import { Component, Input } from '@angular/core';
import { Appointment, AppointmentType, PlanningUser } from 'src/app/interfaces';
import moment from 'moment';
import { doc, getDoc } from '@firebase/firestore';
import { db } from 'src/app/app.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

export interface AppointmentInfoData {
  appointment: Appointment;
  planningUsers: PlanningUser[];
}

@Component({
  selector: 'app-team-appointment-info-card',
  templateUrl: './team-appointment-info-card.component.html',
  styleUrls: ['./team-appointment-info-card.component.scss'],
})
export class TeamAppointmentInfoCardComponent {
  moment = moment;
  townshipId = localStorage.getItem('township') as string;
  appointmentType: AppointmentType;
  maximumAppointmentsInDayPart: number;
  @Input() data: AppointmentInfoData;
  mapsAddress: string;
  safeMapsUrl: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) {}

  async ngOnInit() {
    moment.locale('nl');

    this.mapsAddress = `${this.data.appointment.postal} ${this.data.appointment.houseNumber}${this.data.appointment.houseNumberAddition}`;
    if (this.data.appointment.street) {
      this.mapsAddress = `${this.data.appointment.street} ${this.data.appointment.houseNumber}${this.data.appointment.houseNumberAddition} ${this.data.appointment.postal} ${this.data.appointment.city}`;
    }
    console.log('mapsAddress', this.mapsAddress);
    this.safeMapsUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      `https://maps.google.com/maps?q=${this.mapsAddress}&output=embed`
    );
    await this.getAppointmentType();
  }

  async getAppointmentType() {
    const appointmentTypeRef = doc(
      db,
      `township/${this.townshipId}/appointmentTypes/${this.data.appointment.appointmentTypeId}`
    );
    this.appointmentType = (
      await getDoc(appointmentTypeRef)
    ).data() as AppointmentType;
    this.appointmentType.id = this.data.appointment.appointmentTypeId;
  }

  capitalizeFirstLetter(val: string) {
    return String(val).charAt(0).toUpperCase() + String(val).slice(1);
  }

  getPlanningUser(id: string) {
    const planningUser = this.data.planningUsers.find((user) => user.id === id);
    return planningUser;
  }
}
