import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  collection,
  deleteDoc,
  doc,
  documentId,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import moment from 'moment';
import { dataStore, db, functions } from 'src/app/app.component';
import { ActionType } from 'src/app/enums';
import { getFileExtension, iframeUrl } from 'src/app/globals';
import {
  Appointment,
  AppointmentType,
  DataStoreExport,
  MailDataObj,
  PlanningUser,
  Township,
  Voucher,
} from 'src/app/interfaces';
import { CustomValidators } from 'src/app/validators/custom-validators';
import { ReportSentComponent } from '../report-sent/report-sent.component';
import { Buffer } from 'buffer';

export interface DialogData {
  appointment: Appointment;
}
@Component({
  selector: 'app-manage-report',
  templateUrl: './manage-report.component.html',
  styleUrls: ['./manage-report.component.scss'],
})
export class ManageReportComponent implements OnInit {
  dataStore: DataStoreExport = dataStore;
  planningUser = dataStore.localUser as PlanningUser;
  townshipId = localStorage.getItem('township') as string;
  moment = moment;
  tabIndex: number = 0;
  loaded = false;
  saving: boolean = false;
  externalReportUrl: string;
  requestVoucherUrl: string;
  rightToForm: FormGroup = this.fb.group({
    addressTypes: new FormControl(
      [],
      [Validators.required, CustomValidators.arrayNotEmpty()]
    ),
  });
  savedAddressTypes: boolean = false;
  reportForm: FormGroup = this.fb.group({
    sendReportToResident: new FormControl({ value: false, disabled: true }),
    reportNotes: new FormControl(''),
    status: new FormControl(null, Validators.required),
  });
  reportFile: File;
  appointmentTypes: AppointmentType[] = [];
  additionalForm: FormGroup = this.fb.group({
    followup: new FormControl(false),
    followupAppointmentTypeId: new FormControl(null),
  });
  calendarSequence: number;
  reportAlreadySend: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogRef: MatDialogRef<ManageReportComponent>,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private storage: AngularFireStorage,
    private snackbar: MatSnackBar
  ) {}

  async ngOnInit() {
    moment.locale('nl');
    let townshipName: string;
    const townshipNameDocs = await getDocs(
      query(
        collection(db, 'townshipNames'),
        where('townshipId', '==', this.townshipId),
        limit(1)
      )
    );
    townshipNameDocs.forEach((doc) => {
      townshipName = doc.id;
    });

    if (townshipName) {
      const customParams = `${this.data.appointment.postal}*${
        this.data.appointment.houseNumber
      }*${this.data.appointment.houseNumberAddition ?? ''}*${
        this.data.appointment.userEmail ?? ''
      }`;
      this.requestVoucherUrl = `${await iframeUrl(
        this.townshipId,
        db
      )}/municipality/${townshipName}/${customParams}}`;
    }

    this.reportForm.controls.status.valueChanges.subscribe(
      (newStatus: ActionType) => {
        switch (newStatus) {
          case ActionType.done:
            this.reportForm.removeControl('cancelReason');
            break;
          case ActionType.canceled:
            this.reportForm.addControl('cancelReason', new FormControl());
            break;
        }
      }
    );
    this.additionalForm.controls.followup.valueChanges.subscribe(
      (checked: boolean) => {
        if (checked) {
          this.additionalForm.addControl(
            'followupAppointmentTypeId',
            new FormControl(
              [],
              [Validators.required, CustomValidators.arrayNotEmpty()]
            )
          );
        } else {
          this.additionalForm.removeControl('followupAppointmentTypeId');
        }
      }
    );
    await this.getAppointmentTypes();

    this.rightToForm.patchValue(this.data.appointment);
    this.reportForm.patchValue(this.data.appointment);
    this.additionalForm.patchValue(this.data.appointment);
    if (this.data.appointment.addressTypes) this.savedAddressTypes = true;
    if (
      this.data.appointment?.status == ActionType.done ||
      this.data.appointment?.status == ActionType.canceled
    ) {
      this.reportAlreadySend = true;
      this.reportForm.patchValue(this.data.appointment);
      this.rightToForm.disable();
      this.reportForm.disable();
      this.additionalForm.disable();
    } else {
      this.reportForm.patchValue({ status: null });
    }
    const planningSettings = (
      await getDoc(doc(db, `township/${this.townshipId}/settings/planning`))
    ).data();
    this.externalReportUrl = planningSettings.externalReportUrl;
    this.loaded = true;
  }

  async getAppointmentTypes() {
    const appointmentTypesRef = collection(
      db,
      `township/${this.townshipId}/appointmentTypes`
    );
    const appointmentTypesDocs = await getDocs(
      query(appointmentTypesRef, orderBy('name'))
    );
    appointmentTypesDocs.forEach((appointmentDoc) => {
      const appointmentType = appointmentDoc.data() as AppointmentType;
      appointmentType.id = appointmentDoc.id;
      this.appointmentTypes.push(appointmentType);
    });
  }

  toggleAddressType(addressType: string) {
    const addressTypes = this.rightToForm.value.addressTypes;
    if (!addressTypes.includes(addressType)) {
      addressTypes.push(addressType);
    } else {
      addressTypes.splice(addressTypes.indexOf(addressType), 1);
    }
    console.log('addressTypes', addressTypes);
    this.rightToForm.controls.addressTypes.setValue(addressTypes);
  }

  async nextStep() {
    console.log('this.tabIndex', this.tabIndex);
    if (this.tabIndex === 1) {
      if (!this.reportForm.valid && !this.reportAlreadySend) {
        return this.reportForm.markAllAsTouched();
      }
    }
    console.log('reportData', this.reportData());
    this.tabIndex++;
  }

  prevStep() {
    this.tabIndex--;
  }

  setReportFile(event) {
    const file = event.target.files[0] as File;
    this.reportForm.controls.sendReportToResident.enable();
    this.reportFile = file;
  }
  openSetReportFileInput() {
    const element: HTMLElement = document.getElementById(
      'reportUpload'
    ) as HTMLElement;
    element.click();
  }

  unsetReportFile() {
    this.reportFile = null;
    this.reportForm.controls.sendReportToResident.disable();
  }

  reportData() {
    const report = {
      ...this.rightToForm.value,
      ...this.reportForm.value,
      ...this.additionalForm.value,
    };
    return report;
  }

  async saveAddressTypes() {
    if (!this.rightToForm.valid || this.saving) {
      return;
    }
    console.log('reportData', this.reportData());
    this.saving = true;
    const form = this.rightToForm.value;

    const appointmentRef = doc(
      db,
      `township/${this.townshipId}/appointments/${this.data.appointment.id}`
    );
    const addressString = `${this.data.appointment.postal}${this.data.appointment.houseNumber}${this.data.appointment.houseNumberAddition}`;
    console.log('addressString', addressString);
    const addressRef = doc(
      db,
      `township/${this.townshipId}/addresses/${addressString}`
    );
    await updateDoc(appointmentRef, form);
    await updateDoc(addressRef, { type: form.addressTypes });
    this.savedAddressTypes = true;
    this.saving = false;
  }

  openReportFile() {
    window.open(this.data.appointment.reportUrl, '_blank');
  }

  async save() {
    if (this.saving || !this.reportForm.valid || !this.additionalForm.valid) {
      return;
    }
    this.saving = true;
    const saveObj = this.reportData() as any;
    if (this.reportFile) {
      const extension = getFileExtension(this.reportFile);
      const filePath = `${this.townshipId}/appointmentReports/${
        this.data.appointment.id
      }/report${moment().format('YYYY-MM-DDTHH:mm:ss')}.${extension}`;
      const uploadTask = await this.storage.upload(filePath, this.reportFile);
      saveObj.reportUrl = await uploadTask.ref.getDownloadURL();
      saveObj.reportPath = filePath;
    }

    const appointmentRef = doc(
      db,
      `township/${this.townshipId}/appointments/${this.data.appointment.id}`
    );
    await updateDoc(appointmentRef, saveObj);
    let actionType = ActionType.done;
    if (this.reportForm.value.status == ActionType.canceled) {
      actionType = ActionType.noshow;
      await this.notifyUsers(this.data.appointment);
      await this.sendMailToPlanners('cancelled');
    }
    let followupSend: boolean = false;
    if (this.additionalForm.value.followup) {
      followupSend = await this.sendMailToPlanners('followup');
    }
    const callable = httpsCallable(functions, 'pipedriveCreateActivity');
    const result = await callable({
      townshipId: this.townshipId,
      appointmentId: this.data.appointment.id,
      actionType: actionType,
    });
    if (this.reportForm.value.sendReportToResident) {
      await this.sendReportToResident();
    }
    const dialogRef = this.dialog.open(ReportSentComponent, {
      data: {
        followup: followupSend,
      },
      width: '450px',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(() => {
      this.dialogRef.close();
    });
  }

  async notifyUsers(appointmentData: Appointment) {
    const previousNotificationData = await getDoc(
      doc(
        db,
        `township/${this.townshipId}/appointmentNotificationQueue/${appointmentData.id}`
      )
    );

    if (
      previousNotificationData.exists() &&
      previousNotificationData.data().notificationType !== 'edit'
    ) {
      deleteDoc(
        doc(
          db,
          `township/${this.townshipId}/appointmentNotificationQueue/${appointmentData.id}`
        )
      );
    } else {
      const planningSettings = (
        await getDoc(doc(db, `township/${this.townshipId}/settings/planning`))
      ).data();

      const township = (
        await getDoc(doc(db, `township/${this.townshipId}`))
      ).data() as Township;

      const planningUsersToMail = [];
      const planningUserDocs = await getDocs(
        query(
          collection(db, `township/${this.townshipId}/planningUsers`),
          where(documentId(), 'in', appointmentData.planningUserIds)
        )
      );
      planningUserDocs.forEach((planningUserDoc) => {
        planningUsersToMail.push(planningUserDoc.data());
      });

      const voucher = (
        await getDoc(
          doc(
            db,
            `township/${this.townshipId}/vouchers/${appointmentData.voucherId}`
          )
        )
      ).data() as Voucher;

      const notificationData = {
        appointmentUserToMail: {
          name: appointmentData.userName,
          email: appointmentData.userEmail,
          phone: appointmentData.userPhone,
        },
        planningUsersToMail: planningUsersToMail,
        postal: appointmentData.postal,
        houseNumber: appointmentData.houseNumber,
        houseNumberAddition: appointmentData.houseNumberAddition ?? null,
        street: appointmentData.street ?? null,
        city: appointmentData.city ?? null,
        appointmentStart: appointmentData.start,
        appointmentEnd: appointmentData.end,
        voucherId: appointmentData.voucherId,
        notificationCreationDate: new Date(),
        notificationType: 'cancelled',
        calendarSequence: appointmentData.calendarSequence ?? 0 + 1,
        appointmentType: appointmentData.appointmentTypeName,
        coachesRemoved: [],
        coachesAdded: [],
        planningPhone: planningSettings.phoneNumber,
        voucher: voucher,
        township: township,
      };

      await setDoc(
        doc(
          db,
          `township/${this.townshipId}/appointmentNotificationQueue/${appointmentData.id}`
        ),
        notificationData,
        { merge: true }
      );
    }
  }

  async sendReportToResident() {
    const callable = httpsCallable(functions, `httpSendMail`);
    const reportFileExtension = getFileExtension(this.reportFile);
    const mailDataObj = {
      orgData: {
        township: dataStore.township,
      },
      mailData: {
        mailType: 'planningSendReportToResident',
        appointmentType: this.getAppointmentType(
          this.data.appointment.appointmentTypeId
        )?.name,
        appointmentStart: this.data.appointment.start,
        coachData: [
          {
            email: dataStore.localUser.email,
            name: `${this.planningUser.firstName} ${this.planningUser.lastName}`,
          },
        ],
        clientData: {
          name: this.data.appointment.userName,
          email: this.data.appointment.userEmail,
          phoneNumber: this.data.appointment.userPhone,
        },
        planningLocationData: {
          postal: this.data.appointment.postal,
          houseNumber: this.data.appointment.houseNumber,
          houseNumberAddition:
            this.data.appointment.houseNumberAddition ?? null,
          street: this.data.appointment.street ?? null,
          city: this.data.appointment.city ?? null,
        },
        attachments: [
          {
            filename: `Rapportage.${reportFileExtension}`,
            content: Buffer.from(await this.reportFile.arrayBuffer()).toString(
              `base64`
            ),
            type:
              reportFileExtension == 'jpg' || reportFileExtension == 'jpeg'
                ? 'image/jpeg'
                : reportFileExtension == 'png'
                ? 'image/png'
                : 'application/pdf',
            disposition: `attachment`,
          },
        ],
      },
      userData: {
        email: this.data.appointment.userEmail,
      },
    };
    const result = await callable(mailDataObj);
    if (!result.data) {
      this.snackbar.open(
        'Het versturen van de rapportage naar een inwoner is mislukt. Neem contact op met een planner.',
        'X',
        {
          duration: 5000,
        }
      );
    }
  }

  async sendMailToPlanners(mailType) {
    const plannerDocs = await getDocs(
      query(
        collection(db, `township/${this.townshipId}/planningUsers`),
        where('rights', '==', 'planner')
      )
    );
    let plannerEmails: string[] = [];
    plannerDocs.forEach((plannerDoc) => {
      const planner = { ...plannerDoc.data() } as PlanningUser;
      plannerEmails.push(planner.email);
    });
    if (plannerEmails.length > 0) {
      let mailDataObj: MailDataObj;
      let result;
      const callable = httpsCallable(functions, `httpSendMail`);
      switch (mailType) {
        case 'followup':
          mailDataObj = {
            orgData: {
              township: dataStore.township,
            },
            mailData: {
              mailType: 'planningRequestedFollowupAppointment',
              appointmentType: this.getAppointmentType(
                this.additionalForm.value.followupAppointmentTypeId
              )?.name,
              coachData: [
                {
                  email: dataStore.localUser.email,
                  name: `${this.planningUser.firstName} ${this.planningUser.lastName}`,
                },
              ],
              clientData: {
                name: this.data.appointment.userName,
                email: this.data.appointment.userEmail,
                phoneNumber: this.data.appointment.userPhone,
              },
              planningLocationData: {
                postal: this.data.appointment.postal,
                houseNumber: this.data.appointment.houseNumber,
                houseNumberAddition:
                  this.data.appointment.houseNumberAddition ?? null,
                street: this.data.appointment.street ?? null,
                city: this.data.appointment.city ?? null,
              },
            },
            userData: {
              email: plannerEmails,
            },
          };
          result = await callable(mailDataObj);
          if (!result.data) {
            this.snackbar.open(
              'Het aanvragen van een vervolg afspraak is mislukt. Neem contact op met een planner.',
              'X',
              {
                duration: 5000,
              }
            );
            return false;
          }
          return true;
        case 'cancelled':
          mailDataObj = {
            orgData: {
              township: dataStore.township,
            },
            mailData: {
              mailType: 'planningCancelledAppointmentByCoach',
              appointmentType: this.getAppointmentType(
                this.data.appointment.appointmentTypeId
              )?.name,
              coachData: [
                {
                  email: dataStore.localUser.email,
                  name: `${this.planningUser.firstName} ${this.planningUser.lastName}`,
                },
              ],
              clientData: {
                name: this.data.appointment.userName,
                email: this.data.appointment.userEmail,
                phoneNumber: this.data.appointment.userPhone,
              },
              planningLocationData: {
                postal: this.data.appointment.postal,
                houseNumber: this.data.appointment.houseNumber,
                houseNumberAddition:
                  this.data.appointment.houseNumberAddition ?? null,
                street: this.data.appointment.street ?? null,
                city: this.data.appointment.city ?? null,
              },
            },
            userData: {
              email: plannerEmails,
            },
          };
          result = await callable(mailDataObj);
          break;
      }
    }
  }

  getAppointmentType(typeId) {
    const appointmentType = this.appointmentTypes.find(
      (appointmentType) => typeId == appointmentType.id
    );
    return appointmentType;
  }

  capitalizeFirstLetter(val: string) {
    return String(val).charAt(0).toUpperCase() + String(val).slice(1);
  }
}
