import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { collection, doc, getDocs, query, setDoc } from 'firebase/firestore';
import { getBytes, getStorage, ref } from 'firebase/storage';
import moment from 'moment';
import { PDFDocument } from 'pdf-lib';
import { CommissionType } from 'src/app/enums';
import { MonthlyCostDoc } from 'src/app/interfaces';

interface DialogData {
  townshipFileId: string;
}
@Component({
  selector: 'app-confirm-delete-domains',
  templateUrl: './create-invoice.component.html',
  styleUrls: ['./create-invoice.component.scss'],
})
export class CreateInvoiceComponent implements OnInit {
  townshipId: string = this.data.townshipFileId;
  payments = {};
  invoiceNumbers: any[] = [];
  newInvoiceNumber: string = `WMN${moment(new Date()).format(
    'YYYYMMDDHHmmss'
  )}`;
  idsInvoiceUpdates: any[] = [];
  invoiceForm: UntypedFormGroup;
  loading: boolean = false;
  downloading: boolean = false;
  commissionTypes: CommissionType;
  downloadAmount: number;
  downloadedCount: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private db: AngularFirestore,
    private st: AngularFireStorage,
    private fb: UntypedFormBuilder
  ) {}

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.invoiceForm = this.fb.group({});
    await this.getAndPrepDBData();
    this.loading = false;
  }

  invoiceChecked(event: MatCheckboxChange, value: any) {
    const index = this.invoiceNumbers.indexOf(value);
    this.invoiceNumbers[index]['checked'] = event.checked;
  }

  downloadPDFs() {
    if (this.downloading) {
      return;
    }
    this.downloading = true;
    this.downloadAmount = 0;
    this.downloadedCount = 0;

    this.invoiceNumbers.forEach((invoice) => {
      if (invoice.checked) {
        this.downloadAmount++;
        let sumMonthBrutoCalculated = 0;
        let btwTownshipCalculated = 0;
        let sumMonthNettoCalculated = 0;
        let activatedTotalValueCalculated = 0;
        let totalAmountPaidOutCalculated = 0;
        let totalActivatedVouchers = 0;
        this.payments[invoice.number].forEach((payment) => {
          let btwTownshipCalculation = 0;
          btwTownshipCalculation =
            Number(btwTownshipCalculation.toFixed(2)) +
            Number(payment.sumMonthBruto.toFixed(2)) *
              (payment.settings.btwPercentage / 100);
          sumMonthBrutoCalculated =
            Number(sumMonthBrutoCalculated.toFixed(2)) +
            Number(payment.sumMonthBruto.toFixed(2));
          btwTownshipCalculated =
            Number(btwTownshipCalculated.toFixed(2)) +
            Number(btwTownshipCalculation.toFixed(2));
          sumMonthNettoCalculated =
            Number(sumMonthNettoCalculated.toFixed(2)) +
            Number(payment.sumMonthNetto.toFixed(2));
          activatedTotalValueCalculated += Number(
            payment.activatedTotalValue.toFixed(2)
          );
          totalAmountPaidOutCalculated += Number(
            payment.totalAmountPaidOut.toFixed(2)
          );
          totalActivatedVouchers += payment.activatedVouchers;
        });
        const infoForPDF = {
          nameTownship: this.payments[invoice.number][0].settings.townshipName,
          invoiceDate: moment(new Date()).format('DD-MM-YYYY'),
          invoiceNumber: invoice.number,
          startPeriod: invoice.dateFrom,
          endPeriod: invoice.dateTo,
          commissionType:
            this.payments[invoice.number][0].settings.commissionType,
          sumMonthBruto: sumMonthBrutoCalculated
            .toLocaleString('nl-NL', { style: 'currency', currency: 'EUR' })
            .replace('€', ''),
          btwPercentage:
            this.payments[invoice.number][0].settings.btwPercentage,
          btwTownship: btwTownshipCalculated
            .toLocaleString('nl-NL', { style: 'currency', currency: 'EUR' })
            .replace('€', ''),
          sumMonthNetto: sumMonthNettoCalculated
            .toLocaleString('nl-NL', { style: 'currency', currency: 'EUR' })
            .replace('€', ''),
          periodDoc: `${invoice.dateFrom} t/m ${invoice.dateTo}`,
          activatedTotalValue: `${activatedTotalValueCalculated

            .toLocaleString('nl-NL', { style: 'currency', currency: 'EUR' })
            .replace('€', '')} EUR`,
          totalAmountPaidOut: `${totalAmountPaidOutCalculated
            .toLocaleString('nl-NL', { style: 'currency', currency: 'EUR' })
            .replace('€', '')} EUR`,
          activatedVouchers: (
            totalActivatedVouchers / this.payments[invoice.number].length
          )
            .toLocaleString('nl-NL', { style: 'currency', currency: 'EUR' })
            .replace('€', ''),
          perVoucherCost: `${this.payments[
            invoice.number
          ][0].settings.perVoucherCost
            .toLocaleString('nl-NL', { style: 'currency', currency: 'EUR' })
            .replace('€', '')} EUR`,
          commissionPercentageTownship: `${
            this.payments[invoice.number][0].settings
              .commissionPercentageTownship
          }%`,
          perMonthCost: `${this.payments[
            invoice.number
          ][0].settings.perMonthCost
            .toLocaleString('nl-NL', { style: 'currency', currency: 'EUR' })
            .replace('€', '')} EUR`,
          btwPercentage1: `${
            this.payments[invoice.number][0].settings.btwPercentage
          }%`,
          invoiceNumber1: invoice.number,
          commissionType1:
            this.payments[invoice.number][0].settings.commissionType,
        };

        this.exportInvoice(
          infoForPDF,
          this.payments[invoice.number][0].settings.invoiceTemplateTownship,
          invoice.number
        );
      }
    });

    this.idsInvoiceUpdates.forEach((docId) => {
      setDoc(
        doc(
          this.db.firestore,
          `globalSettings/townships/commissionSettings/${this.townshipId}/monthlyCosts/${docId}`
        ),
        { invoiceNumber: this.newInvoiceNumber, downloadedBefore: true },
        { merge: true }
      );
    });
  }

  async exportInvoice(
    infoForPDF: any,
    invoiceTemplate: string,
    invoiceNumber: string
  ) {
    const pdfDoc = await PDFDocument.load(
      await getBytes(ref(getStorage(), invoiceTemplate))
    );
    const form = pdfDoc.getForm();

    Object.keys(infoForPDF).forEach((key: any) => {
      form.getTextField(key).setText(infoForPDF[key].toString());
      form.getTextField(key).enableReadOnly();
    });

    const url = window.URL.createObjectURL(
      new Blob([await pdfDoc.save()], {
        type: 'application/pdf',
      })
    );
    const htmlAnchor = document.createElement('a');
    htmlAnchor.style.display = 'none';
    htmlAnchor.href = url;
    htmlAnchor.download = `${invoiceNumber}.pdf`;
    document.body.appendChild(htmlAnchor);
    htmlAnchor.click();
    URL.revokeObjectURL(url);
    this.downloadedCount++;
    if (this.downloadedCount == this.downloadAmount) {
      this.downloading = false;
    }
  }

  async getAndPrepDBData() {
    (
      await getDocs(
        query(
          collection(
            this.db.firestore,
            `globalSettings/townships/commissionSettings/${this.townshipId}/monthlyCosts/`
          )
        )
      )
    ).forEach((payment) => {
      const paymentData = {
        ...payment.data(),
        id: payment.id,
      } as MonthlyCostDoc;
      if (!paymentData.invoiceNumber) {
        paymentData.invoiceNumber = this.newInvoiceNumber;
        this.idsInvoiceUpdates.push(paymentData.id);
      }

      if (!this.payments[paymentData.invoiceNumber]) {
        this.payments[paymentData.invoiceNumber] = [];
        const pushObj = { number: paymentData.invoiceNumber, checked: true };
        if (paymentData.downloadedBefore) {
          pushObj['downloaded'] = true;
          pushObj.checked = false;
        }
        this.invoiceNumbers.push(pushObj);
      }
      this.payments[paymentData.invoiceNumber].push(paymentData);
    });

    this.invoiceNumbers.forEach((invoice) => {
      this.invoiceForm.addControl(
        invoice.number,
        new UntypedFormControl(invoice.checked)
      );

      let dates = [];
      this.payments[invoice.number].forEach((payment) => {
        dates.push(payment.date);
      });

      dates = this.sortDates(dates);

      const index = this.invoiceNumbers.findIndex(
        (invoiceObject) => invoiceObject.number === invoice.number
      );

      this.invoiceNumbers[index].dateFrom = moment(dates[0].toDate())
        .tz('Europe/Amsterdam')
        .format('MM-YYYY');
      this.invoiceNumbers[index].dateTo = moment(
        dates[dates.length - 1].toDate()
      )
        .tz('Europe/Amsterdam')
        .format('MM-YYYY');
    });
  }

  sortDates(dates) {
    dates.sort((date1, date2) => {
      const a = date1;
      const b = date2;
      if (a > b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      return 0;
    });
    return dates;
  }

  checkboxChecker() {
    let result: boolean = false;
    this.invoiceNumbers.forEach((invoice) => {
      if (this.invoiceForm.controls[invoice.number].value) {
        result = true;
      }
    });
    return result;
  }
}
