import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { httpsCallable } from 'firebase/functions';
import { functions } from 'src/app/app.component';

export interface DialogData {
  code: string;
  email: string;
}

@Component({
  selector: 'app-send-voucher',
  templateUrl: './send-voucher.component.html',
  styleUrls: ['./send-voucher.component.scss'],
})
export class SendVoucherComponent implements OnInit {
  env = environment;
  awaitingResponse = false;
  sendVoucherForm: UntypedFormGroup;
  townshipId = localStorage.getItem('township') as string;
  constructor(
    public db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: UntypedFormBuilder,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<SendVoucherComponent>,
    private http: HttpClient
  ) {}

  ngOnInit() {
    console.log('data', this.data);
    this.sendVoucherForm = this.fb.group({
      code: [, Validators.required],
      email: [, Validators.required],
      type: [, Validators.required],
    });
    if (this.data) {
      this.sendVoucherForm.patchValue(this.data);
    }
  }

  async send() {
    this.sendVoucherForm.markAllAsTouched();
    if (!this.sendVoucherForm.valid) {
      return;
    }
    if (!this.awaitingResponse) {
      this.awaitingResponse = true;
      const callable = httpsCallable(functions, 'httpSendVoucherMail');
      await callable({
        email: this.sendVoucherForm.controls.email.value,
        code: this.sendVoucherForm.controls.code.value,
        townshipId: this.townshipId,
        type: this.sendVoucherForm.controls.type.value,
      }).then((response) => {
        if (response.data['status'] == 'success') {
          this.snackBar.open('De mail is verstuurd', 'X', {
            duration: 5000,
          });
          return this.dialogRef.close();
        } else {
          this.snackBar.open(
            'Het versturen van de mail is mislukt, controleer gegevens en probeer opnieuw.',
            '',
            {
              duration: 5000,
            }
          );
          this.awaitingResponse = false;
        }
      });
    }
  }
}
