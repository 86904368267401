import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { getAuth } from 'firebase/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import moment from 'moment';
import { db } from 'src/app/app.component';
import { PlanningUser, PlanningUserException } from 'src/app/interfaces';
import { CustomValidators } from 'src/app/validators/custom-validators';
@Component({
  selector: 'app-manage-schedule-exception',
  templateUrl: './manage-schedule-exception.component.html',
  styleUrls: ['./manage-schedule-exception.component.scss'],
})
export class ManageScheduleExceptionComponent implements OnInit {
  townshipId: string = localStorage.getItem('township');
  loaded: boolean = false;
  saving: boolean = false;
  userId: string;
  exceptions: PlanningUserException[] = [];
  exception: PlanningUserException;

  exceptionForm: UntypedFormGroup = this.fb.group({
    startDate: new UntypedFormControl(null, Validators.required),
    endDate: new UntypedFormControl(null, Validators.required),
    startHour: new UntypedFormControl(null, [
      Validators.required,
      Validators.min(0),
      Validators.max(23),
      CustomValidators.numberInput(false, false, 0),
    ]),
    startMinutes: new UntypedFormControl(null, [
      Validators.required,
      Validators.min(0),
      Validators.max(59),
      CustomValidators.numberInput(false, false, 0),
    ]),
    endHour: new UntypedFormControl(null, [
      Validators.required,
      Validators.min(0),
      Validators.max(23),
      CustomValidators.numberInput(false, false, 0),
    ]),
    endMinutes: new UntypedFormControl(null, [
      Validators.required,
      Validators.min(0),
      Validators.max(59),
      CustomValidators.numberInput(false, false, 0),
    ]),
    comment: new UntypedFormControl(),
  });

  constructor(
    private fb: UntypedFormBuilder,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  async ngOnInit() {
    const auth = getAuth();
    this.userId = auth.currentUser.uid;
    const user = (
      await getDoc(
        doc(db, `township/${this.townshipId}/planningUsers/${this.userId}`)
      )
    ).data() as PlanningUser;
    const id = this.route.snapshot.paramMap.get('id');
    this.exceptions = user?.exceptions ?? [];

    if (id && id != 'new') {
      this.exception = this.exceptions.find((obj) => obj.id == id);
      if (this.exception) {
        this.exceptionForm.patchValue({
          startDate: this.exception.startDate.toDate(),
          endDate: this.exception.endDate.toDate(),
          startHour: this.exception.startDate.toDate().getHours(),
          startMinutes: this.exception.startDate.toDate().getMinutes(),
          endHour: this.exception.endDate.toDate().getHours(),
          endMinutes: this.exception.endDate.toDate().getMinutes(),
          comment: this.exception.comment,
        });
      }
    }
    this.loaded = true;
  }

  async save() {
    this.exceptionForm.markAllAsTouched();
    if (!this.exceptionForm.valid || this.saving) {
      return;
    }
    this.saving = true;
    const id = this.exception?.id ?? Date.now().toString(16);
    const startDateTime = moment(this.exceptionForm.value.startDate)
      .locale('nl')
      .set({
        hours: this.exceptionForm.value.startHour,
        minutes: this.exceptionForm.value.startMinutes,
      });
    const endDateTime = moment(this.exceptionForm.value.endDate)
      .locale('nl')
      .set({
        hours: this.exceptionForm.value.endHour,
        minutes: this.exceptionForm.value.endMinutes,
      });
    const saveObj = {
      id: id,
      startDate: startDateTime.toDate(),
      endDate: endDateTime.toDate(),
      comment: this.exceptionForm.value.comment,
    } as PlanningUserException;
    if (this.checkDateOverlap(saveObj)) {
      this.snackbar.open(
        'De startdatum en tijd moeten plaatsvinden voor de einddatum en tijd',
        'X',
        {
          duration: 5000,
        }
      );
      this.saving = false;
      return;
    }

    const index = this.exceptions.indexOf(this.exception);
    if (index != -1) {
      this.exceptions[index] = saveObj;
    } else {
      this.exceptions.push(saveObj);
    }
    this.exceptions.sort(this.sortObjectsByStartDate);

    const docRef = doc(
      db,
      `/township/${this.townshipId}/planningUsers/${this.userId}`
    );
    if (saveObj != this.exception) {
      try {
        await updateDoc(docRef, { exceptions: this.exceptions });
      } catch (error) {
        console.log(error);
        this.snackbar.open(
          'Er is iets fout gegaan met het opslaan van de gegevens',
          'X',
          {
            duration: 5000,
          }
        );
        this.saving = false;
        return;
      }
    }
    this.router.navigate(['schedule'], { state: { tabIndex: 1 } });
  }

  getError(control) {
    const field = this.exceptionForm.get(control);
    if (field.touched || !field.pristine) {
      let error: string;
      if (field.hasError('required')) {
        error = 'Dit veld is verplicht';
        return error;
      }
      if (field.hasError('min')) {
        if (control == 'startHour' || control == 'endHour') {
          error = `Het ingestelde uur niet eerder dan middernacht zijn`;
        } else {
          error = 'De ingestelde minuut moet tussen 0 en 59 zijn';
        }
        return error;
      }
      if (field.hasError('max')) {
        if (control == 'startHour' || control == 'endHour') {
          error = `Het ingestelde uur mag niet later dan middernacht zijn`;
        } else {
          error = 'De ingestelde minuut moet tussen 0 en 59 zijn';
        }
        return error;
      }
      if (field.hasError('numberInput')) {
        error = 'Het ingevulde getal mag niet decimaal zijn';
      }
      return error;
    }
    return '';
  }

  checkDateOverlap(obj) {
    const startDate = moment(
      obj.startDate.toDate ? obj.startDate.toDate() : obj.startDate
    ).set({
      hour: obj.startHour,
      minutes: obj.startMinutes,
    });
    const endDate = moment(
      obj.endDate.toDate ? obj.endDate.toDate() : obj.endDate
    ).set({
      hour: obj.endHour,
      minutes: obj.endMinutes,
    });
    if (startDate >= endDate) return true;
    return false;
  }

  sortObjectsByStartDate(a, b) {
    if (a.startDate > b.startDate) {
      return -1;
    }
    if (a.startDate < b.startDate) {
      return 1;
    }
    return 0;
  }
}
