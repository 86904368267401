import { collection, getDocs } from 'firebase/firestore';
import moment from 'moment';
import {
  AppointmentStatisticUser,
  AppointmentType,
  DayPart,
  PlanningUser,
} from '../interfaces';
import { db } from '../app.component';

const townshipId = localStorage.getItem('township') as string;

export function capitalizeFirstLetter(val: string) {
  return String(val).charAt(0).toUpperCase() + String(val).slice(1);
}

export async function getPlanningUsersStatistics(
  planningUsersStatisticsYearWeek: string
) {
  const statsRef = collection(
    db,
    `township/${townshipId}/appointmentStatistics/${planningUsersStatisticsYearWeek}/users`
  );
  const statsDocs = await getDocs(statsRef);
  const planningUsersStatistics: AppointmentStatisticUser[] = [];
  statsDocs.forEach((statDoc) => {
    const statUser = statDoc.data() as AppointmentStatisticUser;
    statUser.id = statDoc.id;
    planningUsersStatistics.push(statUser);
  });
  console.log('planningUsersStatistics', planningUsersStatistics);
  return planningUsersStatistics;
}

export function getAvailableTimeString(maxTime, percentageUsed = 0) {
  const remainder =
    percentageUsed != 0
      ? (maxTime - maxTime * percentageUsed) % 60
      : maxTime % 60;
  let hours;
  let minutes;
  if (remainder == 0) {
    hours = maxTime / 60 - (maxTime * percentageUsed) / 60;
  } else {
    hours =
      Math.floor(maxTime / 60) - Math.ceil((maxTime * percentageUsed) / 60);
    minutes = remainder;
  }
  let timeString = '';
  if (hours > 0 || (hours == 0 && !minutes)) {
    timeString = `${hours} uur`;
    if (minutes) {
      timeString = timeString + ` en ${minutes} minuten`;
    }
  } else {
    timeString = `${minutes} minuten`;
  }
  return timeString;
}

export function getAvailabilityCirclePercentageForWeek(
  user: PlanningUser,
  planningUsersStatistics: AppointmentStatisticUser[]
) {
  const userStatistics = planningUsersStatistics.find(
    (statisticsUser) => statisticsUser.id === user.id
  );
  if (!userStatistics) {
    return 0;
  }
  const totalTimeAvailable = user.workData?.maxWeeklyHours
    ? user.workData?.maxWeeklyHours * 60
    : 0;
  const totalTimeUsed = userStatistics.workMinutes;

  if (totalTimeUsed >= totalTimeAvailable) {
    return 1;
  }
  return totalTimeUsed / totalTimeAvailable;
}

// currently unused, left function in the helper in case we need it again.
export function getAvailabilityCirclePercentageForDaypart(
  userId: string,
  dayPartIds: string[],
  planningUsersStatistics: AppointmentStatisticUser[],
  dayParts: DayPart[],
  viewDate,
  appointmentId: string,
  info: boolean = false
) {
  const user = planningUsersStatistics.find((user) => user.id === userId);
  if (!user) {
    return 0;
  }
  let totalTimeAvailable = 0;
  dayPartIds.forEach((dayPartId) => {
    const dayPart = dayParts.find((dayPart) => {
      return dayPart.id == dayPartId;
    });
    const dayPartStart = moment(viewDate).set({
      hour: dayPart.startHour,
      minutes: dayPart.startMinutes,
    });
    const dayPartEnd = moment(viewDate).set({
      hour: dayPart.endHour,
      minutes: dayPart.endMinutes,
    });
    totalTimeAvailable =
      totalTimeAvailable + dayPartEnd.diff(dayPartStart, 'minutes');
  });

  const filteredAppointments = user.appointments.filter((appointment) => {
    const dayPartIdFound = dayPartIds.filter((dayPartId) => {
      return appointment.dayPartIds.indexOf(dayPartId) != -1;
    });
    if (info) {
      return dayPartIdFound.length > 0;
    }
    return dayPartIdFound.length > 0 && appointment.id != appointmentId;
  });
  let timeUsedInMinutes = 0;
  filteredAppointments.forEach((appointment) => {
    const appointmentStart = moment(appointment.start.toDate());
    const appointmentEnd = moment(appointment.end.toDate());
    timeUsedInMinutes =
      timeUsedInMinutes + appointmentEnd.diff(appointmentStart, 'minutes') + 15;
    if (appointment.dayPartIds.length > 1) {
      for (let i = 1; i <= appointment.dayPartIds.length; i++) {
        const dayPartOneIdFound = appointment.dayPartIds.indexOf(
          dayPartIds[i - 1]
        );
        const dayPartTwoIdFound = appointment.dayPartIds.indexOf(dayPartIds[i]);
        if (dayPartOneIdFound == -1 || dayPartTwoIdFound == -1) {
          continue;
        }
        const dayPartOne = dayParts.find((dayPart) => {
          return dayPart.id == dayPartIds[i - 1];
        });
        const dayPartTwo = dayParts.find((dayPart) => {
          return dayPart.id == dayPartIds[i];
        });
        const dayPartOneEnd = moment(viewDate).set({
          hour: dayPartOne.endHour,
          minutes: dayPartOne.endMinutes,
        });
        const dayPartTwoStart = moment(viewDate).set({
          hour: dayPartTwo.startHour,
          minutes: dayPartTwo.startMinutes,
        });
        timeUsedInMinutes =
          timeUsedInMinutes - dayPartTwoStart.diff(dayPartOneEnd, 'minutes');
      }
    }
  });

  if (timeUsedInMinutes >= totalTimeAvailable) {
    return 1;
  }
  return timeUsedInMinutes / totalTimeAvailable;
}
