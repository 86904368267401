import {
  Component,
  OnInit,
  Inject,
  ElementRef,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { Organisation, OrganisationTag, Tag } from 'src/app/interfaces';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import {
  deleteField,
  collection,
  getDocs,
  getDoc,
  doc,
  where,
  query,
} from 'firebase/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { environment } from '../../../environments/environment';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, take } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { EditSepaComponent } from './edit-sepa/edit-sepa.component';
import { MatTabGroup } from '@angular/material/tabs';
import { db } from 'src/app/app.component';
export interface DialogData {
  organisation: Organisation;
}

@Component({
  selector: 'app-edit-organisation',
  templateUrl: './edit-organisation.component.html',
  styleUrls: ['./edit-organisation.component.scss'],
})
export class EditOrganisationComponent implements OnInit {
  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  saving: boolean = false;
  isOrganisation: boolean = false;
  env = environment;
  newOrganisation = false;
  organisationId: string;
  infoEntrepreneurForm: UntypedFormGroup;
  authorizationForm: UntypedFormGroup;
  sepaDataForm: UntypedFormGroup;

  apiConnected: boolean = this.data.organisation?.apiConnection ? true : false;
  sepaSettings: any = this.data.organisation?.sepaSettingsComplete
    ? this.data.organisation.sepaSettings
    : null;
  headerImgFileUploaded: File;
  headerImgUrl: string;

  townshipRef = '/township/' + localStorage.getItem('township') + '/';
  townshipId = localStorage.getItem('township');

  // Everything tags
  @ViewChild('claimTagInput', { static: false })
  claimTagInput: ElementRef<HTMLInputElement>;
  @ViewChild('activateTagInput', { static: false })
  activateTagInput: ElementRef<HTMLInputElement>;
  @ViewChild('topUpTagInput', { static: false })
  topUpTagInput: ElementRef<HTMLInputElement>;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  activateTagCtrl = new UntypedFormControl();
  activateFilteredTags: ReplaySubject<Tag[]> = new ReplaySubject<Tag[]>(1);
  claimTagCtrl = new UntypedFormControl();
  claimFilteredTags: ReplaySubject<Tag[]> = new ReplaySubject<Tag[]>(1);
  topUpTagCtrl = new UntypedFormControl();
  topUpFilteredTags: ReplaySubject<Tag[]> = new ReplaySubject<Tag[]>(1);
  allTags: Observable<Tag[]>;
  organisationTags: OrganisationTag[] = [];
  private allTagsCollection: AngularFirestoreCollection<Tag>;
  selectedActivateTags = [];
  selectedClaimTags = [];
  selectedTopUpTags = [];
  addressTypes = [];
  selectedAddressTypes = [];
  addressTypesObs: ReplaySubject<Tag[]> = new ReplaySubject<Tag[]>(1);
  addressTypesCtrl = new UntypedFormControl();
  addresTypesFilteredTags: ReplaySubject<Tag[]> = new ReplaySubject<Tag[]>(1);
  public tagFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public filteredTagsCtrl: ReplaySubject<Tag[]> = new ReplaySubject<Tag[]>(1);
  tabIndex: number = 0;

  constructor(
    public db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: UntypedFormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditOrganisationComponent>,
    private snackBar: MatSnackBar,
    private storage: AngularFireStorage
  ) {}

  async ngOnInit() {
    this.infoEntrepreneurForm = this.fb.group({
      name: [, Validators.required],
      ownerEmail: [, Validators.email],
      promoMaterial: ['', this.invalidUrl],
      apiConnection: [],
      website: ['', this.invalidUrl],
      apiKey: [],
      isWebshop: [],
      isEntrepreneur: [],
      requiresReceipt: [],
      organisationReferenceMandatory: [],
      canBePaidPerTransaction: [],
    });

    this.authorizationForm = this.fb.group({
      handlesAssignments: [],
      termsMandatory: [],
      canClaimVouchers: [],
      canActivateVouchers: [],
      canTopUpVouchers: [],
      handlesAddresses: [],
    });

    this.sepaDataForm = this.fb.group({
      iban: [],
      bic: [],
      name: [],
      paymentReference: [],
    });

    if (this.data.organisation) {
      if (this.data.organisation.externalVouchersOrg) {
        this.infoEntrepreneurForm.addControl(
          'maxTotalAmountClaimed',
          new UntypedFormControl()
        );
      }

      this.headerImgUrl = this.data.organisation.headerImageUrl;
      this.organisationId = this.data.organisation.id;
      this.infoEntrepreneurForm.patchValue(this.data.organisation);
      this.authorizationForm.patchValue(this.data.organisation);
      this.sepaDataForm.patchValue(this.data.organisation.sepaSettings);
    } else {
      this.organisationId = this.db.createId();
      this.newOrganisation = true;
      this.apiConnected = false;
    }
    const townshipData = (
      await getDoc(doc(this.db.firestore, this.townshipRef))
    ).data();
    console.log('townshpData', townshipData, this.data);
    const querySnapshot = await getDocs(
      collection(this.db.firestore, `township/${this.townshipId}/addresTypes`)
    );
    querySnapshot.forEach((doc) => {
      this.addressTypes.push({ id: doc.id, name: doc.id });
    });
    if (this.data.organisation?.addressTypesRights) {
      this.data.organisation.addressTypesRights.forEach((type) => {
        this.selectedAddressTypes.push(type);
      });
    }
    console.log('types=>', this.addressTypes);
    this.addressTypesObs.next(this.addressTypes);

    const organisationTagsRef = collection(
      this.db.firestore,
      `${this.townshipRef}organisations/${this.organisationId}/tags`
    );
    const organisationTagDocs = await getDocs(organisationTagsRef);
    organisationTagDocs.forEach((orgTagDoc) => {
      const orgTag = orgTagDoc.data() as OrganisationTag;
      orgTag.id = orgTagDoc.id;
      this.organisationTags.push(orgTag);
      if (orgTag.activateRights) {
        this.selectedActivateTags.push(orgTag);
      }
      if (orgTag.claimRights) {
        this.selectedClaimTags.push(orgTag);
      }
      if (orgTag.topUpRights) {
        this.selectedTopUpTags.push(orgTag);
      }
    });
    console.log('this.selectedActivateTags', this.selectedActivateTags);
    console.log('this.selectedClaimTags', this.selectedClaimTags);
    console.log('this.selectedTopUpTags', this.selectedTopUpTags);
    this.allTagsCollection = this.db.collection<Tag>(`${this.townshipRef}tags`);
    this.allTags = this.allTagsCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Tag;
          data.id = a.payload.doc['id'];
          return data;
        })
      ),
      take(1)
    );

    const combinedActivateFilter = combineLatest([
      this.activateTagCtrl.valueChanges,
      this.allTags,
    ]);
    combinedActivateFilter
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((values) => {
        console.log('values', values);
        const filteredTags = [];
        const input = values[0];
        const allTags = values[1];
        console.log('allTags', allTags);
        allTags.forEach((tag) => {
          const tagName = tag.name.toLowerCase();
          if (tagName.includes(input)) {
            filteredTags.push(tag);
          }
        });

        console.log('activateFilteredTags', filteredTags);
        this.activateFilteredTags.next(filteredTags);
      });
    this.activateTagCtrl.setValue('');

    const combinedClaimFilter = combineLatest([
      this.claimTagCtrl.valueChanges,
      this.allTags,
    ]);
    combinedClaimFilter
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((values) => {
        console.log('values', values);
        const filteredTags = [];
        const input = values[0].toLowerCase();
        const allTags = values[1];
        console.log('allTags', allTags);
        allTags.forEach((tag) => {
          const tagName = tag.name.toLowerCase();
          if (tagName.includes(input)) {
            filteredTags.push(tag);
          }
        });

        console.log('claimFilteredTags', filteredTags);
        this.claimFilteredTags.next(filteredTags);
      });
    this.claimTagCtrl.setValue('');

    const combinedTopUpFilter = combineLatest([
      this.topUpTagCtrl.valueChanges,
      this.allTags,
    ]);
    combinedTopUpFilter
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((values) => {
        console.log('values', values);
        const filteredTags = [];
        const input = values[0].toLowerCase();
        const allTags = values[1];
        console.log('allTags', allTags);
        allTags.forEach((tag) => {
          const tagName = tag.name.toLowerCase();
          if (tagName.includes(input)) {
            filteredTags.push(tag);
          }
        });

        console.log('topUpFilteredTags', filteredTags);
        this.topUpFilteredTags.next(filteredTags);
      });
    this.topUpTagCtrl.setValue('');

    const combinedAddressTypeFilter = combineLatest([
      this.addressTypesCtrl.valueChanges,
      this.addressTypesObs,
    ]);
    combinedAddressTypeFilter
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((values) => {
        console.log('values', values);
        const filteredTags = [];
        const input = values[0].toLowerCase();
        const addressTypes = values[1];
        console.log('alladdressTypesTags', addressTypes);
        addressTypes.forEach((tag) => {
          const tagName = tag.name.toLowerCase();
          if (tagName.includes(input)) {
            filteredTags.push(tag);
          }
        });

        console.log('claimFilteredTags', filteredTags);
        this.addresTypesFilteredTags.next(filteredTags);
      });
    this.addressTypesCtrl.setValue('');
    if (this.env.env !== 'township') {
      this.isOrganisation = true;
      this.infoEntrepreneurForm.controls.isEntrepreneur.setValue(true);
      this.infoEntrepreneurForm.controls.canBePaidPerTransaction.disable();
      this.authorizationForm.controls.canActivateVouchers.disable();
      this.authorizationForm.controls.handlesAssignments.disable();
      this.authorizationForm.controls.handlesAddresses.disable();
      this.sepaDataForm.controls.iban.disable();
      this.sepaDataForm.controls.bic.disable();
      this.sepaDataForm.controls.name.disable();
      this.sepaDataForm.controls.paymentReference.disable();
    }
    this.tabGroup.selectedIndex = 0;
  }

  remove(tag: Tag, array: Tag[]): void {
    const index = array.indexOf(tag);
    if (index >= 0) {
      array.splice(index, 1);
    }
  }

  async selected(
    event: MatAutocompleteSelectedEvent,
    array: Tag[],
    inputCtrl: UntypedFormControl,
    input?: any
  ): Promise<void> {
    const value = event.option.value as Tag;
    console.log('value', value);
    const foundTag = array.find((lfTag) => {
      if (lfTag.id === value.id) {
        return lfTag;
      }
    });
    inputCtrl.setValue('');
    if (input) {
      this[input].nativeElement.value = '';
    }
    if (!foundTag) {
      array.push(value);
    } else {
      this.snackBar.open('Dit label hangt al aan deze ondernemer.', 'X', {
        duration: 5000,
      });
    }
  }

  async save() {
    if (this.saving || !this.validateForms()) {
      return;
    }
    this.saving = true;
    const saveObj = {
      ...this.infoEntrepreneurForm.value,
      ...this.authorizationForm.value,
      sepaSettings:
        this.env.env == 'township'
          ? this.sepaDataForm.value
          : this.sepaDataForm.getRawValue(),
    };
    const townshipUserFound = await getDocs(
      query(
        collection(db, `township/${this.townshipId}/users`),
        where(`email`, `==`, saveObj.ownerEmail)
      )
    );
    if (!townshipUserFound.empty) {
      this.saving = false;
      this.snackBar.open(
        'Deze gebruiker mag geen eigenaar van een organisatie zijn',
        'X',
        {
          duration: 3000,
        }
      );
      return;
    }
    const tagBatch = this.db.firestore.batch();
    const firstSepaSetup =
      this.data.organisation && !this.data.organisation.sepaSettings
        ? true
        : false;
    const sepaChanged =
      this.data.organisation &&
      this.data.organisation.sepaSettings &&
      (saveObj.sepaSettings.iban !== this.data.organisation.sepaSettings.iban ||
        saveObj.sepaSettings.bic !== this.data.organisation.sepaSettings.bic ||
        saveObj.sepaSettings.name !==
          this.data.organisation.sepaSettings.name ||
        saveObj.sepaSettings.paymentReference !==
          this.data.organisation.sepaSettings.paymentReference)
        ? true
        : false;
    if (!firstSepaSetup && sepaChanged) {
      const dialogRef = this.dialog.open(EditSepaComponent, {
        height: 'auto',
        width: '500px',
      });
      const dialogResult = await dialogRef.afterClosed().toPromise();
      if (dialogResult) {
        this.snackBar.open('Uw gegevens zijn aangepast', 'X', {
          duration: 3000,
        });
        saveObj.sepaSettings = {
          iban: saveObj.sepaSettings.iban
            ? saveObj.sepaSettings.iban
            : deleteField(),
          bic: saveObj.sepaSettings.bic
            ? saveObj.sepaSettings.bic
            : deleteField(),
          name: saveObj.sepaSettings.name
            ? saveObj.sepaSettings.name
            : deleteField(),
          paymentReference: saveObj.sepaSettings.paymentReference
            ? saveObj.sepaSettings.paymentReference
            : deleteField(),
        };
        if (saveObj.sepaSettings.iban && saveObj.sepaSettings.name) {
          saveObj.sepaSettingsComplete = true;
        } else {
          saveObj.sepaSettingsComplete = false;
        }
      } else {
        this.saving = false;
        return;
      }
    }
    if (this.data.organisation && firstSepaSetup) {
      saveObj.sepaSettings = {
        iban: saveObj.sepaSettings.iban
          ? saveObj.sepaSettings.iban
          : deleteField(),
        bic: saveObj.sepaSettings.bic
          ? saveObj.sepaSettings.bic
          : deleteField(),
        name: saveObj.sepaSettings.name
          ? saveObj.sepaSettings.name
          : deleteField(),
        paymentReference: saveObj.sepaSettings.paymentReference
          ? saveObj.sepaSettings.paymentReference
          : deleteField(),
        firstSepaSetup: true,
      };
      if (saveObj.sepaSettings.iban && saveObj.sepaSettings.name) {
        saveObj.sepaSettingsComplete = true;
      } else {
        saveObj.sepaSettingsComplete = false;
      }
    } else if (!this.data.organisation) {
      if (saveObj.sepaSettings.iban && saveObj.sepaSettings.name) {
        saveObj.sepaSettings = {
          iban: saveObj.sepaSettings.iban,
          bic: saveObj.sepaSettings.bic
            ? saveObj.sepaSettings.bic
            : deleteField(),
          name: saveObj.sepaSettings.name,
          paymentReference: saveObj.sepaSettings.paymentReference
            ? saveObj.sepaSettings.paymentReference
            : deleteField(),
          firstSepaSetup: true,
        };
        saveObj.sepaSettingsComplete = true;
      } else {
        saveObj.sepaSettingsComplete = false;
      }
    }
    // Check tags for changes
    console.log('selectedActivateTags', this.selectedActivateTags);
    console.log('selectedClaimTags', this.selectedClaimTags);
    console.log('selectedTopUpTags', this.selectedTopUpTags);
    const tagsObj = {};
    console.log('this.organisationTags', this.organisationTags);
    if (this.organisationTags) {
      this.organisationTags.forEach((tag) => {
        console.log('organisationTag', tag);
        tagsObj[tag.id] = this.getMiniTag(tag);
        tagsObj[tag.id].delete = true;
      });
    }
    this.selectedActivateTags.forEach((tag) => {
      console.log('selectedActivateTag', tag);
      if (!tagsObj[tag.id]) {
        tagsObj[tag.id] = this.getMiniTag(tag);
      }
      tagsObj[tag.id].activateRights = true;
      tagsObj[tag.id].delete = false;
    });
    this.selectedClaimTags.forEach((tag) => {
      console.log('selectedClaimTag', tag);
      if (!tagsObj[tag.id]) {
        tagsObj[tag.id] = this.getMiniTag(tag);
      }
      tagsObj[tag.id].claimRights = true;
      tagsObj[tag.id].delete = false;
    });
    this.selectedTopUpTags.forEach((tag) => {
      console.log('selectedTopUpTag', tag);
      if (!tagsObj[tag.id]) {
        tagsObj[tag.id] = this.getMiniTag(tag);
      }
      tagsObj[tag.id].topUpRights = true;
      tagsObj[tag.id].delete = false;
    });
    Object.keys(tagsObj).forEach((tagId) => {
      console.log('tagId', tagId);
      console.log('tag', tagsObj[tagId]);
      const orgTagRef = this.db.doc(
        `${this.townshipRef}organisations/${this.organisationId}/tags/${tagId}`
      ).ref;
      const tagOrgRef = this.db.doc(
        `${this.townshipRef}tags/${tagId}/organisations/${this.organisationId}`
      ).ref;
      const tagOrgObj: any = this.getMiniTag(tagsObj[tagId]);
      if (tagsObj[tagId].activateRights) {
        tagOrgObj.activateRights = true;
      }
      if (tagsObj[tagId].claimRights) {
        tagOrgObj.claimRights = true;
      }
      if (tagsObj[tagId].topUpRights) {
        tagOrgObj.topUpRights = true;
      }
      if (tagsObj[tagId].delete) {
        tagBatch.delete(orgTagRef);
        tagBatch.delete(tagOrgRef);
      } else {
        delete tagsObj[tagId].delete;
        tagBatch.set(orgTagRef, tagsObj[tagId]);
        tagBatch.set(tagOrgRef, tagOrgObj);
      }
    });
    // Make sure no "null" values are attempting to be saved, also if something is null it will be deleted from the database.
    Object.keys(saveObj).forEach((key) => {
      if (saveObj[key] == null) {
        saveObj[key] = deleteField();
      } else if (
        typeof saveObj[key] === 'string' &&
        saveObj[key].length === 0
      ) {
        saveObj[key] = deleteField();
      }
    });

    saveObj.headerImageUrl = this.headerImgUrl ?? deleteField();

    if (this.selectedAddressTypes) {
      saveObj.addressTypesRights = [];
      this.selectedAddressTypes.forEach((type) => {
        saveObj.addressTypesRights.push(type);
      });
    }
    console.log('saveObj', saveObj);
    console.log('tagsObj', tagsObj);
    console.log('tagBatch', tagBatch);
    await this.db
      .collection(this.townshipRef + 'organisations')
      .doc(this.organisationId)
      .set(saveObj, {
        merge: true,
      });
    // only township has rights to edit tags
    if (this.env.env === 'township') {
      this.infoEntrepreneurForm.controls.isEntrepreneur.setValue(true);
      await tagBatch.commit();
    }
    this.dialogRef.close();
  }

  getMiniTag(tag) {
    return { id: tag.id, name: tag.name };
  }

  getError(name: string, form: UntypedFormGroup) {
    const field = form.get(name);

    if (field.touched || !field.pristine) {
      if (field.hasError('invalidUrl')) {
        return 'Voer een geldige URL in.';
      }

      if (field.hasError('required')) {
        return 'Dit veld moet ingevuld zijn.';
      }
      return '';
    }
  }

  invalidUrl(control: AbstractControl): ValidationErrors | null {
    const url = control.value;

    if (!url) {
      return;
    }

    const expression =
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    const regex = new RegExp(expression);

    if (!url.match(regex)) {
      return { invalidUrl: true };
    }

    return null;
  }

  refreshKey() {
    let id;
    let result = [];
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < 18; i++) {
      result.push(
        characters.charAt(Math.floor(Math.random() * charactersLength))
      );
    }
    id = result.join('');

    this.infoEntrepreneurForm.controls.apiKey.setValue(id);
  }

  async openFileInput(event: any) {
    if (!event.target.files || event.target.files.length === 0) {
      console.error('No files selected');
      return;
    }

    if (event.target.files.size > 2048000) {
      this.snackBar.open('Dit bestand moet onder de 2 MB zijn.', 'X', {
        duration: 5000,
      });
      return;
    }

    this.headerImgFileUploaded = event.target.files[0];

    try {
      let filePath = `${this.townshipId}/images/${this.organisationId}`;
      let uploadTask = await this.storage.upload(
        filePath,
        this.headerImgFileUploaded
      );
      this.headerImgUrl = await uploadTask.ref.getDownloadURL();
    } catch (e: any) {
      console.error(e);
      this.saving = false;
      this.snackBar.open(
        'Er is iets misgegaan. Neem contact op met de beheerder',
        'X',
        {
          duration: 4000,
        }
      );
    }
  }

  previousStep() {
    this.tabIndex === 0
      ? this.dialogRef.close()
      : (this.tabGroup.selectedIndex = this.tabIndex--);
  }

  nextStep() {
    this.tabGroup.selectedIndex = this.tabIndex++;
  }

  validateForms() {
    let formValid: boolean = true;
    let goto: number = 0;
    this.infoEntrepreneurForm.markAllAsTouched();
    this.authorizationForm.markAllAsTouched();
    this.sepaDataForm.markAllAsTouched();

    if (this.env.env == 'township' && !this.sepaDataForm.valid) {
      formValid = false;
      goto = 2;
    }
    if (!this.authorizationForm.valid) {
      formValid = false;
      goto = 1;
    }
    if (!this.infoEntrepreneurForm.valid) {
      formValid = false;
      goto = 0;
    }

    if (!formValid) {
      this.tabIndex = goto;
      this.snackBar.open('Niet alle velden zijn correct ingevuld', 'X', {
        duration: 5000,
      });
    }

    return formValid;
  }
}
