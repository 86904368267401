import { Component, OnInit } from '@angular/core';
import {
  AngularFirestoreCollection,
  AngularFirestore,
} from '@angular/fire/compat/firestore';
import {
  Organisation,
  Voucher,
  PaymentReference,
  DataStoreExport,
} from '../interfaces';
import { EditOrganisationComponent } from './edit-organisation/edit-organisation.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as XLSX from 'xlsx';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { CheckVouchersComponent } from './check-vouchers/check-vouchers.component';
import { environment } from 'src/environments/environment';
import { ManageVoucherComponent } from '../voucher-management/manage-voucher/manage-voucher.component';
import { DiscountComponent } from './dialog/discount/discount.component';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { HideOrganisation } from './dialog/hide-organisation/hide-organisation.component';
import { ConfirmationComponent } from '../voucher-management/dialogs/confirmation-code/confirmation-code.component';
import {
  doc,
  setDoc,
  increment,
  Timestamp,
  getDocs,
  query,
  collection,
  where,
  onSnapshot,
} from 'firebase/firestore';
import { iframeUrl, shortenNumber } from '../globals';
import moment from 'moment';
import firebase from 'firebase/compat/app';
import { dataStore } from '../app.component';

@Component({
  selector: 'app-organisation-management',
  templateUrl: './organisation-management.component.html',
  styleUrls: ['./organisation-management.component.scss'],
})
export class OrganisationManagementComponent implements OnInit {
  env = environment;
  dataStore: DataStoreExport = dataStore;
  // userOrgData: OrgUser;
  organisations: Organisation[] = [];
  showHidden: boolean = false;
  statisticsLastUpdatedDate: string;
  webshopUrl: string;
  loaded: boolean = false;
  orgChartScheme = {
    domain: environment.orgChartScheme,
  };

  paymentReferencesCollection: AngularFirestoreCollection<PaymentReference>;
  paymentReferences: PaymentReference[] = [];

  constructor(
    public db: AngularFirestore,
    public dialog: MatDialog,
    private snackbar: MatSnackBar,
    public afAuth: AngularFireAuth,
    private http: HttpClient,
    private router: Router
  ) {}

  async ngOnInit() {
    console.log('env', this.env);
    this.statisticsLastUpdatedDate = dataStore.township.statisticsLastUpdated!
      ? moment(
          dataStore.township.statisticsLastUpdated.organisations.toDate()
        ).format('[om] HH:mm [op] DD-MM-YYYY')
      : 'N.V.T';

    let organisationQuery = query(
      collection(
        this.db.firestore,
        `township/${dataStore.township.id}/organisations/`
      )
    );

    if (dataStore.rootUser.organisation) {
      organisationQuery = query(
        collection(
          this.db.firestore,
          `/township/${dataStore.township.id}/organisations`
        ),
        where(
          firebase.firestore.FieldPath.documentId(),
          '==',
          dataStore.rootUser.organisation
        )
      );
    }

    const unsubscribeOrganisations = onSnapshot(
      organisationQuery,
      (querySnapshot) => {
        const organisations = [];
        querySnapshot.docs.forEach((doc) => {
          const organisation = this.createStatisticsChart(
            {
              ...doc.data(),
              id: doc.id,
            } as Organisation,
            true
          );

          organisations.push(organisation);
        });
        this.organisations = organisations;
        this.organisations.sort((a, b) => a.name.localeCompare(b.name));
        this.webshopUrl = this.organisations[0]?.website;
        this.loaded = true;
      }
    );
  }

  openDiscountDialog(): void {
    this.dialog.open(ConfirmationComponent, {
      width: '373px',
    });
  }

  createStatisticsChart(organisation: Organisation, added: boolean = false) {
    if (added) {
      //TODO Change to new observable
      this.paymentReferencesCollection = this.db.collection(
        `township/${dataStore.township.id}/organisations/${organisation.id}/paymentReferences`,
        (ref) => ref.where('status', '==', 'done').orderBy('lastUpdate', 'desc')
      );
      this.paymentReferencesCollection
        .valueChanges()
        .subscribe((references: any) => {
          references.find((ref) => {
            if (organisation.paid === false || ref.length <= 0) {
              return;
            }
            if (ref.paid === false) {
              organisation.paid = ref.paid;
              organisation.paymentReference = ref;
            }
            return ref.paid === false;
          });
        });
    }
    if (!organisation.claimedVouchers) {
      organisation.claimedVouchers = 0;
    }
    if (!organisation.paidVouchers) {
      organisation.paidVouchers = 0;
    }
    const totalVouchers =
      organisation.claimedVouchers - organisation.paidVouchers;
    if (totalVouchers < 0) {
      organisation.totalVouchers = 0;
    } else {
      organisation.totalVouchers = totalVouchers;
    }
    let paidVouchers = organisation.paidVouchers;
    let claimedVouchers = organisation.claimedVouchers - paidVouchers;
    organisation.statisticsChart = [];
    if (!organisation.externalVouchersOrg) {
      organisation.statisticsChart = [
        {
          name: 'Geclaimd',
          value: claimedVouchers,
        },
        {
          name: 'Betaald',
          value: paidVouchers,
        },
      ];
    } else {
      if (
        organisation.totalAmountClaimed < organisation.maxTotalAmountClaimed
      ) {
        organisation.statisticsChart.push({
          name: 'Besteed',
          value: organisation.totalAmountClaimed,
        });
        organisation.statisticsChart.push({
          name: 'Resterend',
          value:
            organisation.maxTotalAmountClaimed -
            organisation.totalAmountClaimed,
        });
      }
    }
    return organisation;
  }

  shortenNumber(number) {
    return shortenNumber(number);
  }

  openDialog(dialog, options?: { organisation?: Organisation; type?: string }) {
    switch (dialog) {
      case 'EditOrganisationComponent':
        this.dialog.open(EditOrganisationComponent, {
          width: '700px',
          data: { organisation: options?.organisation },
        });
        break;
      case 'ManageVoucherComponent':
        this.dialog.open(ManageVoucherComponent, {
          width: '373px',
          data: { type: options?.type },
        });
        break;
      case 'CheckVouchersComponent':
        this.dialog.open(CheckVouchersComponent, {
          width: '500px',
          data: { organisation: options?.organisation },
        });
        break;
      case 'DiscountComponent':
        this.dialog.open(DiscountComponent, {
          width: '450px',
        });
        break;
    }
  }

  async recalcStats(organisation) {
    this.http.post(`${environment.functionsUrl}/httpRecalcStatistics`, {
      type: 'organisation',
      townshipId: dataStore.township.id,
      organisationId: organisation.id,
    });
    this.snackbar.open(
      'Statistieken worden herberekend, even geduld a.u.b.',
      'X',
      {
        duration: 5000,
      }
    );
  }

  async recalcAllStats() {
    for await (const voucherGroup of this.organisations) {
      this.recalcStats(voucherGroup);
    }
    const rightNow = moment().toDate();

    this.statisticsLastUpdatedDate = moment(rightNow).format(
      '[om] HH:mm [op] DD-MM-YYYY'
    );

    await setDoc(
      doc(this.db.firestore, `township/${dataStore.township.id}`),
      {
        statisticsLastUpdated: { organisations: Timestamp.fromDate(rightNow) },
      },
      { merge: true }
    );
  }

  async exportOrganisationVouchers(organisation) {
    const vouchers = await getDocs(
      query(
        collection(
          this.db.firestore,
          `/township/${dataStore.township.id}/vouchers/`
        ),
        where('claimOrganisationId', '==', organisation.id)
      )
    );
    const vouchersArray = [];

    // Make batch array with index to start batches with a bang.
    const batches = [];
    let batchIndex = 0;
    let operationCounter = 0;
    batches[0] = this.db.firestore.batch();

    vouchers.forEach((voucherDoc) => {
      const voucher = voucherDoc.data() as Voucher;
      const exportVoucherObj = {};
      exportVoucherObj['Vouchernummer'] = voucher.number ? voucher.number : '';
      exportVoucherObj['Waarde'] =
        voucher.value !== null ? Number(voucher.value) : '';
      exportVoucherObj['Te betalen waarde'] = voucher.amountToPayOrg
        ? Number(voucher.amountToPayOrg)
        : '';
      if (!dataStore.rootUser.organisation) {
        exportVoucherObj['Kassabon/factuur url'] = voucher.imageUrl
          ? voucher.imageUrl
          : '';
      }
      exportVoucherObj['Activatiedatum'] = voucher.activateDate
        ? moment(voucher.activateDate.toDate())
            .tz('Europe/Amsterdam')
            .format('DD-MM-YYYY')
        : '';
      exportVoucherObj['Activatiebedrijf'] = voucher.activateOrganisation
        ? voucher.activateOrganisation
        : '';
      exportVoucherObj['Claimdatum'] = voucher.claimDate
        ? moment(voucher.claimDate.toDate())
            .tz('Europe/Amsterdam')
            .format('DD-MM-YYYY')
        : '';
      exportVoucherObj['Claimbedrijf'] = voucher.claimOrganisation
        ? voucher.claimOrganisation
        : '';
      exportVoucherObj['Betaaldatum'] = voucher.paidDate
        ? moment(voucher.paidDate.toDate())
            .tz('Europe/Amsterdam')
            .format('DD-MM-YYYY')
        : '';
      exportVoucherObj['Betalingskenmerk'] = voucher.paymentId
        ? voucher.paymentId
        : '';
      exportVoucherObj['Referentienummer betalingsverplichting'] =
        voucher.paymentReference ? voucher.paymentReference : '';
      exportVoucherObj['Kostenplaats'] = voucher.costCenter
        ? voucher.costCenter
        : '';
      exportVoucherObj['Vervaldatum'] = voucher.validUntilDate
        ? moment(voucher.validUntilDate.toDate())
            .tz('Europe/Amsterdam')
            .format('DD-MM-YYYY')
        : '';
      exportVoucherObj['Referentie ondernemer'] = voucher.organisationReference
        ? voucher.organisationReference
        : '';
      exportVoucherObj['Akkoord voorwaarden'] = voucher.termsAgreed
        ? 'Ja'
        : 'Nee';
      vouchersArray.push(exportVoucherObj);
      if (!voucher.paidDate) {
        if (operationCounter == 500) {
          batchIndex++;
          operationCounter = 0;
          batches[batchIndex] = this.db.firestore.batch();
        }
        batches[batchIndex].update(voucherDoc.ref, {
          paidDate: new Date(),
        });
        operationCounter++;
      }
    });
    if (vouchersArray.length > 0) {
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(vouchersArray); // converts a DOM TABLE element to a worksheet
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Vouchers');
      // /* save to file */
      XLSX.writeFile(
        wb,
        `Groene Bon.${organisation.name}.${moment(new Date())
          .tz('Europe/Amsterdam')
          .format('DD-MM-YYYY')}.xlsx`
      );
    } else {
      // Nothing to export
      this.snackbar.open('Er zijn geen geclaimde bonnen.', 'X', {
        duration: 5000,
      });
    }
  }

  async copyIframeUrl(language?) {
    if (!this.webshopUrl) {
      this.snackbar.open(
        'Er is nog geen webshop url ingesteld. Neem contact op met de beheerder.',
        'X',
        {
          duration: 5000,
        }
      );
      return;
    }
    const url = `${await iframeUrl(
      dataStore.township.id,
      this.db.firestore,
      language
    )}/exchange/${dataStore.township.id}/${dataStore.rootUser.organisation}`;
    navigator.clipboard.writeText(url);
    console.log('url', url);
    this.snackbar.open('Link gekopieerd.', 'X', {
      duration: 5000,
    });
  }

  navigateToRoute(route: string, extras?: string) {
    if (extras) {
      return this.router.navigate([route, extras]);
    }
    this.router.navigate([route]);
  }

  async changeHiddenStatus(organisation, status) {
    const dialogRef = this.dialog.open(HideOrganisation, {
      width: '375px',
      data: { organisation, type: status },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        await setDoc(
          doc(
            this.db.firestore,
            `township/${dataStore.township.id}/organisations/${organisation.id}`
          ),
          { hidden: status === 'hide' ? true : false },
          { merge: true }
        );
        await setDoc(
          doc(this.db.firestore, `township/${dataStore.township.id}`),
          { hiddenOrganisations: increment(status === 'hide' ? 1 : -1) },
          { merge: true }
        );
        this.snackbar.open(
          status === 'hide'
            ? 'De organisatie is verborgen'
            : 'De organisatie is weer zichtbaar',
          '',
          {
            duration: 5000,
          }
        );
      }
    });
  }
}
