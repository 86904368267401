import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  User,
  TownshipUser,
  Township,
  Organisation,
  Address,
  Voucher,
  VoucherGroup,
  InvoiceInfo,
  AddressException,
  ExternalVoucherGroup,
  crmPackages,
  ExternalVoucher,
  CommissionSettings,
} from '../interfaces';
import { lastValueFrom, Observable } from 'rxjs';
import {
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
  UntypedFormBuilder,
  ValidatorFn,
} from '@angular/forms';
import * as XLSX from 'xlsx';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  deleteField,
  arrayRemove,
  arrayUnion,
  doc,
  getDoc,
  getDocs,
  collection,
  setDoc,
  deleteDoc,
  getCountFromServer,
  addDoc,
  query,
  where,
} from 'firebase/firestore';
import { Router } from '@angular/router';
import { ContinueComponent } from './dialog/continue-dialog/continue.component';
import { environment } from 'src/environments/environment';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { HttpClient } from '@angular/common/http';
import { ForceApiSyncDialogComponent } from './dialog/force-api-sync-dialog/force-api-sync-dialog.component';
import { SetupPaymentDialogComponent } from './dialog/setup-payment-dialog/setup-payment-dialog.component';
import { ConfirmationDialogComponent } from './dialog/confirmation-dialog/confirmation-dialog.component';
import { SelectPdfFontsComponent } from './dialog/select-pdf-fonts/select-pdf-fonts.component';
import { GroupLinkDialogComponent } from './dialog/group-link-dialog/group-link-dialog.component';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ImportExportComponent } from './dialog/import-export-dialog/import-export.component';
import { FileSaverService } from 'ngx-filesaver';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { AddressTypesDialogComponent } from './dialog/address-types-dialog/address-types-dialog.component';
import { BurdenOfProofStatus, CommissionType, AmbitionLevels } from '../enums';
import { ExportReportDialogComponent } from './dialog/export-report-dialog/export-report-dialog-component';
import { iframeUrl } from '../globals';
import { dataStore, db } from '../app.component';
import { getAuth } from 'firebase/auth';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit, AfterViewInit {
  @ViewChild('primary', { static: false }) primary: ElementRef;
  @ViewChild('accent', { static: false }) accent: ElementRef;
  env = environment;
  settingsForm: UntypedFormGroup;
  userId: Observable<string>;
  userDoc: AngularFirestoreDocument<User>;
  user: Observable<User>;
  isSuperUser: boolean = false;
  isOwner: boolean = false;
  township: Township;
  accentToggle: boolean;
  primaryToggle: boolean;
  addressEmptyOnLoad: boolean;
  addressFileUploaded: File;
  addressWorksheet: any;
  postalsEmptyOnLoad = true;
  postalsFileUploaded: File;
  postalsWorksheet: any;
  saving: boolean;
  sisowProfileId: string;
  externalVoucherGroups: Observable<ExternalVoucherGroup[]>;
  externalVoucherGroupsArray: ExternalVoucherGroup[];
  formChanged: boolean;
  deleteHeaderImg: boolean;
  deleteLogoImg: boolean;
  deleteFooterImg: boolean;

  invoiceData: {};
  checkboxCheck = false;

  prefixes: any[] = [
    { name: 'Lokale Bon', db: 'lokale' },
    { name: 'Groene Bon', db: 'groene' },
    { name: 'Toegangsbon', db: 'toegangs' },
    { name: 'Duurzaam Wonen Bon', db: 'duurzaamwonen' },
  ];

  headerImgFileAlreadyUploaded: boolean;
  headerImgFileUploaded: File;
  headerImgUrl: string;

  logoImgFileAlreadyUploaded: boolean;
  logoImgFileUploaded: File;
  logoImgUrl: string;

  footerImgFileAlreadyUploaded: boolean;
  footerImgFileUploaded: File;
  footerImgUrl: string;

  invoicePdfFileAlreadyUploaded: boolean;
  invoicePdfFileUploaded: File;
  invoicePdfUrl: string;
  deleteInvoicePdf: boolean;

  packingSlipPdfFileAlreadyUploaded: boolean;
  packingSlipPdfFileUploaded: File;
  packingSlipPdfUrl: string;
  deletePackingSlipPdf: boolean;

  voucherPdfFileAlreadyUploaded: boolean;
  voucherPdfFileUploaded: File;
  voucherPdfUrl: string;
  voucherPdfFonts: any;
  deleteVoucherPdf: boolean;

  deleteAddresses = false;

  addressExceptionFileAlreadyUploaded: boolean;
  addressExceptionFileUploaded: File;
  addressExceptionWorksheet: any;
  deleteAddressesExceptions: boolean;
  checkingBlacklistedAddreses = false;

  // crm
  crmPackages: Observable<crmPackages[]>;
  crmPackagesArray: crmPackages[];

  addressCheckDbl: boolean;
  smsVerification: boolean;

  addOnBlur = true;
  confidentialEmails: any[] = [];
  confidentialPhoneNumbers: any[] = [];
  commissionTypes = CommissionType;
  readonly separatorKeysCodes = [COMMA, ENTER, SPACE] as const;

  transfering: boolean;
  collections = [
    'addresTypes',
    'addressExceptions',
    'addresses',
    'fonts',
    'orders',
    'organisations',
    'paymentReferences',
    'smsCentralErrors',
    'tags',
    'users',
    'voucherGroups',
    'vouchers',
  ];

  @ViewChild('uploader', { static: false }) uploader: ElementRef;

  exportingAllVouchers: boolean = false;

  constructor(
    public db: AngularFirestore,
    public afAuth: AngularFireAuth,
    private snackBar: MatSnackBar,
    private router: Router,
    public dialog: MatDialog,
    private storage: AngularFireStorage,
    private fb: UntypedFormBuilder,
    private http: HttpClient,
    private _FileSaverService: FileSaverService,
    private st: AngularFireStorage
  ) {}

  rgbToHex(rgb) {
    const rgbArray = rgb.substring(4, rgb.length - 1).split(', ');
    let r = Number(rgbArray[0]).toString(16);
    let g = Number(rgbArray[1]).toString(16);
    let b = Number(rgbArray[2]).toString(16);
    if (r.length === 1) {
      r = '0' + r;
    }
    if (g.length === 1) {
      g = '0' + g;
    }
    if (b.length === 1) {
      b = '0' + b;
    }

    return '#' + r + g + b;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (!this.settingsForm.value.primaryColor) {
        this.settingsForm.controls.primaryColor.setValue(
          this.rgbToHex(getComputedStyle(this.primary.nativeElement).color)
        );
      }
      if (!this.settingsForm.value.accentColor) {
        this.settingsForm.controls.accentColor.setValue(
          this.rgbToHex(getComputedStyle(this.accent.nativeElement).color)
        );
      }
    }, 500);
  }

  async ngOnInit() {
    this.settingsForm = this.fb.group({
      name: new UntypedFormControl('', [Validators.required]),
      uniqueName: new UntypedFormControl(''),
      url: new UntypedFormControl('', [Validators.required]),
      termsUrl: new UntypedFormControl('', [Validators.required]),
      promoMaterial: new UntypedFormControl(''),
      externalVoucherGroupId: new UntypedFormControl(''),
      municipalitys: new UntypedFormControl(),
      voucherPrefix: new UntypedFormControl('', [Validators.required]),
      prefix: new UntypedFormControl('', [Validators.required]),
      sisowProfileId: new UntypedFormControl(''),
      pinCode: new UntypedFormControl(''),
      primaryColor: ['', [Validators.maxLength(7), Validators.minLength(7)]],
      accentColor: ['', [Validators.maxLength(7), Validators.minLength(7)]],
      resendVouchersEmail: [, [Validators.email]],
      checkAddressesDbl: [false],
      activateEconobis: [''],
      usesGroeneAppWebsite: [],
      crmPackage: new UntypedFormControl(''),
      crmApiKey: [],
      crmEndPoint: new UntypedFormControl(''),
      usesSendGrid: [],
      expiredNote: [],
      claimedNote: [],
      reminderNote: [],
      closingPhrase: [],
      maxVouchers: ['', [Validators.required, number({ min: 1 })]],
      maxVouchersPerGroup: ['', [number({ min: 1 })]],
      operatorName: [{ value: 'Wemaron B.V.', disabled: true }, []],
      commissionPercentageTownship: [{ value: 0, disabled: true }, []],
      commissionPercentageOrganisation: [{ value: 0, disabled: true }, []],
      commissionType: [
        { value: this.commissionTypes.Provided, disabled: true },
        [],
      ],
      usesPlanningEnvironment: ['', []],
    });
    this.externalVoucherGroups = this.db
      .collection<ExternalVoucherGroup>(`externalVouchers`)
      .valueChanges({ idField: 'id' });
    this.externalVoucherGroups.subscribe((groups) => {
      this.externalVoucherGroupsArray = groups;
    });
    this.township = dataStore.township;

    const commissionSettings = (
      await getDoc(
        doc(
          this.db.firestore,
          `globalSettings/townships/commissionSettings/${this.township.id}`
        )
      )
    ).data() as CommissionSettings;

    const user = getAuth().currentUser;
    if (user !== null) {
      const userDoc = await getDoc(doc(this.db.firestore, `users/${user.uid}`));

      const userData = { ...userDoc.data(), id: userDoc.id } as User;

      const userTownshipData = (
        await lastValueFrom(
          this.db
            .doc<User>(`township/${this.township.id}/users/${user.uid}`)
            .get()
        )
      ).data();

      if (userData.rights === 'admin') {
        this.isSuperUser = true;
      }

      if (userTownshipData?.rights === 'owner') {
        this.isOwner = true;
      }

      if (!this.isSuperUser) {
        this.settingsForm.controls.usesPlanningEnvironment.disable();
      }

      if (!this.isOwner && !this.isSuperUser) {
        const disableControls = [
          'name',
          'uniqueName',
          'externalVoucherGroupId',
          'voucherPrefix',
          'prefix',
          'activateEconobis',
          'crmPackage',
          'crmEndPoint',
        ];

        disableControls.forEach((control) => {
          this.settingsForm.controls[control].disable();
        });
      }

      if (this.township.postals) {
        this.postalsEmptyOnLoad = false;
      }
      if (this.township.useHeaderImage) {
        this.headerImgFileAlreadyUploaded = true;
        this.headerImgUrl = this.township.headerImageUrl;
      }
      if (this.township.logoImageUrl) {
        this.logoImgFileAlreadyUploaded = true;
        this.logoImgUrl = this.township.logoImageUrl;
      }
      if (this.township.footerImageUrl) {
        this.footerImgFileAlreadyUploaded = true;
        this.footerImgUrl = this.township.footerImageUrl;
      }
      if (this.township.useInvoicePdf) {
        this.invoicePdfFileAlreadyUploaded = true;
        this.invoicePdfUrl = this.township.invoicePdfUrl;
      }
      if (this.township.useVoucherPdf) {
        this.voucherPdfFileAlreadyUploaded = true;
        this.voucherPdfUrl = this.township.voucherPdfUrl;
      }
      if (this.township.usePackingSlipPdf) {
        this.packingSlipPdfFileAlreadyUploaded = true;
        this.packingSlipPdfUrl = this.township.packingSlipPdfUrl;
      }
      if (
        (this.township.sisow &&
          this.township.invoiceInfo.name &&
          this.township.sepaSettingsComplete) ||
        (this.township.mollieProfileId &&
          this.township.invoiceInfo.name &&
          this.township.sepaSettingsComplete)
      ) {
        this.checkboxCheck = true;
      }
      if (this.township.useAddressException) {
        this.addressExceptionFileAlreadyUploaded = true;
      }
      if (this.township.checkAddressesDbl) {
        this.addressCheckDbl = true;
      }
      if (this.township.smsVerification) {
        this.smsVerification = true;
      }
      if (this.township.plan.maxVouchers) {
        this.settingsForm.controls.maxVouchers.patchValue(
          this.township.plan.maxVouchers
        );
      }
      if (this.township.plan.maxVouchersPerGroup) {
        this.settingsForm.controls.maxVouchersPerGroup.patchValue(
          this.township.plan.maxVouchersPerGroup
        );
      }
      if (this.township.confidentialEmails) {
        for (const confidentialEmail of this.township.confidentialEmails) {
          const index = this.confidentialEmails.indexOf(confidentialEmail);
          if (index === -1) {
            this.confidentialEmails.push(confidentialEmail);
          }
        }
      }
      if (this.township.confidentialPhoneNumbers) {
        for (const confidentialPhoneNumber of this.township
          .confidentialPhoneNumbers) {
          const index = this.confidentialPhoneNumbers.indexOf(
            confidentialPhoneNumber
          );
          if (index === -1) {
            this.confidentialPhoneNumbers.push(confidentialPhoneNumber);
          }
        }
      }
      const patchObj = this.township as any;
      if (this.township.municipalitys) {
        patchObj.municipalitys = this.township.municipalitys.toString();
      }
      // if using multiple crm packages this could be extended to mat-select multiple and set value to dropdown
      // crm packages are already saved as []
      if (this.township.activateEconobis) {
        this.settingsForm.controls.crmPackage.setValue('econobis');
      }
      this.settingsForm.patchValue(patchObj);

      this.settingsForm.valueChanges.subscribe((value) => {
        if (this.settingsForm.dirty) {
          this.formChanged = true;
        }
      });

      if (commissionSettings) {
        this.settingsForm.controls.operatorName.setValue(
          commissionSettings.operatorName
        );
        this.settingsForm.controls.commissionPercentageTownship.setValue(
          commissionSettings.commissionPercentageTownship
        );
        this.settingsForm.controls.commissionPercentageOrganisation.setValue(
          commissionSettings.commissionPercentageOrganisation
        );
        this.settingsForm.controls.commissionType.setValue(
          commissionSettings.commissionType
        );
      }
    }

    if (!this.township.pinCode) {
      const defaultPin = (
        await getDoc(doc(this.db.firestore, `admin/sepa-pin`))
      ).data();
      this.township['pinCode'] = defaultPin.pin;
    }

    const patchObj = this.township as any;
    if (this.township.municipalitys) {
      patchObj.municipalitys = this.township.municipalitys.toString();
    }
    this.settingsForm.patchValue(patchObj);

    this.crmPackages = this.db
      .collection<crmPackages>(`crmPackages`)
      .valueChanges({ idField: 'id' });
    this.crmPackages.subscribe((groups) => {
      this.crmPackagesArray = groups;
    });

    const backupPlanned = await this.db
      .doc(`township/${this.township.id}/backup-planned/${this.township.id}`)
      .valueChanges();

    backupPlanned.subscribe((planning: any) => {
      if (planning && !planning.backupDone) {
        this.transfering = true;
      } else {
        this.transfering = false;
      }
    });
  }
  openAddressTypesDialog() {
    const dialogRef = this.dialog.open(AddressTypesDialogComponent, {
      width: '500px',
      data: {
        townshipId: this.township.id,
      },
    });
  }

  add(event: MatChipInputEvent, list: string[], type: 'phone' | 'email'): void {
    const value = (event.value || '').trim();
    if (value) {
      switch (type) {
        case 'phone':
          if (value.length !== 10) {
            return;
          }
          break;
        case 'email':
          if (!this.validateEmail(value)) {
            return;
          }
          break;
      }
      const index = list.indexOf(value);
      if (index === -1) {
        list.push(value);
      }
      if (event.chipInput.inputElement.value) {
        event.chipInput.inputElement.value = '';
      }
    }
  }
  validateEmail(email: string) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }
  remove(item: string, list: string[]) {
    const index = list.indexOf(item);
    if (index >= 0) {
      list.splice(index, 1);
    }
  }

  async exportAllVouchers(type?: 'interSolve') {
    this.exportingAllVouchers = true;
    const townshipId = localStorage.getItem('township');
    let voucherRef = this.db.collection(`/township/${townshipId}/vouchers/`);
    if (type === 'interSolve') {
      voucherRef = this.db.collection(
        `/externalVouchers/interSolve/vouchers/`,
        (ref) => ref.where('townshipId', '==', townshipId)
      );
    }
    const organisationsRef = collection(
      this.db.firestore,
      `/township/${townshipId}/organisations/`
    );
    const organisations = await getDocs(organisationsRef);
    const voucherGroupsRef = collection(
      this.db.firestore,
      `/township/${townshipId}/voucherGroups/`
    );
    const voucherGroups = await getDocs(voucherGroupsRef);
    const vouchersArray = [];
    const vouchersObservable = voucherRef.get();
    vouchersObservable.forEach((vouchers) => {
      vouchers.forEach((voucherDoc) => {
        let voucher: any = voucherDoc.data() as Voucher;
        if (type === 'interSolve') {
          voucher = voucherDoc.data() as ExternalVoucher;
        }
        let voucherGroupName = '';
        if (voucher.voucherGroupId) {
          const voucherGroupDoc = voucherGroups.docs.find(
            (voucherGroup) => voucherGroup.id === voucher.voucherGroupId
          );
          if (voucherGroupDoc) {
            voucherGroupName = (voucherGroupDoc.data() as VoucherGroup).name;
          }
        }
        let organisationName = '';
        let iban = '';
        if (voucher.voucherGroupId) {
          const organisationDoc = organisations.docs.find(
            (organisations) => organisations.id === voucher.claimOrganisationId
          );
          if (organisationDoc) {
            const data = organisationDoc.data() as Organisation;
            if (data.sepaSettings) {
              organisationName = data.sepaSettings.name
                ? data.sepaSettings.name
                : '';
              iban = data.sepaSettings.iban ? data.sepaSettings.iban : '';
            }
          }
        }
        const exportVoucherObj = {};
        exportVoucherObj['Vouchernummer'] = voucher.number
          ? voucher.number
          : '';
        if (type !== 'interSolve') {
          exportVoucherObj['Huidige waarde'] = voucher.value
            ? Number(voucher.value)
            : '';
          exportVoucherObj['Originele waarde'] = voucher.originalValue
            ? Number(voucher.originalValue)
            : '';
          exportVoucherObj['Verzilveringswaarde'] = voucher.claimAmount
            ? voucher.claimAmount
            : '';
          exportVoucherObj['Opwaardeerwaarde'] = voucher.topUpAmount
            ? voucher.topUpAmount
            : '';
          exportVoucherObj['Retourwaarde'] = voucher.refundAmount
            ? voucher.refundAmount
            : '';
          exportVoucherObj['Te betalen waarde'] = voucher.amountToPayOrg
            ? Number(voucher.amountToPayOrg)
            : '';
          exportVoucherObj['Waarde kassabon'] = voucher.receiptValue
            ? Number(voucher.receiptValue)
            : '';
          exportVoucherObj['Kassabon/factuur url'] = voucher.imageUrl
            ? voucher.imageUrl
            : '';
          exportVoucherObj['Naam'] = voucher.name ? voucher.name : '';
          exportVoucherObj['Telefoonnummer'] = voucher.phone
            ? voucher.phone
            : '';
          exportVoucherObj['E-mail'] = voucher.email ? voucher.email : '';
          exportVoucherObj['Postcode'] = voucher.postal ? voucher.postal : '';
          exportVoucherObj['Straat'] = voucher.street ? voucher.street : '';
          exportVoucherObj['Huisnummer'] = voucher.houseNumber
            ? voucher.houseNumber
            : '';
          exportVoucherObj['Toevoeging'] = voucher.houseNumberAddition
            ? voucher.houseNumberAddition
            : '';
          exportVoucherObj['Woonplaats'] = voucher.city ? voucher.city : '';
          exportVoucherObj['Gemeente'] = voucher.municipality
            ? voucher.municipality
            : '';
          exportVoucherObj['Kenmerk'] = voucher.type
            ? voucher.type.toString()
            : '';
          exportVoucherObj['Uitgegeven'] = voucher.distributed ? 'WAAR' : '';
          exportVoucherObj['Activatiedatum'] = voucher.activateDate
            ? voucher.activateDate.toDate().getDate() +
              '-' +
              (voucher.activateDate.toDate().getMonth() + 1) +
              '-' +
              voucher.activateDate.toDate().getFullYear()
            : '';

          exportVoucherObj['Activatie tijdstip'] = voucher.activateDate
            ? `${('0' + voucher.activateDate.toDate().getHours()).slice(-2)}:${(
                '0' + voucher.activateDate.toDate().getMinutes()
              ).slice(-2)}`
            : '';
          exportVoucherObj['Activatiebedrijf'] = voucher.activateOrganisation
            ? voucher.activateOrganisation
            : '';
          exportVoucherObj['Claimdatum'] = voucher.claimDate
            ? voucher.claimDate.toDate().getDate() +
              '-' +
              (voucher.claimDate.toDate().getMonth() + 1) +
              '-' +
              voucher.claimDate.toDate().getFullYear()
            : '';
          exportVoucherObj['Claimbedrijf'] = voucher.claimOrganisation
            ? voucher.claimOrganisation
            : '';
          exportVoucherObj['Betaaldatum'] = voucher.paidDate
            ? voucher.paidDate.toDate().getDate() +
              '-' +
              (voucher.paidDate.toDate().getMonth() + 1) +
              '-' +
              voucher.paidDate.toDate().getFullYear()
            : '';
          exportVoucherObj['Betalingskenmerk'] = voucher.paymentId
            ? voucher.paymentId
            : '';
          exportVoucherObj['Referentienummer betalingsverplichting'] =
            voucher.paymentReference ? voucher.paymentReference : '';
          exportVoucherObj['Referentienummer ondernemer'] =
            voucher.organisationReference ? voucher.organisationReference : '';
          exportVoucherObj['IBAN'] = iban ? iban : '';
          exportVoucherObj['Naam bankrekeninghouder'] = organisationName
            ? organisationName
            : '';
          exportVoucherObj['Vervaldatum'] = voucher.validUntilDate
            ? voucher.validUntilDate.toDate().getDate() +
              '-' +
              (voucher.validUntilDate.toDate().getMonth() + 1) +
              '-' +
              voucher.validUntilDate.toDate().getFullYear()
            : '';
          exportVoucherObj['Herinnering verstuurd'] = voucher.reminderSend
            ? 'WAAR'
            : '';
          exportVoucherObj['Herinnering verstuurd op'] = voucher.reminderDate
            ? voucher.reminderDate
            : '';
          exportVoucherObj['Bewijslastformulier ingevuld '] =
            voucher?.burdenOfProofForm ? 'WAAR' : '';
          exportVoucherObj['Bewijslastformulier opmerking'] = voucher
            .burdenOfProofForm?.completeRemarks
            ? voucher.burdenOfProofForm.completeRemarks
            : '';

          switch (voucher.burdenOfProofForm?.status) {
            case 'Accepted':
              exportVoucherObj['Status van bewijslastformulier'] =
                BurdenOfProofStatus.Accepted;
              break;
            case 'Submitted':
              exportVoucherObj['Status van bewijslastformulier'] =
                BurdenOfProofStatus.Submitted;
              break;
            case 'Concept':
              exportVoucherObj['Status van bewijslastformulier'] =
                BurdenOfProofStatus.Concept;
              break;
            case 'Rejected':
              exportVoucherObj['Status van bewijslastformulier'] =
                BurdenOfProofStatus.Rejected;
              break;
            default:
              exportVoucherObj['Status van bewijslastformulier'] = '';
          }

          exportVoucherObj['Reden voor afkeuring'] = voucher.burdenOfProofForm
            ?.reasonOfRejection
            ? voucher.burdenOfProofForm.reasonOfRejection
            : '';
          exportVoucherObj['Opmerkingen'] = voucher.notes ? voucher.notes : '';
          exportVoucherObj['Campagne'] = voucherGroupName
            ? voucherGroupName
            : '';
          exportVoucherObj['Kostenplaats'] = voucher.costCenter
            ? voucher.costCenter
            : '';
          switch (voucher.ambitionLevel) {
            case 'bronze':
              exportVoucherObj['Ambitieniveau'] = AmbitionLevels.bronze;
              break;
            case 'silver':
              exportVoucherObj['Ambitieniveau'] = AmbitionLevels.silver;
              break;
            case 'gold':
              exportVoucherObj['Ambitieniveau'] = AmbitionLevels.gold;
              break;
            case 'platinum':
              exportVoucherObj['Ambitieniveau'] = AmbitionLevels.platinum;
              break;
            default:
              exportVoucherObj['Ambitieniveau'] = '';
          }
          exportVoucherObj['Uitgiftegroep'] = voucher.groupLink
            ? voucher.groupLink
            : '';
          exportVoucherObj['Blacklisted'] = voucher.isBlacklisted ? 'WAAR' : '';
        } else {
          exportVoucherObj['Winkel ID'] = voucher.merchantId
            ? voucher.merchantId
            : '';
        }
        vouchersArray.push(exportVoucherObj);
        if (vouchers.size === vouchersArray.length) {
          if (vouchersArray.length > 0) {
            const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(vouchersArray); // converts a DOM TABLE element to a worksheet
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Vouchers');

            // /* save to file */
            let fileName = `${this.env.name}.Alle Bonnen.xlsx`;
            if (type === 'interSolve') {
              fileName = `${this.env.name}.Alle InterSolve Bonnen.xlsx`;
            }
            XLSX.writeFile(wb, fileName);
          } else {
            // Nothing to export
            this.snackBar.open('Er zijn geen bonnen om te exporteren.', 'X', {
              duration: 5000,
            });
          }
          this.exportingAllVouchers = false;
        }
      });
      if (!vouchers.docs.length) {
        this.exportingAllVouchers = false;
      }
    });
  }
  async save() {
    if (this.saving) {
      return;
    }
    this.saving = true;
    const saveObj = { ...this.settingsForm.getRawValue() } as Township;

    if (saveObj.uniqueName !== this.township.uniqueName) {
      // Unique Name changed
      if (saveObj.uniqueName) {
        saveObj.uniqueName = saveObj.uniqueName
          .replaceAll(' ', '-')
          .toLowerCase();
        const uniqueNameRef = doc(
          this.db.firestore,
          `townshipNames/${saveObj.uniqueName}`
        );
        const uniqueNameDoc = await getDoc(uniqueNameRef);

        const uniqueNameExists = uniqueNameDoc.exists();
        if (uniqueNameExists) {
          return this.snackBar.open(
            'Deze unieke naam is al in gebruik, kies a.u.b. iets anders',
            'x',
            {
              duration: 5000,
            }
          );
        }
        await setDoc(uniqueNameRef, {
          townshipId: this.township.id,
        });
      }
      if (this.township.uniqueName) {
        // Should delete old uniqueName if it exists
        const oldUniqueNameRef = doc(
          this.db.firestore,
          `townshipNames/${this.township.uniqueName}`
        );
        await deleteDoc(oldUniqueNameRef);
      }
    }
    let municipalitys: string[];
    if (this.settingsForm.value.municipalitys) {
      municipalitys = this.settingsForm.value.municipalitys.split(',');
      for (let i = 0; i < municipalitys.length; i++) {
        const municipality = municipalitys[i];
        municipalitys[i] = municipality.trim().toLowerCase();
      }
      saveObj.municipalitys = municipalitys;
    } else {
      saveObj.municipalitys = null;
    }
    if (saveObj.prefix.length === 3) {
      if (
        !this.urlCheck(saveObj.url, 'url') ||
        !this.urlCheck(saveObj.termsUrl, 'terms')
      ) {
        return;
      }

      if (saveObj.activateEconobis) {
        if (saveObj.crmPackage) {
          saveObj.crmPackages = arrayUnion(saveObj.crmPackage);
        } else {
          return this.snackBar.open(
            "Vul een CRM pakket in of schakel de schakelaar 'Stuur berichten naar CRM pakket' uit",
            'x',
            {
              duration: 5000,
            }
          );
        }
      } else {
        saveObj.crmPackages = arrayRemove(saveObj.crmPackage);
      }
      saveObj.crmPackage = deleteField();
      if (this.invoiceData) {
        saveObj.invoiceInfo = this.invoiceData as InvoiceInfo;
      }
      // Make sure no "null" values are attempting to be saved, also if something is null it will be deleted from the database.
      Object.keys(saveObj).forEach((key) => {
        if (saveObj[key] == null) {
          saveObj[key] = deleteField();
        }
      });

      if (this.headerImgFileAlreadyUploaded) {
        saveObj.headerImageUrl = this.headerImgUrl;
        saveObj.useHeaderImage = true;
      }

      if (this.logoImgFileAlreadyUploaded) {
        saveObj.logoImageUrl = this.logoImgUrl;
      }

      if (this.headerImgFileUploaded) {
        const filePath = `${this.township.id}/images/${this.township.id}header`;
        const uploadTask = await this.storage.upload(
          filePath,
          this.headerImgFileUploaded
        );
        saveObj.headerImageUrl = await uploadTask.ref.getDownloadURL();
        saveObj.useHeaderImage = true;
      }

      if (this.logoImgFileUploaded) {
        const filePath = `${this.township.id}/images/${this.township.id}logo`;
        const uploadTask = await this.storage.upload(
          filePath,
          this.logoImgFileUploaded
        );
        saveObj.logoImageUrl = await uploadTask.ref.getDownloadURL();
        saveObj.useLogoImage = true;
      }

      if (this.footerImgFileUploaded) {
        const filePath = `${this.township.id}/images/${this.township.id}footer`;
        const uploadTask = await this.storage.upload(
          filePath,
          this.footerImgFileUploaded
        );
        saveObj.footerImageUrl = await uploadTask.ref.getDownloadURL();
      }

      if (this.invoicePdfFileUploaded) {
        const filePath = `${this.township.id}/pdf-templates/factuur.pdf`;
        const uploadTask = await this.storage.upload(
          filePath,
          this.invoicePdfFileUploaded
        );
        saveObj.invoicePdfUrl = await uploadTask.ref.getDownloadURL();
        saveObj.useInvoicePdf = true;
      }

      if (this.packingSlipPdfFileUploaded) {
        const filePath = `${this.township.id}/pdf-templates/pakbon.pdf`;
        const uploadTask = await this.storage.upload(
          filePath,
          this.packingSlipPdfFileUploaded
        );
        saveObj.packingSlipPdfUrl = await uploadTask.ref.getDownloadURL();
        saveObj.usePackingSlipPdf = true;
      }

      if (this.voucherPdfFileUploaded) {
        const filePath = `${this.township.id}/pdf-templates/bon-template.pdf`;
        const uploadTask = await this.storage.upload(
          filePath,
          this.voucherPdfFileUploaded
        );
        saveObj.voucherPdfUrl = await uploadTask.ref.getDownloadURL();
        saveObj.useVoucherPdf = true;
        if (this.voucherPdfFonts) {
          saveObj.voucherPdfFonts = this.voucherPdfFonts;
        }
      }

      if (this.deleteHeaderImg) {
        this.deleteFile('headerImg', 'img');
        saveObj.headerImageUrl = '';
        saveObj.useHeaderImage = false;
      }
      if (this.deleteLogoImg) {
        this.deleteFile('logoImg', 'img');
        saveObj.logoImageUrl = '';
        saveObj.useLogoImage = false;
      }
      if (this.deleteFooterImg) {
        this.deleteFile('footerImg', 'img');
        saveObj.footerImageUrl = '';
      }
      if (this.deleteInvoicePdf) {
        this.deleteFile('invoicePdf', 'pdf');
        saveObj.invoicePdfUrl = '';
        saveObj.useInvoicePdf = false;
      }
      if (this.deleteVoucherPdf) {
        this.deleteFile('voucherPdf', 'pdf');
        saveObj.voucherPdfUrl = '';
        saveObj.useVoucherPdf = false;
      }
      if (this.deletePackingSlipPdf) {
        this.deleteFile('packingSlipPdf', 'pdf');
        saveObj.packingSlipPdfUrl = '';
        saveObj.usePackingSlipPdf = false;
      }
      if (this.deleteAddressesExceptions) {
        saveObj.useAddressException = false;
        // there is no functionality to delete a whole collection
        // need to retrieve all docs and then delete
        const addressExceptions = this.db
          .collection(`township/${this.township.id}/addressExceptions`)
          .get();
        addressExceptions.subscribe(async (addressException: any) => {
          addressException.forEach(async (adres) => {
            await this.db.doc(adres.ref.path).delete();
          });
        });
      }
      // if (this.sisowProfileId) {
      //   // console.log('profileId', this.sisowProfileId);
      //   saveObj.sisowProfile = this.sisowProfileId;
      // }
      saveObj.checkAddressesDbl = this.addressCheckDbl
        ? this.addressCheckDbl
        : false;
      saveObj.smsVerification = this.smsVerification
        ? this.smsVerification
        : false;
      const batches = [];
      let batchIndex = 0;
      let operationCounter = 0;
      let addressesSpreadsheetError;
      if (this.addressWorksheet) {
        const spreadsheet = {};
        Object.keys(this.addressWorksheet).forEach((key) => {
          try {
            if (
              key !== '!ref' &&
              key !== '!margins' &&
              key !== '!autofilter' &&
              key !== '!merges'
            ) {
              const rowId = key.match(/\d+/g).toString();
              const colId = key.match(/[a-zA-Z]+/g).toString();
              if (!spreadsheet[rowId]) {
                spreadsheet[rowId] = {};
              }
              spreadsheet[rowId][colId] =
                this.addressWorksheet[key].w ?? this.addressWorksheet[key].v;
            }
          } catch (error) {
            console.error(error);
          }
        });
        const columnNames = spreadsheet[1];
        Object.keys(columnNames).forEach((key) => {
          key = key;
          const val = columnNames[key].toLowerCase();
          switch (val) {
            default:
              delete columnNames[key];
              break;
            case 'postcode':
              columnNames[key] = 'postal';
              break;
            case 'huisnummer':
              columnNames[key] = 'houseNumber';
              break;
            case 'toevoeging':
              columnNames[key] = 'houseNumberAddition';
              break;
            case 'kenmerk':
              columnNames[key] = 'type';
              break;
            case 'straatnaam':
              columnNames[key] = 'street';
              break;
            case 'woonplaats':
              columnNames[key] = 'place';
              break;
            case 'woz waarde':
              columnNames[key] = 'wozValue';
              break;
            case 'bouwjaar':
              columnNames[key] = 'buildYear';
              break;
            case 'energielabel':
              columnNames[key] = 'energyLabel';
              break;
            case 'oppervlakte':
              columnNames[key] = 'surfaceM2';
              break;
            case 'pincode':
              columnNames[key] = 'pin';
              break;
          }
        });
        delete spreadsheet[1];
        batches[0] = this.db.firestore.batch();
        Object.keys(spreadsheet).forEach((key) => {
          const rowObj = {} as any;
          Object.keys(spreadsheet[key]).forEach((colKey) => {
            const colName = columnNames[colKey];
            if (colName && spreadsheet[key][colKey].length !== 0) {
              rowObj[colName] = spreadsheet[key][colKey];
              // set rowObj with value from spreadsheet for ALL fields
              if (colName === 'postal') {
                rowObj[colName] = spreadsheet[key][colKey]
                  .toUpperCase()
                  .replace(/ /g, '');
              }
              if (colName === 'houseNumberAddition') {
                rowObj[colName] = spreadsheet[key][colKey]
                  .toLowerCase()
                  .replace(/ /g, '');
              }
              if (colName === 'surfaceM2') {
                rowObj[colName] = this.prepareNumberForDb(
                  spreadsheet[key][colKey]
                );
              }
              if (colName === 'wozValue') {
                rowObj[colName] = this.prepareNumberForDb(
                  spreadsheet[key][colKey]
                );
              }
            }
          });
          let newtypes = [];
          if (rowObj.type && rowObj.type.includes(',')) {
            newtypes = rowObj.type
              .split(',')
              .map((item: string) => item.trim().toLowerCase());
          } else if (rowObj.type && !rowObj.type.includes(',')) {
            // cannot use else because of empty excel field
            newtypes = [rowObj.type.toString().toLowerCase()];
          }

          if (newtypes && newtypes.length > 0) {
            newtypes.forEach(async (type) => {
              const addresTypeRef = this.db.doc(
                `township/${this.township.id}/addresTypes/${type}`
              );
              if (operationCounter == 500) {
                batchIndex++;
                operationCounter = 0;
                batches[batchIndex] = this.db.firestore.batch();
              }
              batches[batchIndex].set(
                addresTypeRef.ref,
                { type: type },
                { merge: true }
              );
              operationCounter++;
            });
          }
          if (newtypes.length > 0) {
            rowObj.type = newtypes;
          } else {
            rowObj.type = deleteField();
          }
          // Only upload address if it has both of the required values
          if (rowObj.postal && rowObj.houseNumber) {
            const addressString =
              rowObj.postal +
              rowObj.houseNumber +
              (rowObj.houseNumberAddition ? rowObj.houseNumberAddition : '');
            const ref = this.db
              .collection(`township/${this.township.id}/addresses/`)
              .doc(addressString);
            if (operationCounter == 500) {
              batchIndex++;
              operationCounter = 0;
              batches[batchIndex] = this.db.firestore.batch();
            }
            batches[batchIndex].set(ref.ref, rowObj, { merge: true });
            operationCounter++;
          } else {
            addressesSpreadsheetError = true;
          }
        });
        // console.loag('batches', batches);
        // Object.keys(batches).forEach((key) => {
        //   const currentRow = spreadsheetObj[key];
        // });
      }

      let postalsSpreadsheetError;
      if (this.postalsWorksheet) {
        const postals = [];
        const spreadsheet = {};
        Object.keys(this.postalsWorksheet).forEach((key) => {
          try {
            if (
              key !== '!ref' &&
              key !== '!margins' &&
              key !== '!autofilter' &&
              key !== '!merges'
            ) {
              const rowId = key.match(/\d+/g).toString();
              const colId = key.match(/[a-zA-Z]+/g).toString();
              if (!spreadsheet[rowId]) {
                spreadsheet[rowId] = {};
              }
              spreadsheet[rowId][colId] = this.postalsWorksheet[key].w;
            }
          } catch (error) {
            console.error(error);
          }
        });
        const columnNames = spreadsheet[1];
        Object.keys(columnNames).forEach((key) => {
          key = key;
          const val = columnNames[key].toLowerCase();
          switch (val) {
            default:
              delete columnNames[key];
              break;
            case 'postcode cijfers':
              columnNames[key] = 'postal';
              break;
          }
        });
        delete spreadsheet[1];
        Object.keys(spreadsheet).forEach((key) => {
          const rowObj = {} as Address;
          Object.keys(spreadsheet[key]).forEach((colKey) => {
            const colName = columnNames[colKey];
            if (colName && spreadsheet[key][colKey].length !== 0) {
              rowObj[colName] = spreadsheet[key][colKey];
            }
          });

          // Only upload voucher if it has both of the required values
          if (rowObj.postal && rowObj.postal.length === 4) {
            postals.push(rowObj.postal);
          } else {
            postalsSpreadsheetError = true;
          }
        });
        // console.loag('batches', batches);
        // Object.keys(batches).forEach((key) => {
        //   const currentRow = spreadsheetObj[key];
        // });
        saveObj.postals = postals;
      }
      let addressExceptionError;
      operationCounter = 0;
      batchIndex += 1;
      if (this.addressExceptionWorksheet) {
        const addressExcpetion = [];
        const spreadsheet = {};
        Object.keys(this.addressExceptionWorksheet).forEach((key) => {
          try {
            if (
              key !== '!ref' &&
              key !== '!margins' &&
              key !== '!autofilter' &&
              key !== '!merges'
            ) {
              const rowId = key.match(/\d+/g).toString();
              const colId = key.match(/[a-zA-Z]+/g).toString();
              if (!spreadsheet[rowId]) {
                spreadsheet[rowId] = {};
              }
              spreadsheet[rowId][colId] = this.addressExceptionWorksheet[key].w;
            }
          } catch (error) {
            console.error(error);
          }
        });
        const columnNames = spreadsheet[1];
        Object.keys(columnNames).forEach((key) => {
          key = key;
          const val = columnNames[key].toLowerCase();
          switch (val) {
            default:
              delete columnNames[key];
              break;
            case 'postcode':
              columnNames[key] = 'postal';
              break;
            case 'huisnummer':
              columnNames[key] = 'houseNumber';
              break;
            case 'toevoeging':
              columnNames[key] = 'houseNumberAddition';
              break;
            case 'kenmerken':
              columnNames[key] = 'type';
              break;
          }
        });
        delete spreadsheet[1];
        batches[batchIndex] = this.db.firestore.batch();
        Object.keys(spreadsheet).forEach((key) => {
          const rowObj = {} as Address;
          Object.keys(spreadsheet[key]).forEach((colKey) => {
            const colName = columnNames[colKey];
            if (colName && spreadsheet[key][colKey].length !== 0) {
              rowObj[colName] = spreadsheet[key][colKey];
            }
          });
          console.log('rowObj', rowObj);

          // Only upload voucher if it has both of the required values
          if (
            rowObj.postal &&
            rowObj.postal.length === 6 &&
            rowObj.houseNumber
          ) {
            if (rowObj.type) {
              const types = rowObj.type as any; // redeclare var because .split() is not a function on string[]
              console;
              rowObj.type = types.split(','); //Use split() instead of slice()
            }

            if (operationCounter == 500) {
              batchIndex++;
              operationCounter = 0;
              batches[batchIndex] = this.db.firestore.batch();
            }

            rowObj.postal = rowObj.postal.replace(' ', ''); // remove whitespaces
            rowObj.houseNumberAddition = rowObj.houseNumberAddition
              ? rowObj.houseNumberAddition
              : '';
            const docId =
              rowObj.postal + rowObj.houseNumber + rowObj.houseNumberAddition;

            // houseNumber is a string because then we can catch the error if an housenumber addition is typed in by mistake (or no mistake)
            batches[batchIndex].set(
              this.db.doc(
                `township/${this.township.id}/addressExceptions/${docId}`
              ).ref,
              rowObj,
              { merge: true }
            );
            operationCounter++;

            addressExcpetion.push(rowObj);
          } else {
            addressExceptionError = true;
          }
          console.log('rowObj', rowObj);
        });
        // console.loag('batches', batches);
        // Object.keys(batches).forEach((key) => {
        //   const currentRow = spreadsheetObj[key];
        // });
        saveObj.useAddressException = true;
      }
      if (
        !addressesSpreadsheetError ||
        !postalsSpreadsheetError ||
        !addressExceptionError
      ) {
        if (saveObj.voucherPrefix === 'groene') {
          saveObj.voucherImg = this.env.prefixImgGroene;
        } else {
          saveObj.voucherImg = this.env.prefixImgLokale;
        }
        let planObject;
        if (
          saveObj['maxVouchers'] !== this.township.plan.maxVouchers ||
          saveObj['maxVouchersPerGroup'] !==
            this.township.plan.maxVouchersPerGroup
        ) {
          if (
            saveObj['maxVouchers'] !== this.township.plan.maxVouchers &&
            saveObj['maxVouchersPerGroup'] !==
              this.township.plan.maxVouchersPerGroup
          ) {
            planObject = {
              id: 'custom',
              type: 'custom',
              plangroup: 'custom',
              name: 'betaald',
              maxVouchers: Number(saveObj['maxVouchers']),
              maxVouchersPerGroup: Number(saveObj['maxVouchersPerGroup']),
            };
          } else if (
            saveObj['maxVouchers'] !== this.township.plan.maxVouchers &&
            saveObj['maxVouchersPerGroup'] ===
              this.township.plan.maxVouchersPerGroup
          ) {
            planObject = {
              id: 'custom',
              type: 'custom',
              plangroup: 'custom',
              name: 'betaald',
              maxVouchers: Number(saveObj['maxVouchers']),
              maxVouchersPerGroup: this.township.plan.maxVouchersPerGroup,
            };
          } else if (
            saveObj['maxVouchers'] === this.township.plan.maxVouchers &&
            saveObj['maxVouchersPerGroup'] !==
              this.township.plan.maxVouchersPerGroup
          ) {
            planObject = {
              id: 'custom',
              type: 'custom',
              plangroup: 'custom',
              name: 'betaald',
              maxVouchers: this.township.plan.maxVouchers,
              maxVouchersPerGroup: Number(saveObj['maxVouchersPerGroup']),
            };
          }

          await this.db
            .doc(`township/${this.township.id}`)
            .update({ plan: planObject });
        }

        saveObj.confidentialEmails = this.confidentialEmails ?? [];
        saveObj.confidentialPhoneNumbers = this.confidentialPhoneNumbers ?? [];
        saveObj['maxVouchers'] = deleteField();
        saveObj['maxVouchersPerGroup'] = deleteField();
        await setDoc(
          doc(
            this.db.firestore,
            `township/${this.township.id}/settings/public`
          ),
          {
            termsUrl: saveObj.termsUrl ?? null,
            url: saveObj.url ?? null,
            voucherPrefix: saveObj.voucherPrefix ?? null,
            name: saveObj.name ?? null,
            primaryColor: saveObj.primaryColor ?? null,
            accentColor: saveObj.accentColor ?? null,
            headerImageUrl: saveObj.headerImageUrl ?? null,
            logoImageUrl: saveObj.logoImageUrl ?? null,
            idealMerchantId: saveObj.idealMerchantId ?? null,
          },
          { merge: true }
        );
        await setDoc(
          doc(this.db.firestore, `township/${this.township.id}`),
          saveObj,
          { merge: true }
        );
        if (saveObj.externalVoucherGroupId) {
          let externalVoucherGroup;
          if (this.externalVoucherGroups && this.externalVoucherGroupsArray) {
            externalVoucherGroup = this.externalVoucherGroupsArray.find(
              (voucherGroup) => {
                return voucherGroup.id === saveObj.externalVoucherGroupId;
              }
            );
          }
          if (externalVoucherGroup) {
            const name = externalVoucherGroup.name
              ? externalVoucherGroup.name
              : 'Externe Campagne';
            const orgObj = {
              name,
              externalVouchersOrg: true,
            } as Organisation;

            if (externalVoucherGroup.sepaSettingsComplete) {
              orgObj.sepaSettingsComplete = true;
              orgObj.sepaSettings = externalVoucherGroup.sepaSettings;
            }
            await setDoc(
              doc(
                this.db.firestore,
                `township/${this.township.id}/organisations/${saveObj.externalVoucherGroupId}`
              ),
              {
                orgObj,
              },
              { merge: true }
            );
          }
        }
        if (saveObj.usesPlanningEnvironment) {
          await this.addDefaultPlanningData();
        }
        batches.forEach(async (batch) => {
          await batch.commit();
        });
        if (this.deleteAddresses) {
          const requestUrl = `${environment.functionsUrl}/httpDeleteAddresses`;
          let res: Observable<any>;
          const postData = {
            townshipId: this.township.id,
          };
          res = this.http.post(requestUrl, postData);
          res.subscribe(async (result) => {
            if (result.message === 'succeed') {
              this.snackBar.open('Veranderingen zijn opgeslagen', 'X', {
                duration: 5000,
              });
              this.saving = false;
            } else {
              this.snackBar.open('Oops er iets verkeerds gegaan', 'X', {
                duration: 4000,
              });
            }
          });
        } else {
          this.snackBar.open('Veranderingen zijn opgeslagen', 'X', {
            duration: 5000,
          });
          const townshipData = await getDoc(
            doc(
              this.db.firestore,
              `township/${localStorage.getItem('township')}`
            )
          );
          const township = {
            ...townshipData.data(),
            id: townshipData.id,
          } as Township;
          dataStore.township = township;
          this.township = township;
          this.saving = false;
        }
      } else {
        this.saving = false;
        if (!addressesSpreadsheetError) {
          this.snackBar.open(
            'Eén of meer adressen bevatten niet alle vereisde velden: postcode en huisnummer.',
            'X',
            {
              duration: 3000,
            }
          );
        } else if (!postalsSpreadsheetError) {
          this.snackBar.open(
            'Eén of meer postcodecijfers bevatten niet alle vereisde velden: postcodecijfers (4 karakters).',
            'X',
            {
              duration: 3000,
            }
          );
        } else if (!addressExceptionError) {
          this.snackBar.open(
            'De adres uitzonderingen zijn niet in het juiste format.',
            'X',
            {
              duration: 3000,
            }
          );
        }
      }
    } else {
      this.settingsForm.controls['prefix'].setErrors({ incorrect: true });
      this.saving = false;
    }
  }
  openFileInput(htmlId) {
    const element: HTMLElement = document.getElementById(htmlId) as HTMLElement;
    element.click();
  }
  uploadedFile(event, type) {
    this.formChanged = true;
    const file = event.target.files[0] as File;
    if (type === 'postals') {
      this.postalsFileUploaded = event.target.files[0];
      this.readExcel(type);
    } else if (type === 'address') {
      this.addressFileUploaded = event.target.files[0];
      this.readExcel(type);
    } else if (type === 'headerImg') {
      if (file.size > 2048000) {
        this.snackBar.open('Dit bestand moet onder de 2 MB zijn.', 'X', {
          duration: 5000,
        });
        return;
      } else {
        this.headerImgFileUploaded = file;
      }
    } else if (type === 'logoImg') {
      if (file.size > 2048000) {
        this.snackBar.open('Dit bestand moet onder de 2 MB zijn.', 'X', {
          duration: 5000,
        });
        return;
      } else {
        this.logoImgFileUploaded = file;
      }
    } else if (type === 'footerImg') {
      if (file.size > 2048000) {
        this.snackBar.open('Dit bestand moet onder de 2 MB zijn.', 'X', {
          duration: 5000,
        });
        return;
      } else {
        this.footerImgFileUploaded = file;
      }
    } else if (type === 'invoicePdf') {
      this.invoicePdfFileUploaded = file;
    } else if (type === 'voucherPdf') {
      const dialogRef = this.dialog.open(SelectPdfFontsComponent, {
        width: '373px',
        data: { file: file },
      });
      dialogRef.afterClosed().subscribe(async (result) => {
        if (result) {
          this.voucherPdfFonts = result;
          this.voucherPdfFileUploaded = file;
        }
      });
    } else if (type === 'packingSlipPdf') {
      this.packingSlipPdfFileUploaded = file;
    } else if (type === 'addressException') {
      this.addressExceptionFileUploaded = event.target.files[0];
      this.readExcel(type);
    }
  }
  readExcel(type) {
    const readFile = new FileReader();
    readFile.onload = (e) => {
      const storeData = readFile.result as any;
      const data = new Uint8Array(storeData);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, { type: 'binary' });
      const firstSheetName = workbook.SheetNames[0];
      if (type === 'postals') {
        this.postalsWorksheet = workbook.Sheets[firstSheetName];
      } else if (type === 'address') {
        this.addressWorksheet = workbook.Sheets[firstSheetName];
      } else if (type === 'addressException') {
        this.addressExceptionWorksheet = workbook.Sheets[firstSheetName];
      }
    };
    if (type === 'postals') {
      readFile.readAsArrayBuffer(this.postalsFileUploaded);
    } else if (type === 'address') {
      readFile.readAsArrayBuffer(this.addressFileUploaded);
    } else if (type === 'addressException') {
      readFile.readAsArrayBuffer(this.addressExceptionFileUploaded);
    }
  }
  async exportAddresses() {
    const exportArray = [];

    const addressesRef = this.db.collection(
      `township/${this.township.id}/addresses/`
    );
    const addressesObservable = await addressesRef.get();
    await addressesObservable.forEach((addresses) => {
      addresses.forEach((addressDoc) => {
        const exportObj = {};
        const address = addressDoc.data() as Address;
        if (address.houseNumber.includes(' ')) {
          return;
        }
        exportObj['Postcode'] = address.postal;
        exportObj['Huisnummer'] = address.houseNumber;
        exportObj['Toevoeging'] = address.houseNumberAddition;
        exportObj['Kenmerk'] = address.type ? address.type.join(', ') : '';
        exportObj['Straatnaam'] = address.street ? address.street : '';
        exportObj['Woonplaats'] = address.place ? address.place : '';
        exportObj['WOZ waarde'] = address.wozValue ? address.wozValue : '';
        exportObj['Bouwjaar'] = address.buildYear ? address.buildYear : '';
        exportObj['Energielabel'] = address.energyLabel
          ? address.energyLabel
          : '';
        exportObj['Oppervlakte'] = address.surfaceM2 ? address.surfaceM2 : '';
        exportObj['Pincode'] = address.pin ? address.pin : '';
        exportArray.push(exportObj);
      });
    });
    if (exportArray.length === 0) {
      exportArray.push({
        Postcode: '',
        Huisnummer: '',
        Toevoeging: '',
        Type: '',
        Straatnaam: '',
        Woonplaats: '',
      });
    }
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportArray); // converts a DOM TABLE element to a worksheet
    const columns = [6, 9];
    const rows = exportArray.length;
    columns.forEach((C) => {
      for (let R = 1; R <= rows; R = R + 1) {
        let cell = ws[XLSX.utils.encode_cell({ r: R, c: C })];
        cell.z = '#,##00.00';
      }
    });
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Addressenlijst');

    // /* save to file */
    XLSX.writeFile(wb, this.env.name + '.' + 'Addressenlijst' + '.xlsx');
  }
  async exportPostals() {
    const exportArray = [];
    if (this.township.postals) {
      this.township.postals.forEach((postal) => {
        const exportObj = {};
        exportObj['Postcode cijfers'] = postal;
        exportArray.push(exportObj);
      });
    } else {
      exportArray.push({
        'Postcode cijfers': '',
      });
    }
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportArray); // converts a DOM TABLE element to a worksheet
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Postcodelijst');

    // /* save to file */
    XLSX.writeFile(wb, this.env.name + '.' + 'Postcodelijst' + '.xlsx');
  }
  async exportExceptionalAdresses() {
    const exportArray = [];
    if (this.township.useAddressException) {
      const allAdressesExceptions = await getDocs(
        collection(
          this.db.firestore,
          `township/${this.township.id}/addressExceptions`
        )
      );

      allAdressesExceptions.forEach(async (adres) => {
        const exportObj = {}; // need to reassign var because otherwise order in xlsx is wrong
        const addressData = adres.data() as AddressException;
        exportObj['Postcode'] = addressData.postal;
        exportObj['Huisnummer'] = addressData.houseNumber;
        exportObj['Toevoeging'] = addressData.houseNumberAddition
          ? addressData.houseNumberAddition
          : '';
        exportObj['Kenmerken'] = addressData.type
          ? addressData.type.toString()
          : '';
        exportArray.push(exportObj);
      });
    } else {
      exportArray.push({
        Postcode: '',
        Huisnummer: '',
        Toevoeging: '',
        Kenmerken: '',
      });
    }

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportArray); // converts a DOM TABLE element to a worksheet
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Uitzonderingen adressen');

    // /* save to file */
    XLSX.writeFile(
      wb,
      this.env.name + '.' + 'Uitzonderingen adressen' + '.xlsx'
    );
  }

  navigateTo(url) {
    if (this.formChanged) {
      // show modal
      const dialogRef = this.dialog.open(ContinueComponent, {
        width: '373px',
      });
      dialogRef.afterClosed().subscribe(async (result) => {
        if (result) {
          this.router.navigateByUrl(url);
        }
      });
    } else {
      this.router.navigateByUrl(url);
    }
  }
  colorChanged(color, colorController) {
    colorController.setValue(color);
  }
  urlCheck(saveObj, controller) {
    let invalidUrl = false;

    const expression =
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    const regex = new RegExp(expression);

    if (!saveObj.match(regex)) {
      invalidUrl = true;
    }

    if (invalidUrl) {
      if (controller === 'terms') {
        this.settingsForm.controls.termsUrl.setErrors({
          notMatched: true,
        });
      } else {
        this.settingsForm.controls.url.setErrors({
          notMatched: true,
        });
      }
      this.saving = false;
      return false;
    } else {
      return true;
    }
  }
  async copyUrl(
    primaryUrlPart: string,
    options?: {
      language?: string;
      secondUrlPart?: string;
      usesTownshipUniqueName?: boolean;
    }
  ): Promise<void> {
    if (!options) {
      options = {};
    }
    if (!options.secondUrlPart) {
      options.secondUrlPart = '';
    } else {
      options.secondUrlPart = '/' + options.secondUrlPart;
    }
    const urlTownshipId = options.usesTownshipUniqueName
      ? this.township.uniqueName
      : this.township.id;

    let url = await iframeUrl(
      this.township.id,
      this.db.firestore,
      options.language
    );
    let domain = this.township.voucherPrefix;
    switch (domain) {
      case 'duurzaamwonen':
        domain = 'duurzaamwonenbon';
        break;
      case 'lokale':
        domain = 'lokalebon';
        break;
      case 'groene':
        domain = 'groenebon';
        break;
    }
    if (this.env.production) {
      url = `${url}/${primaryUrlPart}/${urlTownshipId}${options.secondUrlPart}`;
    } else {
      url = `${url}/${primaryUrlPart}/${urlTownshipId}${options.secondUrlPart}`;
    }
    navigator.clipboard.writeText(url);
    this.snackBar.open('Link gekopieerd.', 'X', {
      duration: 3000,
    });
  }
  async openFile(type: string): Promise<any> {
    if (type === 'headerImg') {
      if (this.headerImgFileAlreadyUploaded) {
        window.open(this.headerImgUrl);
      }
    } else if (type === 'logoImg') {
      if (this.logoImgFileAlreadyUploaded) {
        window.open(this.logoImgUrl);
      }
    } else if (type === 'footerImg') {
      if (this.footerImgFileAlreadyUploaded) {
        window.open(this.footerImgUrl);
      }
    } else if (type === 'invoicePdf') {
      if (this.invoicePdfFileAlreadyUploaded) {
        window.open(this.invoicePdfUrl);
      }
    } else if (type === 'voucherPdf') {
      if (this.voucherPdfFileAlreadyUploaded) {
        window.open(this.voucherPdfUrl);
      }
    } else if (type === 'packingSlipPdf') {
      if (this.packingSlipPdfFileAlreadyUploaded) {
        window.open(this.packingSlipPdfUrl);
      }
    }
  }

  setDelete(type: string): void {
    let name = '';

    if (type === 'headerImg') {
      this.deleteHeaderImg = true;
      name = 'E-mail header';
    } else if (type === 'logoImg') {
      this.deleteLogoImg = true;
      name = 'E-mail logo';
    } else if (type === 'footerImg') {
      this.deleteFooterImg = true;
      name = 'E-mail footer';
    } else if (type === 'invoicePdf') {
      name = 'PDF';
      this.deleteInvoicePdf = true;
    } else if (type === 'voucherPdf') {
      name = 'PDF';
      this.deleteVoucherPdf = true;
    } else if (type === 'packingSlipPdf') {
      name = 'PDF';
      this.deletePackingSlipPdf = true;
    } else if (type === 'addressException') {
      name = 'Excel';
      this.deleteAddressesExceptions = true;
    }
    this.snackBar.open(
      `Druk op opslaan om de ${name} definitief te verwijderen`,
      'X',
      {
        duration: 3500,
      }
    );
  }

  deleteFile(type: string, extension: string): void {
    let segment;
    // let extension = '';
    let fileName;
    if (type === 'pdf') {
      segment = 'pdf-templates';
      // extension = '.pdf';
      fileName = this.township.id;
    } else if (type === 'headerImg') {
      segment = 'images';
      fileName = this.township.id + 'header';
    } else if (type === 'logoImg') {
      segment = 'images';
      fileName = this.township.id + 'logo';
    } else if (type === 'footerImg') {
      segment = 'images';
      fileName = this.township.id + 'footer';
    } else if (type === 'invoicePdf') {
      // extension = '.pdf';
      segment = 'pdf-templates';
      fileName = 'factuur.pdf';
    } else if (type === 'voucherPdf') {
      // extension = '.pdf';
      segment = 'pdf-templates';
      fileName = 'bon.pdf';
    } else if (type === 'packingSlipPdf') {
      segment = 'pdf-templates';
      fileName = 'pakbon.pdf';
    }
    const name = extension === 'pdf' ? 'PDF' : 'E-mail header';
    const fileUrl = `${this.township.id}/${segment}/${fileName}`;

    const file = this.storage.ref(fileUrl).delete();
    file.subscribe((result) => {});
  }

  async openSetupPaymentDialog() {
    const dialogRef = this.dialog.open(SetupPaymentDialogComponent, {
      width: '600px',
      height: 'auto',
      data: { township: this.township.id },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'succeed') {
        this.checkboxCheck = true;
      }
    });
  }

  openForceApiSyncDialog(): void {
    this.dialog.open(ForceApiSyncDialogComponent, {
      width: '375px',
    });
  }

  openExportReportDialogComponent() {
    this.dialog.open(ExportReportDialogComponent, {
      width: '500px',
      data: {
        townshipId: this.township.id,
      },
    });
  }

  confirmDeleteAddress(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '375px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteAddresses = true;
        this.snackBar.open(
          'Druk op opslaan om de adressen definitief te verwijderen',
          'X',
          {
            duration: 5000,
          }
        );
      } else {
        this.deleteAddresses = false;
      }
    });
  }

  openGroupLinkDialog() {
    const dialog = this.dialog.open(GroupLinkDialogComponent, {
      width: '700px',
    });
    dialog.afterClosed().subscribe((result) => {
      if (result.copy) {
        const url = `${iframeUrl(
          this.township.id,
          this.db.firestore
        )}/group-link/${this.township.id}/${result.groupLink}`;
        navigator.clipboard.writeText(url);
        this.snackBar.open('Link gekopieerd.', 'X', {
          duration: 3000,
        });
      }
    });
  }

  async checkBlacklistedAddreses() {
    this.checkingBlacklistedAddreses = true;
    try {
      const response = (await lastValueFrom(
        this.http.post(
          `${environment.functionsUrl}/httpCheckBlacklistedVouchers`,
          {
            townshipId: this.township.id,
          }
        )
      )) as { message: string };
      let txt: string;
      if (response && response.message === 'succeed') {
        txt = 'De bonnen zijn bijgewerkt';
      } else if (response.message === 'missing_parameters') {
        txt =
          'Het verzoek is mislukt omdat niet alle gegevens zijn doorgestuurd.';
      }
      this.checkingBlacklistedAddreses = false;
      this.snackBar.open(txt, 'X', {
        duration: 5000,
      });
    } catch (e) {
      this.checkingBlacklistedAddreses = false;
      this.snackBar.open(
        'Er is iets missgegaan. Neem contact op of probeer het later opnieuw',
        'X',
        {
          duration: 5000,
        }
      );
    }
  }

  toggleChanges($event: MatSlideToggleChange) {
    this.addressCheckDbl = $event.checked;
  }

  toggleSMSVerification($event: MatSlideToggleChange) {
    this.smsVerification = $event.checked;
  }

  openImportExport() {
    const dialogRef = this.dialog.open(ImportExportComponent, {
      width: '375px',
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result && result.type === 'export') {
        this.transfering = true;
        await this.exportTownshipData(this.township.id, this.collections);
        this.transfering = false;
      }
      if (result && result.type === 'import') {
        this.uploader.nativeElement.click();
      }
    });
  }

  delay = (millis: any) =>
    new Promise<void>((resolve) => {
      setTimeout((_) => resolve(), millis);
    });

  async importTownshipData(event) {
    try {
      if (this.uploader.nativeElement.value === null) {
        return;
      } else {
        this.transfering = true;
        const upload = await this.st
          .upload(
            `${this.township.id}/backups/${this.township.id}`,
            event.target.files[0]
          )
          .percentageChanges();
        upload.subscribe(async (percentage) => {
          if (percentage === 100) {
            await setDoc(
              doc(
                this.db.firestore,
                `township/${this.township.id}/backup-planned/${this.township.id}`
              ),
              {
                backupPlanned: true,
                township: this.township.id,
                backupDone: false,
              }
            );
          }
        });
      }
    } catch (e) {
      console.error(e);
      this.snackBar.open('Er is iets misgegaan bij het importeren', 'X', {
        duration: 5000,
      });
    }
  }

  async exportTownshipData(townshipId: string, collections: string[]) {
    try {
      const saveObj = {} as any;
      const settings = (
        await getDoc(doc(this.db.firestore, `township/${townshipId}`))
      ).data() as Township;
      saveObj['settings'] = settings;
      for (const col of collections) {
        if (col === 'vouchers' || col === 'addresses') {
          saveObj[col] = [];
        } else {
          saveObj[col] = {};
        }
        let collectionDoc;
        collectionDoc = await getDocs(
          collection(this.db.firestore, `township/${townshipId}/${collection}`)
        );
        for (const collectionData of collectionDoc) {
          let data = collectionData.data();
          if (data.date) {
            data.date = new Date(data.date.toDate());
          }
          if (data.validUntilDate) {
            try {
              data.validUntilDate = new Date(data.validUntilDate.toDate());
            } catch (e) {
              data.validUntilDate = new Date(data.validUntilDate);
            }
          }
          if (data.activateDate && data.activateDate !== null) {
            try {
              data.activateDate = new Date(data.activateDate.toDate());
            } catch (e) {
              data.activateDate = new Date(data.activateDate);
            }
          }
          if (data.claimDate && data.activateDate !== null) {
            try {
              data.claimDate = new Date(data.claimDate.toDate());
            } catch (e) {
              data.claimDate = new Date(data.claimDate);
            }
          }
          if (data.paidDate && data.activateDate !== null) {
            try {
              data.paidDate = new Date(data.paidDate.toDate());
            } catch (e) {
              data.paidDate = new Date(data.paidDate);
            }
          }
          if (data.expiredDate && data.activateDate !== null) {
            try {
              data.expiredDate = new Date(data.expiredDate.toDate());
            } catch (e) {
              data.expiredDate = new Date(data.expiredDate);
            }
          }
          if (data.usableFrom && data.activateDate !== null) {
            try {
              data.usableFrom = new Date(data.usableFrom.toDate());
            } catch (e) {
              data.usableFrom = new Date(data.usableFrom);
            }
          }
          if (data.startDate && data.activateDate !== null) {
            try {
              data.startDate = new Date(data.startDate.toDate());
            } catch (e) {
              data.startDate = new Date(data.startDate);
            }
          }
          if (data.endDate && data.activateDate !== null) {
            try {
              data.endDate = new Date(data.endDate.toDate());
            } catch (e) {
              data.endDate = new Date(data.endDate);
            }
          }

          if (data.userRef && typeof data.userRef !== 'string') {
            const userId = data.userRef.f_.path.segments[6];
            data.userRef = `township/${townshipId}/users/${userId}`;
          }
          if (col === 'vouchers' || col === 'addresses') {
            saveObj[col].push(data);
          } else {
            saveObj[col][collectionData.id] = data;
          }
        }
      }
      this._FileSaverService.save(
        new Blob([JSON.stringify(saveObj)]),
        `${this.township.name}-backup-${new Date().toLocaleDateString()}.json`
      );
    } catch (e) {
      console.error(e);
    }
  }

  async recalcStats() {
    const requestUrl = `${environment.functionsUrl}/httpRecalcStatistics`;
    this.http
      .post(requestUrl, {
        type: 'township',
        townshipId: localStorage.getItem('township'),
      })
      .subscribe((res) => {});
    this.snackBar.open(
      'Statistieken worden herberekend, even geduld a.u.b.',
      'X',
      {
        duration: 5000,
      }
    );
  }

  prepareNumberForDb(content: any) {
    const REGEX = new RegExp(
      `([0-9]{1,3})*([,.]{1}[0-9]{3})*([,.]{1}[0-9]{1,2})?`
    );
    content = content.toString();
    content = content.replace(REGEX, (match, p1, p2, p3) => {
      let lastIndex = content.lastIndexOf('.');
      if (p3 && p3 !== undefined) {
        if (p3.indexOf(',') !== -1) {
          lastIndex = content.lastIndexOf(',');
          content =
            content.substring(0, lastIndex) +
            '.' +
            content.substring(lastIndex + 1);
        }
      }
      let i = lastIndex - 1;
      while (i >= 0) {
        if (content[i] === ',' || content[i] === '.') {
          content = content.substring(0, i) + content.substring(i + 1);
        }
        i = i - 1;
      }
      return content;
    });
    return Number(content);
  }

  async addDefaultPlanningData() {
    const ref = collection(db, `township/${this.township.id}/dayParts`);
    const count = (await getCountFromServer(ref)).data().count;
    const days: string[] = [
      'Zondag',
      'Maandag',
      'Dinsdag',
      'Woensdag',
      'Donderdag',
      'Vrijdag',
      'Zaterdag',
    ];
    if (count == 0) {
      const ref = collection(db, `/township/${this.township.id}/dayParts`);
      for (let i = 0; i <= 6; i++) {
        let enabled = true;
        if (i == 0 || i == 6) {
          enabled = false;
        }
        const morning = {
          name: days[i],
          part: 'Ochtend',
          day: i,
          startHour: 8,
          startMinutes: 0,
          endHour: 12,
          endMinutes: 0,
          enabled: enabled,
        };
        const afternoon = {
          name: days[i],
          part: 'Middag',
          day: i,
          startHour: 13,
          startMinutes: 0,
          endHour: 17,
          endMinutes: 0,
          enabled: enabled,
        };
        await addDoc(ref, morning);
        await addDoc(ref, afternoon);
      }
    }
    const labelRef = query(
      collection(db, `township/${this.township.id}/distanceLabels`)
    );
    const labelCount = (await getCountFromServer(labelRef)).data().count;
    if (labelCount == 0) {
      const labels = [
        ['#BA1A1A', true, 'sentiment_dissatisfied', 'Ver weg', null, 50],
        ['#F09552', true, 'sentiment_neutral', 'Verder weg', 50, 30],
        ['#ADF2C6', false, 'sentiment_very_satisfied', 'Dichtbij', 10, null],
        ['#F5DC6A', false, 'sentiment_satisfied', 'Gemiddeld', 30, 10],
      ];
      const ref = collection(
        db,
        `/township/${this.township.id}/distanceLabels`
      );
      for (const label of labels) {
        await addDoc(ref, {
          color: label[0],
          contrast: label[1],
          icon: label[2],
          name: label[3],
          rangeEnd: label[4],
          rangeStart: label[5],
        });
      }
    }
    const particularityRef = query(
      collection(db, `township/${this.township.id}/planningParticularities`),
      where('default', '==', true)
    );
    const particularityCount = (
      await getCountFromServer(particularityRef)
    ).data().count;
    if (particularityCount == 0) {
      const particularities = [
        {
          info: 'Allergisch voor dieren',
          iconPath: '../assets/images/default-particularities/pets.svg',
        },
        {
          info: 'Niet roken',
          iconPath: '../assets/images/default-particularities/smoke_free.svg',
        },
      ];
      const ref = collection(
        db,
        `/township/${this.township.id}/planningParticularities`
      );
      for (const particularity of particularities) {
        await addDoc(ref, { default: true, ...particularity });
      }
    }
  }
}

export function number(prms: { min?: number; max?: number } = {}): ValidatorFn {
  return (control: UntypedFormControl): { [key: string]: any } => {
    let val: number = control.value;
    if (!val && val !== 0) {
      return null;
    }
    if (isNaN(val) || /\D/.test(val.toString())) {
      return { number: true };
    } else if (!isNaN(prms.min) && !isNaN(prms.max)) {
      return val < prms.min || val > prms.max ? { number: true } : null;
    } else if (!isNaN(prms.min)) {
      return val < prms.min ? { number: true } : null;
    } else if (!isNaN(prms.max)) {
      return val > prms.max ? { number: true } : null;
    } else {
      return null;
    }
  };
}
// function findInvalidControls(form: UntypedFormGroup) {
//   const invalid = [];
//   const controls = form.controls;
//   for (const name in controls) {
//     if (controls[name].invalid) {
//       invalid.push(name);
//     }
//   }
//   return invalid;
// }
