import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Appointment } from 'src/app/interfaces';

export interface DialogData {
  followup: boolean;
}

@Component({
  selector: 'app-report-sent',
  templateUrl: './report-sent.component.html',
  styleUrls: ['./report-sent.component.scss'],
})
export class ReportSentComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}
}
