import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  Appointment,
  PlanningUser,
  Township,
  Voucher,
} from 'src/app/interfaces';
import { AppointmentDeleteComponent } from '../appointment-delete/appointment-delete.component';
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import { db, functions } from 'src/app/app.component';
import { httpsCallable } from 'firebase/functions';
import { ActionType } from 'src/app/enums';
import { documentId } from 'firebase/firestore';

export interface DialogData {
  appointment: Appointment;
  planningUsers: PlanningUser[];
}

@Component({
  selector: 'app-appointment-details',
  templateUrl: './appointment-details.component.html',
  styleUrls: ['./appointment-details.component.scss'],
})
export class AppointmentDetailsComponent {
  townshipId = localStorage.getItem('township') as string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<AppointmentDetailsComponent>
  ) {}

  cancelAppointment() {
    const dialogRef = this.dialog.open(AppointmentDeleteComponent, {
      width: '315px',
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe(async (deleteAppointment) => {
      if (deleteAppointment) {
        const appointmentRef = doc(
          db,
          `township/${this.townshipId}/appointments/${this.data.appointment.id}`
        );
        await this.notifyUsers(this.data.appointment);
        const callable = httpsCallable(functions, 'pipedriveCreateActivity');
        const result = await callable({
          townshipId: this.townshipId,
          appointmentId: this.data.appointment.id,
          actionType: ActionType.canceled,
        });
        // await deleteDoc(appointmentRef);
        await setDoc(
          appointmentRef,
          { status: ActionType.canceled },
          { merge: true }
        );
        this.dialogRef.close();
      }
    });
  }

  async notifyUsers(appointmentData: Appointment) {
    const previousNotificationData = await getDoc(
      doc(
        db,
        `township/${this.townshipId}/appointmentNotificationQueue/${appointmentData.id}`
      )
    );

    if (
      previousNotificationData.exists() &&
      previousNotificationData.data().notificationType !== 'edit'
    ) {
      deleteDoc(
        doc(
          db,
          `township/${this.townshipId}/appointmentNotificationQueue/${appointmentData.id}`
        )
      );
    } else {
      const planningSettings = (
        await getDoc(doc(db, `township/${this.townshipId}/settings/planning`))
      ).data();

      const township = (
        await getDoc(doc(db, `township/${this.townshipId}`))
      ).data() as Township;

      const planningUsersToMail = [];
      const planningUserDocs = await getDocs(
        query(
          collection(db, `township/${this.townshipId}/planningUsers`),
          where(documentId(), 'in', appointmentData.planningUserIds)
        )
      );
      planningUserDocs.forEach((planningUserDoc) => {
        planningUsersToMail.push(planningUserDoc.data());
      });

      const voucher = (
        await getDoc(
          doc(
            db,
            `township/${this.townshipId}/vouchers/${appointmentData?.voucherId}`
          )
        )
      ).data() as Voucher;

      const notificationData = {
        appointmentUserToMail: {
          name: appointmentData.userName,
          email: appointmentData.userEmail,
          phone: appointmentData.userPhone,
        },
        planningUsersToMail: planningUsersToMail,
        postal: appointmentData.postal,
        houseNumber: appointmentData.houseNumber,
        houseNumberAddition: appointmentData.houseNumberAddition ?? null,
        street: appointmentData.street ?? null,
        city: appointmentData.city ?? null,
        appointmentStart: appointmentData.start,
        appointmentEnd: appointmentData.end,
        voucherId: appointmentData?.voucherId ?? null,
        notificationCreationDate: new Date(),
        notificationType: 'cancelled',
        calendarSequence: appointmentData.calendarSequence ?? 0 + 1,
        appointmentType: appointmentData.appointmentTypeName,
        coachesRemoved: [],
        coachesAdded: [],
        planningPhone: planningSettings.phoneNumber,
        voucher: voucher ?? null,
        township: township,
      };

      await setDoc(
        doc(
          db,
          `township/${this.townshipId}/appointmentNotificationQueue/${appointmentData.id}`
        ),
        notificationData,
        { merge: true }
      );
    }
  }
}
