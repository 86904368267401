// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
  justify-content: left;
}
.title mat-chip {
  margin-left: 24px;
}

.info-row {
  display: flex;
  flex-direction: row;
}
.info-row .info-child {
  flex: 1;
}

.tab-wrapper {
  overflow: hidden;
}

.rights-tab .address-types-wrapper {
  margin: 4px 0 16px;
}
.rights-tab .buttons {
  display: flex;
  justify-content: flex-end;
}

.report-tab .buttons {
  margin: 36px 0 20px;
  display: flex;
  align-items: center;
}
.report-tab .buttons .mdc-button {
  margin-right: 16px;
}
.report-tab .checkbox-wrapper {
  margin-bottom: 8px;
}
.report-tab mat-radio-group {
  margin-bottom: 5px;
  gap: 16px;
  display: flex;
}

.additional-tab .request-voucher {
  margin: 30px 0 0 0;
}
.additional-tab .follow-up-checkbox {
  margin-bottom: 10px;
}
.additional-tab .follow-up-chiplist-title {
  margin-bottom: 5px;
}

mat-form-field {
  width: 100%;
}

mat-dialog-actions {
  display: flex;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/app/planning-team/dialogs/manage-report/manage-report.component.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;AACF;AAAE;EACE,iBAAA;AAEJ;;AAEA;EACE,aAAA;EACA,mBAAA;AACF;AACE;EACE,OAAA;AACJ;;AAGA;EACE,gBAAA;AAAF;;AAIE;EACE,kBAAA;AADJ;AAIE;EACE,aAAA;EACA,yBAAA;AAFJ;;AAOE;EACE,mBAAA;EACA,aAAA;EACA,mBAAA;AAJJ;AAKI;EACE,kBAAA;AAHN;AAME;EACE,kBAAA;AAJJ;AAME;EACE,kBAAA;EACA,SAAA;EACA,aAAA;AAJJ;;AASE;EACE,kBAAA;AANJ;AAQE;EACE,mBAAA;AANJ;AAQE;EACE,kBAAA;AANJ;;AAUA;EACE,WAAA;AAPF;;AAUA;EACE,aAAA;EACA,8BAAA;AAPF","sourcesContent":[".title {\n  justify-content: left;\n  mat-chip {\n    margin-left: 24px;\n  }\n}\n\n.info-row {\n  display: flex;\n  flex-direction: row;\n\n  .info-child {\n    flex: 1;\n  }\n}\n\n.tab-wrapper {\n  overflow: hidden;\n}\n\n.rights-tab {\n  .address-types-wrapper {\n    margin: 4px 0 16px;\n  }\n\n  .buttons {\n    display: flex;\n    justify-content: flex-end;\n  }\n}\n\n.report-tab {\n  .buttons {\n    margin: 36px 0 20px;\n    display: flex;\n    align-items: center;\n    .mdc-button {\n      margin-right: 16px;\n    }\n  }\n  .checkbox-wrapper {\n    margin-bottom: 8px;\n  }\n  mat-radio-group {\n    margin-bottom: 5px;\n    gap: 16px;\n    display: flex;\n  }\n}\n\n.additional-tab {\n  .request-voucher {\n    margin: 30px 0 0 0;\n  }\n  .follow-up-checkbox {\n    margin-bottom: 10px;\n  }\n  .follow-up-chiplist-title {\n    margin-bottom: 5px;\n  }\n}\n\nmat-form-field {\n  width: 100%;\n}\n\nmat-dialog-actions {\n  display: flex;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
