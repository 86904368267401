// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.outlined-button {
  border: 0 !important;
}

.title {
  margin: 0 !important;
}

.dialog-text {
  padding: 10px 24px 0 24px;
}

.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.dialog-form {
  display: flex;
  flex-direction: column;
  padding: 10px 0 0 0;
}`, "",{"version":3,"sources":["webpack://./src/app/general-settings/dialog/calculate-cost-center/calculate-cost-center.component.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;AACF;;AAEA;EACE,oBAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,gDAAA;EACA,qCAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AACF","sourcesContent":[".outlined-button {\n  border: 0 !important;\n}\n\n.title {\n  margin: 0 !important;\n}\n\n.dialog-text {\n  padding: 10px 24px 0 24px;\n}\n\n.mat-button-disabled {\n  background-color: rgba(0, 0, 0, 0.12) !important;\n  color: rgba(0, 0, 0, 0.26) !important;\n}\n\n.dialog-form {\n  display: flex;\n  flex-direction: column;\n  padding: 10px 0 0 0;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
