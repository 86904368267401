import { Component, OnInit } from '@angular/core';
import {
  startOfDay,
  endOfDay,
  endOfMonth,
  isSameDay,
  isSameMonth,
  startOfMonth,
  endOfWeek,
  startOfWeek,
} from 'date-fns';
import { Subject } from 'rxjs';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import { EventColor } from 'calendar-utils';
import moment from 'moment';
import {
  Appointment,
  AppointmentType,
  DayPart,
  PlanningUser,
} from '../interfaces';
import { FormBuilder, FormControl } from '@angular/forms';
import {
  collection,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { MatDialog } from '@angular/material/dialog';
import { AppointmentDetailsComponent } from './dialogs/appointment-details/appointment-details.component';
import { ManageAppointmentComponent } from './dialogs/manage-appointment/manage-appointment.component';
import { capitalizeFirstLetter } from './helper';
import { ExportAppointmentsComponent } from './dialogs/export-appointments/export-appointments.component';
import { db } from '../app.component';

const colors: Record<string, EventColor> = {
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
    secondaryText: 'black',
  },
};

@Component({
  selector: 'app-planning',
  templateUrl: './planning.component.html',
  styleUrls: ['./planning.component.scss'],
})
export class PlanningComponent implements OnInit {
  CalendarView = CalendarView;
  moment = moment;
  capitalizeFirstLetter = capitalizeFirstLetter;
  loaded: boolean = false;
  activeDayIsOpen: boolean = false;
  view: CalendarView = CalendarView.Week;
  calendarStartHour: number = 8;
  calendarEndHour: number = 18;
  hourSegments: number = 60 / 5; // Second number should be how many minutes for a segment

  viewDate: Date = new Date();
  viewWeekNumber: number = 0;
  viewBeginDate: Date = new Date();
  viewEndDate: Date = new Date();

  refresh = new Subject<void>();
  townshipId = localStorage.getItem('township') as string;

  selectedAppointmentTypeIdsForm = this.fb.group({});

  appointmentTypes: AppointmentType[] = [];

  unfilteredAppointments: Appointment[] = [];
  planningUsers: PlanningUser[] = [];

  events: CalendarEvent[] = [];

  dayParts: DayPart[] = [];

  constructor(private fb: FormBuilder, public dialog: MatDialog) {}

  async ngOnInit() {
    moment.locale('nl');
    await this.getDayParts();
    this.dayParts.forEach((dayPart) => {
      if (dayPart.startHour < this.calendarStartHour) {
        this.calendarStartHour = dayPart.startHour;
      }
      if (dayPart.endHour > this.calendarEndHour) {
        this.calendarEndHour = dayPart.endHour;
      }
    });
    if (this.calendarStartHour > 1) {
      this.calendarStartHour--;
    }
    if (this.calendarEndHour < 23) {
      this.calendarEndHour++;
    }
    await this.getAppointmentTypes();
    this.selectedAppointmentTypeIdsForm.valueChanges.subscribe((val) => {
      this.filterEvents();
    });
    await this.getPlanningUsers();
    this.fetchEvents();
    this.loaded = true;
  }

  async getAppointmentTypes() {
    const appointmentTypesRef = collection(
      db,
      `township/${this.townshipId}/appointmentTypes`
    );
    const appointmentTypesDocs = await getDocs(
      query(appointmentTypesRef, orderBy('name'))
    );
    appointmentTypesDocs.forEach((appointmentDoc) => {
      const appointmentType = appointmentDoc.data() as AppointmentType;
      appointmentType.id = appointmentDoc.id;
      this.selectedAppointmentTypeIdsForm.addControl(
        appointmentType.id,
        new FormControl(true)
      );
      this.appointmentTypes.push(appointmentType);
    });
  }

  async getDayParts() {
    const dayPartsRef = collection(db, `township/${this.townshipId}/dayParts`);
    const dayPartsDocs = await getDocs(
      query(dayPartsRef, where('enabled', '==', true))
    );
    dayPartsDocs.forEach((dayPartDoc) => {
      const dayPart = dayPartDoc.data() as DayPart;
      dayPart.id = dayPartDoc.id;
      this.dayParts.push(dayPart);
    });
  }

  async getPlanningUsers() {
    const usersRef = collection(
      db,
      `township/${this.townshipId}/planningUsers`
    );
    onSnapshot(usersRef, (querySnapshot) => {
      const users: PlanningUser[] = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data() as PlanningUser;
        users.push({ id: doc.id, ...data });
      });
      users.sort((userA, userB) => {
        if (userA.firstName.toLowerCase() > userB.firstName.toLowerCase()) {
          return 1;
        }
        return -1;
      });
      this.planningUsers = users;
    });
  }

  async fetchEvents() {
    this.updateStartEndDate();
    const appointmentsRef = collection(
      db,
      `township/${this.townshipId}/appointments`
    );
    onSnapshot(
      query(
        appointmentsRef,
        where('start', '>', this.viewBeginDate),
        where('start', '<', this.viewEndDate),
        orderBy('start')
      ),
      (querySnapshot) => {
        const appointments: Appointment[] = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data() as Appointment;
          data.id = doc.id;
          data.start = data.start.toDate();
          data.end = data.end.toDate();
          appointments.push(data);
        });
        this.unfilteredAppointments = appointments;
        this.filterEvents();
      }
    );
  }

  updateStartEndDate() {
    const getStart: any = {
      month: startOfMonth,
      week: startOfWeek,
      day: startOfDay,
    }[this.view];

    const getEnd: any = {
      month: endOfMonth,
      week: endOfWeek,
      day: endOfDay,
    }[this.view];

    this.viewWeekNumber = moment(this.viewDate).week();
    this.viewBeginDate = getStart(this.viewDate, { weekStartsOn: 1 });
    this.viewEndDate = getEnd(this.viewDate, { weekStartsOn: 1 });
  }

  filterEvents() {
    const visibleAppointmentTypes = this.selectedAppointmentTypeIdsForm
      .value as any;
    const events: CalendarEvent[] = [];
    this.unfilteredAppointments.forEach((appointment) => {
      if (
        visibleAppointmentTypes[appointment.appointmentTypeId] &&
        appointment?.status != 'canceled'
      ) {
        let title = appointment.appointmentTypeName;
        appointment.planningUserNames?.forEach((name) => {
          title = `${title} ${name}`;
        });
        events.push({
          start: appointment.start,
          end: appointment.end,
          title: title,
          color: { ...colors.blue },
          meta: { appointment },
        });
      }
    });
    this.events = events;
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  setView(view: CalendarView) {
    this.view = view;
    this.fetchEvents();
  }

  dateChanged($event: { value: any }) {
    const newDateValue = $event.value;
    this.viewDate = newDateValue;
    this.fetchEvents();
  }

  appointmentDetails(event: CalendarEvent): void {
    this.dialog.open(AppointmentDetailsComponent, {
      data: {
        appointment: event.meta.appointment,
        planningUsers: this.planningUsers,
      },
      disableClose: true,
      autoFocus: false,
      panelClass: ['fullscreen-dialog'],
    });
  }

  newAppointment() {
    this.dialog.open(ManageAppointmentComponent, {
      data: {
        appointment: null,
        planningUsers: this.planningUsers,
      },
      disableClose: true,
      autoFocus: false,
      panelClass: ['fullscreen-dialog'],
    });
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  exportAppointments() {
    this.dialog.open(ExportAppointmentsComponent, {
      data: {
        appointmentTypes: this.appointmentTypes,
        planningUsers: this.planningUsers,
      },
      width: '400px',
    });
  }
}
