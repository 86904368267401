import { Component, OnInit, Inject, ElementRef } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  Voucher,
  Organisation,
  User,
  OrganisationTag,
  Tag,
  VoucherGroup,
  Address,
  Township,
} from 'src/app/interfaces';
import {
  deleteField,
  Timestamp,
  getDoc,
  doc,
  DocumentReference,
  DocumentData,
  setDoc,
} from 'firebase/firestore';
import {
  combineLatest,
  lastValueFrom,
  Observable,
  ReplaySubject,
  Subject,
} from 'rxjs';
import { debounceTime, distinctUntilChanged, map, take } from 'rxjs/operators';
import { ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { environment } from '../../../environments/environment';
import { ReceiptComponent } from '../dialogs/receipt/receipt.component';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CustomValidators } from '../../validators/custom-validators';
import { FillInBopFormDialogComponent } from './dialogs/fill-in-bop-form-dialog/fill-in-bop-form-dialog.component';
export interface DialogData {
  voucherNumber: string;
  type: 'claim' | 'activate' | 'edit';
  isHelpdesk?: boolean;
}

@Component({
  selector: 'app-manage-voucher',
  templateUrl: './manage-voucher.component.html',
  styleUrls: ['./manage-voucher.component.scss'],
})
export class ManageVoucherComponent implements OnInit {
  numberForm: UntypedFormGroup;
  voucherForm: UntypedFormGroup;
  claimForm: UntypedFormGroup;
  activateForm: UntypedFormGroup;
  voucherNumber: string;
  voucher: Voucher;
  voucherDoc: DocumentReference<DocumentData>;
  saving = false;

  organisationCollection: AngularFirestoreCollection<Organisation>;
  organisations: Observable<Organisation[]>;
  organisationsArray: Array<Organisation> = [];
  public filteredOrganisations: ReplaySubject<Organisation[]> =
    new ReplaySubject<Organisation[]>(1);
  totalOrgsFound: number;
  searchQuery: Subject<string> = new Subject<string>();
  organisationId: string;
  voucherFound: boolean;
  userDoc: AngularFirestoreDocument<User>;
  user: Observable<User>;
  userData: User;
  organisationsCollection: AngularFirestoreCollection<Organisation>;

  search: boolean;

  error: boolean;
  errorMessage: string;

  isEntrepreneur: boolean;
  entrepreneurId: string;
  entrepreneurData: Organisation;
  entrepreneurTagsCollection: AngularFirestoreCollection<OrganisationTag>;
  entrepreneurTags: Observable<OrganisationTag[]>;
  entrepreneurTagsObj = {};

  env = environment;

  voucherGroupArray: VoucherGroup[] = [];
  voucherGroupCollection: AngularFirestoreCollection<VoucherGroup>;

  succeedActions: number;

  barcode: string;
  receiptFile: any;
  receiptUrl: string;
  townshipId = localStorage.getItem('township') as string;
  townShipRef = '/township/' + localStorage.getItem('township') + '/';
  deleteReceipt: boolean;

  addressCheckDbl = false; //: boolean;
  addressAllowedToInsert: boolean;
  invalidAddress = false;
  isPayBack = false;
  addressAlreadyChecked: boolean;
  closed: boolean;

  receiptIsRequired: boolean;
  waitingResponse: boolean;
  customerNeedsToPay: number;
  editedBefore: boolean;
  lastEditedDate: string;
  lastEditedEmail: string;

  voucherContainsEmail: boolean = false;
  voucherEmailChanged: boolean = false;
  formContainsEmail: boolean = false;
  hasValidatedEmail: boolean = false;
  validationError: boolean = false;
  validatedEmail: string;
  emailChangedAfterValidation: boolean = false;
  emailTrustScore: number;
  emailScore: string;
  invalidEmail: boolean = true;
  invalidEmailMessage: string;
  suggestionMessage: string;
  termsMandatory: boolean = true;

  @ViewChild('couponNumber', { static: false }) ref: ElementRef;

  constructor(
    public db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: UntypedFormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ManageVoucherComponent>,
    public snackBar: MatSnackBar,
    public afAuth: AngularFireAuth,
    private storage: AngularFireStorage,
    private http: HttpClient
  ) {}

  async ngOnInit() {
    this.numberForm = this.fb.group({
      number: [, Validators.required],
    });
    this.activateForm = this.fb.group({
      activateDate: [, Validators.required], // Validators.required
      activateOrganisationId: ['none', Validators.required],
      couponNumber: [, Validators.required],
      search: [],
    });
    this.claimForm = this.fb.group({
      claimOrganisationId: [, Validators.required],
      claimDate: [, Validators.required],
      receiptValue: ['', CustomValidators.numberInput(true, false, 2)],
      couponNumber: [, Validators.required],
      pin: [{ value: '', disabled: true }, Validators.required],
      paybackComment: [{ value: '', disabled: true }, Validators.required],
      search: [],
      organisationReference: [],
      termsAgreed: [],
    });
    this.claimForm.valueChanges.subscribe((value) => {
      // console.log('claimForm value:', value);
      const adjustedReceiptValue = Number(
        `${value.receiptValue}`.replace(',', '.')
      );
      if (value.receiptValue && adjustedReceiptValue < 0) {
        if (this.isEntrepreneur && this.claimForm.controls.pin.disabled) {
          this.claimForm.controls.pin.enable();
        }
        if (this.claimForm.controls.paybackComment.disabled) {
          this.claimForm.controls.paybackComment.enable();
        }
      } else {
        if (this.isEntrepreneur && this.claimForm.controls.pin.enabled) {
          this.claimForm.controls.pin.disable();
        }
        if (this.claimForm.controls.paybackComment.enabled) {
          this.claimForm.controls.paybackComment.disable();
        }
      }
    });
    this.voucherForm = this.fb.group({
      voucherGroupId: [],
      value: [
        ,
        [CustomValidators.numberInput(false, false, 2), Validators.required],
      ],
      amountToPayOrg: [, CustomValidators.numberInput(true, false, 2)],
      email: [],
      postal: [, [Validators.minLength(6), Validators.maxLength(6)]],
      houseNumber: [],
      houseNumberAddition: [],
      ibanNumber: [, CustomValidators.ibanValidator],
      bankAccountName: [],
      activateDate: [],
      activateOrganisationId: [],
      claimDate: [],
      claimNumber: [],
      claimOrganisationId: [],
      organisationReference: [],
      paidDate: [],
      reminderSend: [],
      couponNumber: [],
      validUntilDate: [],
      search: [],
      type: [],
      name: [],
      phone: [],
      distributed: [],
      contactedOn: [],
      responsible: [],
      appointmentDate: [],
      appointmentTime: [],
      usedMaterials: [],
      notes: [],
      reminderDate: [],
      groupLink: [],
      paymentReference: [{ value: '', disabled: true }],
      paymentId: [{ value: '', disabled: true }],
      burdenOfProofFillInBeforeDate: [],
    });

    this.editedBefore = false;
    this.voucherFound = false;
    this.search = false;
    this.succeedActions = 0;

    console.log('this.data', this.data);
    if (this.data.voucherNumber) {
      this.findVoucher(this.data.voucherNumber);
    }

    if (this.data.isHelpdesk) {
      this.voucherForm.controls.organisationReference.disable();
    }

    this.afAuth.user.subscribe(async (user) => {
      if (user) {
        // console.log('user', user);
        this.userDoc = this.db.doc<User>('users/' + user.uid);
        this.user = this.userDoc.valueChanges();
        this.user.subscribe(async (res) => {
          console.log('res', res);
          this.userData = res;
          if (this.userData.rights !== 'admin') {
            this.voucherForm.controls.value.disable();
            this.voucherForm.controls.burdenOfProofFillInBeforeDate.disable();
          }
          if (this.env.env === 'org' || res.organisation) {
            this.isEntrepreneur = true;
            this.entrepreneurId = res.organisation;
            getDoc(
              doc(
                this.db.firestore,
                `township/${this.townshipId}/organisations/${this.entrepreneurId}`
              )
            ).then((ds) => {
              this.entrepreneurData = ds.data() as Organisation;
              console.log('entrepreneurData', this.entrepreneurData);
              this.receiptIsRequired = this.entrepreneurData.hasOwnProperty(
                'requiresReceipt'
              )
                ? this.entrepreneurData.requiresReceipt
                : true;
            });
            this.activateForm.controls.activateOrganisationId.disable();
            this.activateForm.controls.activateDate.disable();
            this.claimForm.controls.claimOrganisationId.disable();
            this.claimForm.controls.claimDate.disable();

            this.activateForm.patchValue({
              activateOrganisationId: res.organisation,
              activateDate: new Date(),
            });
            this.claimForm.patchValue({
              claimOrganisationId: res.organisation,
              claimDate: new Date(),
            });
            this.entrepreneurTagsCollection =
              this.db.collection<OrganisationTag>(
                '/township/' +
                  this.townshipId +
                  '/organisations/' +
                  this.entrepreneurId +
                  '/tags',
                (ref) => {
                  let query: any = ref;
                  if (this.data.type === 'claim') {
                    query = query.where('claimRights', '==', true);
                  }
                  if (this.data.type === 'activate') {
                    query = query.where('activateRights', '==', true);
                  }
                  return query;
                }
              );
            this.entrepreneurTags = this.entrepreneurTagsCollection
              .snapshotChanges()
              .pipe(
                map((actions) =>
                  actions.map((a) => {
                    const data = a.payload.doc.data() as OrganisationTag;
                    data.id = a.payload.doc['id'];
                    return { ...data };
                  })
                )
              );
            this.entrepreneurTags.subscribe((entrepreneurTags) => {
              console.log('entrepreneurTags', entrepreneurTags);
              const entrepreneurTagsObj = {};
              entrepreneurTags.forEach((tag) => {
                entrepreneurTagsObj[tag.id] = tag;
              });
              this.entrepreneurTagsObj = entrepreneurTagsObj;
            });
          }
          // console.log('res',  res);
        });

        this.addressCheckDbl = (
          (
            await getDoc(doc(this.db.firestore, `township/${this.townshipId}`))
          ).data() as Township
        ).checkAddressesDbl;
        console.log('thischeckardes', this.addressCheckDbl);
      }
    });

    this.organisationsCollection = this.db.collection<Organisation>(
      '/township/' + this.townshipId + '/organisations',
      (ref) => ref.orderBy('name', 'asc')
    );
    this.organisations = this.organisationsCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Organisation;
          data.id = a.payload.doc['id'];
          if (!data.claimedVouchers) {
            data.claimedVouchers = 0;
          }
          if (!data.paidVouchers) {
            data.paidVouchers = 0;
          }
          return { ...data };
        })
      ),
      take(1)
    );
    this.organisations.subscribe((organisations) => {
      console.log('organisations', organisations);
      this.organisationsArray = organisations;
    });

    this.voucherGroupCollection = this.db.collection<VoucherGroup>(
      '/township/' + this.townshipId + '/voucherGroups'
    );
    this.voucherGroupCollection.snapshotChanges().subscribe((result) => {
      // tslint:disable-next-line: no-shadowed-variable
      result.forEach((element: any) => {
        const data = element.payload.doc.data();
        data.id = element.payload.doc.id;
        this.voucherGroupArray.push(data);
      });
    });

    const combinedObservable = combineLatest([
      this.organisations,
      this.searchQuery,
    ]);
    combinedObservable
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((values) => {
        console.log('values', values);
        const orgs = values[0];
        const searchQuery = values[1];
        const filteredOrgs = orgs.filter((item) =>
          this.checkFilters(searchQuery, item)
        );

        console.log('filteredOrgs', filteredOrgs);
        this.totalOrgsFound = filteredOrgs.length;

        this.filteredOrganisations.next(filteredOrgs);
      });
    this.searchQuery.next('');

    this.activateForm.controls.search.valueChanges.subscribe((value) => {
      console.log('searchQueryChangedTo:', value);
      this.searchQuery.next(value);
    });
    this.claimForm.controls.search.valueChanges.subscribe((value) => {
      console.log('searchQueryChangedTo:', value);
      this.searchQuery.next(value);
    });
    this.voucherForm.controls.search.valueChanges.subscribe((value) => {
      console.log('searchQueryChangedTo:', value);
      this.searchQuery.next(value);
    });
    this.claimForm.controls.claimOrganisationId.valueChanges.subscribe(
      (value) => {
        if (!value) {
          return;
        }
        const claimOrganisation = this.organisationsArray.find((org) => {
          return org.id === value;
        });
        console.log('claimbedrijf:', claimOrganisation);
        if (claimOrganisation.organisationReferenceMandatory) {
          this.claimForm.controls.organisationReference.addValidators(
            Validators.required
          );
          this.claimForm.controls.organisationReference.updateValueAndValidity();
        } else {
          this.claimForm.controls.organisationReference.removeValidators(
            Validators.required
          );
          this.claimForm.controls.organisationReference.updateValueAndValidity();
        }
        if (
          claimOrganisation.termsMandatory ||
          claimOrganisation.termsMandatory == undefined
        ) {
          this.termsMandatory = true;
        } else {
          this.termsMandatory = false;
        }
      }
    );

    this.claimForm.controls.couponNumber.valueChanges
      .pipe(debounceTime(20000))
      .subscribe((result) => {
        this.barcode = null;
      });

    this.claimForm.controls.couponNumber.valueChanges
      .pipe(debounceTime(100))
      .subscribe((result) => {
        this.addressAlreadyChecked = false;
        this.invalidAddress = false;
      });
  }

  checkFilters(searchQuery, item) {
    let passesSearchFilter = true;
    if (searchQuery) {
      searchQuery = searchQuery.toLowerCase();
      passesSearchFilter = false;
      if (item.name) {
        if (item.name.toLowerCase().includes(searchQuery)) {
          passesSearchFilter = true;
        }
      }
      if (item.description) {
        if (item.description.toLowerCase().includes(searchQuery)) {
          passesSearchFilter = true;
        }
      }
    }

    if (passesSearchFilter) {
      return item;
    }
  }

  async findVoucher(voucherNumber: string) {
    if (this.saving) {
      return;
    }
    this.voucherNumber = voucherNumber;
    this.saving = true;
    const voucher = await this.getVoucher(voucherNumber);
    if (voucher) {
      console.log('voucher', voucher);
      this.voucher = voucher;
      this.lastEditedDate = this.voucher.lastEditedDate
        ? this.voucher.lastEditedDate
        : null;
      this.lastEditedEmail = this.voucher.lastEditedEmail
        ? this.voucher.lastEditedEmail
        : null;
      this.voucherFound = true;
      if (this.data.type === 'edit' && voucher.lastEditedDate) {
        this.editedBefore = true;
      }
      if (voucher.imageUrl) {
        this.receiptUrl = voucher.imageUrl;
      }
      this.voucherForm.patchValue(voucher);
      this.voucherForm.patchValue({ value: Number(voucher.value).toFixed(2) });
      if (voucher.distributed || voucher.activateDate) {
        this.voucherForm.controls.distributed.setValue(true);
      }
      const voucherGroup = (
        await getDoc(
          doc(
            this.db.firestore,
            `${this.townShipRef}voucherGroups/${voucher.voucherGroupId}`
          )
        )
      ).data() as VoucherGroup;
      console.log(
        'voucherGroupRef',
        `${this.townShipRef}voucherGroups/${voucher.voucherGroupId}`
      );
      console.log('voucherGroup', voucherGroup);
      if (voucherGroup.name) {
        this.voucherForm.controls.voucherGroupId.setValue(voucherGroup.name);
      } else {
        this.voucherForm.controls.voucherGroupId.setValue(
          voucher.voucherGroupId
        );
      }
      this.voucherForm.controls.voucherGroupId.disable();
    } else {
      this.error = true;
      this.errorMessage = 'Deze bon bestaat niet';
    }
    this.saving = false;
  }

  async getVoucher(voucherNumber: string) {
    this.voucherDoc = doc(
      this.db.firestore,
      `township/${this.townshipId}/vouchers/${voucherNumber}`
    );
    const voucher = (await getDoc(this.voucherDoc)).data() as any;
    if (voucher) {
      this.voucherNumber = voucher.number;
      if (voucher.activateDate) {
        voucher.activateDate = voucher.activateDate.toDate();
      }
      if (voucher.claimDate) {
        voucher.claimDate = voucher.claimDate.toDate();
      }
      if (voucher.paidDate) {
        voucher.paidDate = voucher.paidDate.toDate();
      }
      if (voucher.validUntilDate) {
        voucher.validUntilDate = voucher.validUntilDate.toDate();
      }
      if (voucher.burdenOfProofFillInBeforeDate) {
        voucher.burdenOfProofFillInBeforeDate =
          voucher.burdenOfProofFillInBeforeDate.toDate();
      }
      if (voucher.contactedOn) {
        const splitDateTime = voucher.contactedOn.split(' ', 2); //separate date and time
        const splitDate = splitDateTime[0].split('-'); //separate each digit in the date
        const day = splitDate[0];
        const month = splitDate[1] - 1; //JS counts months from 0 to 11
        const year = splitDate[2];

        const newDate = new Date(year, month, day, 0); //place the pieces in the correct order
        voucher.contactedOn = newDate;
      }
      if (voucher.appointmentDate) {
        const splitDateTime = voucher.appointmentDate.split(' ', 2);
        const splitDate = splitDateTime[0].split('-');
        const day = splitDate[0];
        const month = splitDate[1] - 1;
        const year = splitDate[2];

        const newDate = new Date(year, month, day, 0);
        voucher.appointmentDate = newDate;
      }
      if (voucher.reminderDate) {
        const timeString = voucher.reminderDate.split(/[-_]/);
        console.log('timeString', timeString);
        voucher.reminderDate = new Date(voucher.reminderDate);
      }
      if (voucher.email) {
        this.voucherContainsEmail = true;
        this.formContainsEmail = true;
      }
    }
    this.voucherForm.valueChanges.subscribe((email) => {
      if (this.voucherForm.value.email) {
        this.formContainsEmail = true;
      } else {
        this.formContainsEmail = false;
      }
      if (this.voucherContainsEmail) {
        if (this.voucherForm.value.email !== voucher.email) {
          this.voucherEmailChanged = true;
        } else {
          this.voucherEmailChanged = false;
        }
      }
      if (this.validatedEmail) {
        if (this.validatedEmail !== this.voucherForm.value.email) {
          this.emailChangedAfterValidation = true;
        } else {
          this.emailChangedAfterValidation = false;
        }
      }
    });
    console.log('voucher', voucher);
    return voucher as Voucher;
  }

  async checkTags(voucher: Voucher) {
    let hasRights = false;
    const organisation = (
      await getDoc(
        doc(
          this.db.firestore,
          `/township/${this.townshipId}/organisations/${this.entrepreneurId}`
        )
      )
    ).data();
    console.log('organisation', organisation);
    const voucherGroupTagsCollection = this.db.collection<Tag>(
      '/township/' +
        this.townshipId +
        '/voucherGroups/' +
        voucher.voucherGroupId +
        '/tags'
    );
    const voucherGroupTags = voucherGroupTagsCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Tag;
          data.id = a.payload.doc['id'];
          return { ...data };
        })
      ),
      take(1)
    );
    await voucherGroupTags.forEach((tags) => {
      console.log('voucherGroupTags', tags);
      if (tags.length === 0) {
        if (
          (organisation.canActivateVouchers && this.data.type === 'activate') ||
          this.data.type != 'activate'
        ) {
          hasRights = true;
        }
      }
      tags.forEach((tag) => {
        if (this.entrepreneurTagsObj[tag.id]) {
          if (
            this.data.type === 'claim' &&
            this.entrepreneurTagsObj[tag.id].claimRights
          ) {
            console.log('matching tag', this.entrepreneurTagsObj[tag.id]);
            hasRights = true;
          }
          if (
            this.data.type === 'activate' &&
            organisation.canActivateVouchers &&
            this.entrepreneurTagsObj[tag.id].activateRights
          ) {
            console.log('matching tag', this.entrepreneurTagsObj[tag.id]);
            hasRights = true;
          }
        }
      });
    });
    return hasRights;
  }

  async checkValidity(voucher, voucherGroup: VoucherGroup) {
    let isValid = true;
    const now = new Date();
    console.log('now', now);
    if (voucherGroup.validUntilDate) {
      if (voucherGroup.validUntilDate.toDate() < now) {
        console.log(
          'voucherGroup.validUntilDate',
          voucherGroup.validUntilDate.toDate()
        );
        isValid = false;
      }
    }
    if (voucherGroup.validUntilTime && voucher.activateDate) {
      const activateDate = voucher.activateDate;
      const activateDateWithoutHours = new Date(
        activateDate.getFullYear(),
        activateDate.getMonth(),
        activateDate.getDate() + 1
      );
      let validDate;
      console.log('activateDate', activateDate);
      console.log('activateDateWithoutHours', activateDateWithoutHours);
      const validUntilTimeValue = voucherGroup.validUntilTimeValue as number;
      switch (voucherGroup.validUntilTimeType) {
        case 'days':
          validDate = new Date(
            activateDate.getFullYear(),
            activateDate.getMonth(),
            activateDate.getDate() + validUntilTimeValue
          );
          break;
        case 'weeks':
          validDate = new Date(
            activateDate.getFullYear(),
            activateDate.getMonth(),
            activateDate.getDate() + validUntilTimeValue * 7
          );
          break;
        case 'months':
          validDate = new Date(
            activateDate.getFullYear(),
            activateDate.getMonth() + validUntilTimeValue,
            activateDate.getDate()
          );
          break;
        case 'years':
          validDate = new Date(
            activateDate.getFullYear() + validUntilTimeValue,
            activateDate.getMonth(),
            activateDate.getDate()
          );
          break;
      }
      if (validDate < now) {
        console.log('voucherGroup.validDateTime', validDate);
        isValid = false;
      }
    }
    // Overwrites
    if (voucher.validUntilDate) {
      try {
        voucher.validUntilDate = voucher.validUntilDate.toDate();
      } catch {}
      if (voucher.validUntilDate < now) {
        console.log('voucher.validUntilDate', voucher.validUntilDate.toDate());
        isValid = false;
      } else {
        isValid = true;
      }
    }
    return isValid;
  }

  async save(form: UntypedFormGroup) {
    console.log('this.saving', this.saving);
    if (form.invalid || this.saving) {
      return;
    }
    if (
      this.data.type === 'claim' &&
      this.receiptIsRequired &&
      !this.receiptFile
    ) {
      this.showError('Je moet nog een kassabon toevoegen.');
      return;
    }
    this.saving = true;
    this.errorMessage = '';
    this.customerNeedsToPay = null;
    const saveObj = { ...form.value };
    if (form.value.value) {
      const formValue = form.value.value.toString();
      const adjustedValue = Number(formValue.replace(',', '.'));
      saveObj.value = adjustedValue;
    }
    if (form.value.amountToPayOrg) {
      const formAmountToPay = form.value.amountToPayOrg.toString();
      const adjustedAmountToPayOrg = Number(formAmountToPay.replace(',', '.'));
      saveObj.amountToPayOrg = adjustedAmountToPayOrg;
    }
    if (form.value.receiptValue) {
      const formReceiptValue = form.value.receiptValue.toString();
      const adjustedReceiptValue = Number(formReceiptValue.replace(',', '.'));
      saveObj.receiptValue = adjustedReceiptValue;
    }

    // If negative transaction is being made by a organisation, check pin
    if (
      this.data.type === 'claim' &&
      this.isEntrepreneur &&
      saveObj.receiptValue < 0
    ) {
      const pin = form.value.pin;
      if (pin != (this.entrepreneurData.pinCode ?? '00000')) {
        this.showError('De pincode is incorrect.');
        return (this.saving = false);
      }
      delete saveObj.pin;
    }

    delete saveObj.search;
    delete saveObj.couponNumber;
    console.log('form.value', { ...saveObj });

    let voucher: Voucher;
    let voucherGroup: VoucherGroup;
    let checked = false;
    let snackbarValue;
    let hasRights = true;
    let isValid = true;

    if (saveObj.reminderDate) {
      const reminderDate = new Date(saveObj.reminderDate);
      const year = reminderDate.getFullYear();
      let month = (reminderDate.getMonth() + 1).toString();
      if (month.length === 1) {
        month = `0${month}`;
      }
      let day = reminderDate.getDate().toString();
      if (day.length === 1) {
        day = `0${day}`;
      }
      const timeString = year + '-' + month + '-' + day;
      saveObj.reminderDate = timeString;
      console.log('timeString', timeString);
    }

    if (saveObj.appointmentDate && saveObj.appointmentTime) {
      try {
        const timeArray = saveObj.appointmentTime.split(':');
        const hour = Number(timeArray[0]);
        const minute = Number(timeArray[1]);
        const appointmentDate = new Date(
          saveObj.appointmentDate.setHours(hour, minute)
        );
        console.log('appointmentDate', appointmentDate);
        saveObj.appointmentDate = appointmentDate;
      } catch (e) {
        console.error(e);
      }
    }

    if (saveObj.postal) {
      saveObj.postal = saveObj.postal.toUpperCase().replace(/\s/g, '');
    }

    if (saveObj.houseNumber) {
      saveObj.houseNumber = saveObj.houseNumber.toString();
    }

    if (saveObj.houseNumberAddition) {
      saveObj.houseNumberAddition = saveObj.houseNumberAddition.toLowerCase();
    }

    if (saveObj.ibanNumber) {
      saveObj.ibanNumber = saveObj.ibanNumber.toUpperCase().replace(/\s/g, '');
    }

    if (this.data.type != 'edit') {
      // check if voucher is valid before claiming
      voucher = await this.getVoucher(form.value.couponNumber);
      console.log('voucher', { ...voucher });
      if (!voucher) {
        this.showError('Deze bon bestaat niet.');
      }
      if (voucher) {
        if (voucher.voucherGroupId) {
          voucherGroup = (
            await getDoc(
              doc(
                this.db.firestore,
                `${this.townShipRef}voucherGroups/${voucher.voucherGroupId}`
              )
            )
          ).data() as VoucherGroup;
        }
        if (this.isEntrepreneur) {
          hasRights = await this.checkTags(voucher);
          isValid = await this.checkValidity(voucher, voucherGroup);
          if (this.data.type === 'activate') {
            saveObj.activateOrganisationId = this.entrepreneurId;
            saveObj.activateDate = new Date();
            saveObj.distributed = true;
          }
          if (this.data.type === 'claim') {
            saveObj.claimOrganisationId = this.entrepreneurId;
            saveObj.claimDate = new Date();
          }
        }
        if (this.data.type === 'activate') {
          console.log('saveObj.activeDate', saveObj.activateDate);

          if (voucher.activateDate) {
            this.showError('Deze bon is al geactiveerd.');
          } else {
            console.log('checked activate');
            checked = true;
          }
          if (saveObj.activateOrganisationId !== 'none') {
            // tslint:disable-next-line: no-shadowed-variable
            this.organisationsArray.forEach((element: any) => {
              if (element.id === saveObj.activateOrganisationId) {
                saveObj.activateOrganisation = element.name;
              }
            });
          } else {
            saveObj.activateOrganisation = deleteField();
            saveObj.activateOrganisationId = deleteField();
          }
        } else if (this.data.type === 'claim') {
          if (
            voucherGroup.activateFromType === 'activateAfterScan' &&
            !voucher.activateDate
          ) {
            this.showError('Deze bon moet eerst geactiveerd worden.');
            this.barcode = null;
          } else if (!voucher.activateDate || voucher.activateFrom) {
            this.showError('Deze bon moet eerst geactiveerd worden.');
            this.barcode = null;
          } else if (voucher.claimDate) {
            this.showError('Deze bon is al geclaimd.');
            this.barcode = null;
          } else {
            console.log('checked claim');
            checked = true;
            if (voucher.activateDate) {
              // console.log('lets claim', voucherGroup.usableFrom);
              if (voucherGroup.claimInstantly) {
                // Claim voucher fully when claimInstantly is enabled.
                saveObj.receiptValue = voucher.value;
                saveObj.amountToPayOrg = voucher.value;
              } else if (
                saveObj.receiptValue &&
                saveObj.receiptValue.length !== 0
              ) {
                saveObj.amountToPayOrg =
                  saveObj.receiptValue > voucher.value
                    ? voucher.value
                    : saveObj.receiptValue;
              } else {
                // Voucher has claimInstantly turned off so it requires a receiptValue
                this.showError(
                  'Voor deze bon moet je een aankoopwaarde invullen.'
                );
                checked = false;
              }
              console.log(`amountToPayOrg: ${saveObj.amountToPayOrg}`);
              if (voucherGroup.activateFromType === 'activateOnDate') {
                const voucherGroupDate = voucherGroup.usableFrom.toDate();
                const now = new Date();
                if (voucherGroupDate > now) {
                  this.showError('De activeer datum is nog niet gepasseerd');
                  checked = false;
                }
                saveObj.activateFrom = true;
              }
              if (voucherGroup.activateFromType === 'activateAfterScan') {
                saveObj.activateFrom = true;
              }
              if (saveObj.amountToPayOrg < 0) {
                const nonNegativeReceiptValue = 0 - saveObj.amountToPayOrg; // -- equals +
                const valueAfterRefund =
                  voucher.value + nonNegativeReceiptValue;
                if (valueAfterRefund > voucher.originalValue) {
                  // Adding more than the maximum amount
                  this.showError(
                    'Bedrag komt boven de originele waarde van de bon.'
                  );
                  checked = false;
                }
              }
            }
          }
          if (saveObj.claimOrganisationId !== 'none') {
            // tslint:disable-next-line: no-shadowed-variable
            this.organisationsArray.forEach((element: any) => {
              if (element.id === saveObj.claimOrganisationId) {
                saveObj.claimOrganisation = element.name;
              }
            });
          } else {
            saveObj.claimOrganisation = deleteField();
            saveObj.claimOrganisationId = deleteField();
          }
        }
      }
    } else {
      checked = true;
      if (saveObj.type) {
        console.log('saveObj.type', typeof saveObj.type);
        if (typeof saveObj.type !== 'object') {
          saveObj.type = saveObj.type.split(',');
        }
      }
      if (!saveObj.distributed) {
        saveObj.activateFromType = deleteField();
      }

      if (saveObj.contactedOn) {
        const year = saveObj.contactedOn.getFullYear();
        const month = saveObj.contactedOn.getMonth() + 1;
        const day = saveObj.contactedOn.getDate();
        saveObj.contactedOn = `${day}-${month}-${year}`;
      }

      if (saveObj.appointmentDate) {
        const year = saveObj.appointmentDate.getFullYear();
        const month = saveObj.appointmentDate.getMonth() + 1;
        const day = saveObj.appointmentDate.getDate();
        saveObj.appointmentDate = `${day}-${month}-${year}`;
      }

      if (saveObj.validUntilDate) {
        const validUntilDateInSeconds = saveObj.validUntilDate.setHours(23, 59);
        saveObj.validUntilDate = Timestamp.fromMillis(validUntilDateInSeconds);
        console.log('saveObj.validUntilDate', saveObj.validUntilDate);
      }

      const lastEditDate = new Date();
      const year = lastEditDate.getFullYear().toString();
      let month = (lastEditDate.getMonth() + 1).toString();
      if (month.length === 1) {
        month = `0${month}`;
      }
      let day = lastEditDate.getDate().toString();
      if (day.length === 1) {
        day = `0${day}`;
      }

      let hours = lastEditDate.getHours().toString();
      if (hours.length === 1) {
        hours = `0${hours}`;
      }
      let minutes = lastEditDate.getMinutes().toString();
      if (minutes.length === 1) {
        minutes = `0${minutes}`;
      }
      saveObj.lastEditedDate = `${day}-${month}-${year} | ${hours}:${minutes}`;

      saveObj.lastEditedEmail = this.userData.email;
    }
    console.log('checked', checked);
    if (checked) {
      // Only move on to these errors if voucher is checked
      if (!isValid) {
        this.showError('Deze bon is niet meer geldig.');
        this.barcode = null;
      } else if (!hasRights) {
        if (this.data.type === 'claim') {
          this.showError('Je hebt geen rechten om deze bon te claimen.');
          this.barcode = null;
        }
        if (this.data.type === 'activate') {
          this.showError('Je hebt geen rechten om deze bon te activeren.');
          this.barcode = null;
        }
      }
    }
    console.log('hasRights', hasRights);
    console.log('isValid', isValid);
    if (checked && hasRights && isValid) {
      Object.keys(saveObj).forEach((key) => {
        if (saveObj[key] == null) {
          saveObj[key] = null;
        } else if (
          typeof saveObj[key] === 'string' &&
          saveObj[key].length === 0
        ) {
          saveObj[key] = null;
        }
      });

      if (saveObj.claimOrganisationId !== 'none') {
        // tslint:disable-next-line: no-shadowed-variable
        this.organisationsArray.forEach((element: any) => {
          if (element.id === saveObj.claimOrganisationId) {
            saveObj.claimOrganisationId = element.id;
            saveObj.claimOrganisation = element.name;
          }
        });
      } else {
        saveObj.claimOrganisationId = deleteField();
        saveObj.claimOrganisation = deleteField();
      }
      if (saveObj.activateOrganisationId !== 'none') {
        // tslint:disable-next-line: no-shadowed-variable
        this.organisationsArray.forEach((element: any) => {
          if (element.id === saveObj.activateOrganisationId) {
            saveObj.activateOrganisation = element.name;
          }
        });
      } else {
        saveObj.activateOrganisationId = deleteField();
        saveObj.activateOrganisation = deleteField();
      }

      if (this.receiptFile) {
        const fileName = form.value.couponNumber
          ? form.value.couponNumber
          : this.voucherNumber;
        const file = this.receiptFile;
        const filePath = `${this.townshipId}/receipts/${fileName}`;
        const uploadTask = await this.storage.upload(filePath, file);
        saveObj.imageUrl = await uploadTask.ref.getDownloadURL();
        this.receiptFile = null;
        // console.log('receiptImageUrl', receiptImageUrl);
      }
      if (this.deleteReceipt) {
        saveObj.imageUrl = null;
        this.receiptFile = null;
        // console.log('this.voucherNumber', this.voucherNumber);
        await this.deleteFile(this.voucherNumber);
      }

      let addressCheckRes;
      if (
        this.data.type != 'edit' &&
        this.addressCheckDbl &&
        typeof this.addressAllowedToInsert === 'undefined'
      ) {
        if (voucher && (!voucher.postal || !voucher.houseNumber)) {
          this.saving = false;
          return this.showError(
            'Een postcode & huisnummer combinatie is verplicht voor DBL.'
          );
        }
        // addressAllowedToInsert false comes van dbl api
        addressCheckRes = await this.onBlur();
        if (!this.addressAllowedToInsert) {
          console.log(
            'this.addressAllowedToInsert',
            this.addressAllowedToInsert
          );
          return;
        }
      } else if (
        this.addressCheckDbl &&
        typeof this.addressAllowedToInsert === 'undefined'
      ) {
        this.showError(
          'Er is iets misgegaan met toevoegen van het adres bij Duurzaam Bouwloket'
        );
        this.invalidAddress = true;
      }
      console.log('this.addressAllowedToInsert', this.addressAllowedToInsert);
      if (
        this.invalidAddress &&
        this.addressCheckDbl &&
        this.data.type === 'claim'
      ) {
        return this.showError('Deze bon heeft geen geldig adres');
      }
      this.addressAlreadyChecked = false;
      console.log('saveObj', saveObj);
      if (saveObj.receiptValue > saveObj.amountToPayOrg) {
        this.customerNeedsToPay = saveObj.receiptValue - saveObj.amountToPayOrg;
      }
      if (saveObj.email && this.voucher.email) {
        if (saveObj.email !== this.voucher.email) {
          saveObj.sendgridData = {};
        }
      }
      await setDoc(this.voucherDoc, saveObj, { merge: true });
      if (this.data.type === 'edit') {
        this.snackBar.open(`Bon ${this.voucherNumber} bewerkt`, '', {
          duration: 4000,
          panelClass: ['snackbar'],
        });
        this.dialogRef.close({ voucher: saveObj });
      } else {
        console.log('voucher', saveObj);
        if (this.data.type === 'claim') {
          snackbarValue = saveObj.receiptValue;
        } else {
          snackbarValue = voucher.value;
        }
        this.succeedActions++;
        form.controls.couponNumber.setValue('');
        form.controls.organisationReference?.setValue('');
        this.ref.nativeElement.focus(); // focus to bon nummer input
        if (this.data.type === 'claim') {
          this.snackBar.open(
            `Bon ${form.value.couponNumber} geclaimed voor ${snackbarValue} euro`,
            '',
            {
              duration: 4000,
              panelClass: ['snackbar'],
            }
          );
          let barcodeFound = false;
          this.organisations.forEach((org) => {
            console.log('org', org);
            org.forEach((element: any) => {
              if (element.id === saveObj.claimOrganisationId) {
                if (element.discountCode) {
                  [...element.discountCode].forEach((discount) => {
                    if (discount.voucherId === voucher.voucherGroupId) {
                      this.barcode = discount.barcode;
                      barcodeFound = true;
                    }
                  });
                }
              }
            });
          });
          if (!barcodeFound) {
            this.barcode = null;
          }
          if (voucherGroup.sendBurdenOfProof && !voucherGroup.cashback) {
            const township = (
              await getDoc(doc(this.db.firestore, this.townShipRef))
            ).data() as Township;
            this.dialog.open(FillInBopFormDialogComponent, {
              width: 'auto',
              height: 'auto',
              data: {
                voucher: voucher,
                voucherGroup: voucherGroup,
                township: township,
              },
            });
          }
        } else if (this.data.type === 'activate') {
          this.snackBar.open(
            `Bon ${form.value.couponNumber} geactiveerd voor ${snackbarValue} euro`,
            '',
            {
              duration: 4000,
              panelClass: ['snackbar'],
            }
          );
        }
      }
    }
    // console.log('saveObj.claimOrganisationId', saveObj.claimOrganisationId);
    // Make convert empty strings to 'null' for our queries.
    this.saving = false;
  }

  showError(message: string) {
    this.error = true;
    this.errorMessage = message;
    if (this.ref && this.ref.nativeElement) {
      this.ref.nativeElement.focus();
      this.ref.nativeElement.select();
    }
  }

  isFocus() {
    // console.log('is focus');
    if (this.claimForm.valid) {
      this.save(this.claimForm);
    } else if (this.activateForm.valid) {
      this.save(this.activateForm);
    } else if (this.numberForm.valid) {
      this.findVoucher(this.numberForm.value.number);
    }
  }

  addEditReceipt() {
    const dialogRef = this.dialog.open(ReceiptComponent, {
      width: 'auto',
      height: 'auto',
      // data:
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      console.log('The dialog was closed', result);
      this.addressAlreadyChecked = true;
      if (result) {
        this.receiptFile = result;
      }
    });
  }

  openFile() {
    window.open(this.receiptUrl);
  }

  setDelete() {
    this.deleteReceipt = true;
    this.snackBar.open(
      'Druk op opslaan om de kassabon definitief te verwijderen.',
      'X',
      {
        duration: 4000,
      }
    );
  }
  async deleteFile(couponNumber: string) {
    const fileUrl = `${this.townshipId}/receipts/${couponNumber}`;
    const file = await this.storage.ref(fileUrl).delete();
    file.subscribe(() => {
      console.log('file deleted');
    });
  }

  async getAddressTypes(
    postalCode: string,
    houseNumber: string,
    houseNumberAddition?: string
  ) {
    console.log('postalCode', `${postalCode}${houseNumber}`);
    let newAddres = postalCode + houseNumber;
    if (houseNumberAddition !== null) {
      newAddres += houseNumberAddition;
    }
    console.log(newAddres);
    const address = (
      await getDoc(
        doc(
          this.db.firestore,
          `township/${this.townshipId}/addresses/${newAddres}`
        )
      )
    ).data() as Address;
    console.log('addresRef', address);
    if (address && address.type && address.type.toString()) {
      this.voucherForm.controls.type.setValue(address.type.toString());
    } else {
      this.snackBar.open('Geen kenmerken gevonden', 'X', {
        duration: 4000,
      });
    }
  }

  async onBlur() {
    if (!this.addressCheckDbl) {
      return;
    }
    this.waitingResponse = true;
    setTimeout(() => {
      if (this.addressAlreadyChecked || this.saving || this.closed) {
        console.log('cancel');
        // this.waiting = false;
        this.waitingResponse = false;
        return;
      }
    }, 100);
    const number = this.claimForm.value.couponNumber
      ? this.claimForm.value.couponNumber
      : this.activateForm.value.couponNumber;
    const promise = await new Promise(async (resolve, reject) => {
      if (this.addressCheckDbl && number) {
        // call function to check if coupon is registrered on a valid address
        const requestUrl = `${this.env.functionsUrl}/dblCheckAddressRrew`;
        console.log('requestUrl', requestUrl);
        const voucherData = (
          await getDoc(
            doc(
              this.db.firestore,
              `township/${this.townshipId}/vouchers/${number}`
            )
          )
        ).data() as Voucher;

        if (!voucherData) {
          this.showError('Deze voucher bestaat niet');
          this.waitingResponse = false;
          return reject('failed');
        }
        const postData = {
          townshipId: this.townshipId,
          postal: voucherData.postal,
          houseNumber: voucherData.houseNumber,
          houseNumberAddition: voucherData.houseNumberAddition,
          type: 'check',
          voucherGroupId: voucherData.voucherGroupId,
        };
        console.log('postData', postData);
        const result = (await lastValueFrom(
          this.http.post(requestUrl, postData)
        )) as any;

        console.log('result', result);
        if (this.addressAlreadyChecked) {
          console.log('cancel');
          this.waitingResponse = false;
          return reject('failed');
        }
        this.addressAlreadyChecked = true;
        if (result.message === 'done') {
          this.invalidAddress = false;
          this.addressAllowedToInsert = result.data.AllowedToInsert;
          this.waitingResponse = false;
          return resolve('succeed');
        } else {
          if (result.message === 'failed') {
            this.invalidAddress = true;
            if (result.data.error === 'Gemeente onbekend') {
              console.log('Gemeente niet bekend');
              this.showError('Deze gemeente is niet bekend');
            } else if (result.data.error === 'Aanvraag bekend') {
              console.log('Aanvraag bekend');
              this.showError('Er is al een aanvraag gedaan');
            } else if (result.data.error === 'missing_parameters') {
              this.showError('Deze bon heeft geen postcode & huisnummer');
            } else {
              this.showError(
                'Er is iets missgegaan met de postcode controleren'
              );
            }
          } else {
            this.showError(
              'Er is iets misgegaan met controleren van het adres'
            );
          }
          console.log('invalidAddress', this.invalidAddress);
          this.waitingResponse = false;
          return reject('failed');
        }
      }
    }).catch((e: any) => {
      console.error(e);
    });
  }

  close() {
    this.closed = true;
    this.dialogRef.close(false);
  }

  async validateEmail() {
    if (!this.voucherForm.value.email) {
      return this.snackBar.open('Geen e-mailadres ingevuld', 'X', {
        duration: 5000,
      });
    }
    try {
      const sendgridValidatorData = (
        await getDoc(doc(this.db.firestore, `emailValidation/sendgrid`))
      ).data() as { api_key: string };
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sendgridValidatorData.api_key,
        }),
      };
      const result = await lastValueFrom(
        this.http.post(
          'https://api.sendgrid.com/v3/validations/email',
          {
            email: this.voucherForm.value.email,
            source: 'mail validation',
          },
          httpOptions
        )
      );
      switch (result['result']['verdict']) {
        case 'Invalid':
          this.emailTrustScore = result['result']['score'];
          this.invalidEmail = true;
          this.invalidEmailMessage =
            'Dit e-mailadres is hoogstwaarschijnlijk ongeldig';
          break;
        case 'Risky':
          this.emailTrustScore = result['result']['score'];
          this.invalidEmail = false;
          this.snackBar.open('Dit e-mailadres lijkt geldig te zijn', 'X', {
            duration: 4000,
          });
          break;
        default:
          this.emailTrustScore = result['result']['score'];
          this.invalidEmail = false;
          this.snackBar.open(
            'Dit e-mailadres is hoogstwaarschijnlijk geldig',
            'X',
            {
              duration: 4000,
            }
          );
          break;
      }
      if (result['result']['suggestion']) {
        this.suggestionMessage = `@${result['result']['suggestion']}`;
      }
      this.validationError = false;
      this.validatedEmail = this.voucherForm.value.email;
      this.emailScore = '';
      if (this.emailTrustScore <= 0.25) {
        this.emailScore = 'low';
      } else if (this.emailTrustScore > 0.25 && this.emailTrustScore < 0.75) {
        this.emailScore = 'medium';
      } else {
        this.emailScore = 'high';
      }
      this.emailTrustScore = this.emailTrustScore * 100;
      this.emailTrustScore =
        Math.round((this.emailTrustScore + Number.EPSILON) * 100) / 100;
      this.hasValidatedEmail = true;
      if (this.emailChangedAfterValidation) {
        this.emailChangedAfterValidation = false;
      }
      delete result['result']['checks'];
      await this.db
        .collection(`emailValidation/sendgrid/validationHistory`)
        .add({
          email: this.voucherForm.value.email,
          result: result['result'],
          date: new Date(),
        });
    } catch (e) {
      console.error('E-mailadres valideren mislukt:', e);
      if (!this.formContainsEmail) {
        this.validationError = true;
        this.snackBar.open('Vul a.u.b. een e-mailadres in', 'X', {
          duration: 4000,
        });
      } else {
        this.snackBar.open(
          'Er is iets misgegaan met het valideren van het e-mail',
          'X',
          {
            duration: 5000,
          }
        );
      }
    }
  }
}
