// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.export-form .date-filter {
  display: flex;
  gap: 15px;
  min-height: 150px;
}
.export-form .date-filter mat-form-field {
  max-height: 75px;
}
.export-form .date-filter > div {
  min-width: 0;
}
.export-form .date-filter > div:first-child {
  flex: 1;
}
.export-form .date-filter > div:last-child {
  flex: 2;
}
.export-form .date-filter mat-radio-button {
  width: 100%;
}
@media only screen and (max-width: 579px) {
  .export-form .date-filter {
    flex-direction: column;
  }
  .export-form .date-filter .date-filter-radio-group {
    display: flex;
    flex-direction: column;
  }
}
.export-form .type-of-user {
  margin: 10px 0 0 0;
}
.export-form mat-form-field {
  width: 100%;
}

.mat-mdc-dialog-actions {
  justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/app/planning/dialogs/export-appointments/export-appointments.component.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,SAAA;EACA,iBAAA;AAAJ;AACI;EACE,gBAAA;AACN;AACI;EACE,YAAA;AACN;AACI;EACE,OAAA;AACN;AACI;EACE,OAAA;AACN;AACI;EACE,WAAA;AACN;AAEE;EACE;IACE,sBAAA;EAAJ;EACI;IACE,aAAA;IACA,sBAAA;EACN;AACF;AAEE;EACE,kBAAA;AAAJ;AAEE;EACE,WAAA;AAAJ;;AAIA;EACE,yBAAA;AADF","sourcesContent":[".export-form {\n  .date-filter {\n    display: flex;\n    gap: 15px;\n    min-height: 150px;\n    mat-form-field {\n      max-height: 75px;\n    }\n    > div {\n      min-width: 0;\n    }\n    > div:first-child {\n      flex: 1;\n    }\n    > div:last-child {\n      flex: 2;\n    }\n    mat-radio-button {\n      width: 100%;\n    }\n  }\n  @media only screen and (max-width: 579px) {\n    .date-filter {\n      flex-direction: column;\n      .date-filter-radio-group {\n        display: flex;\n        flex-direction: column;\n      }\n    }\n  }\n  .type-of-user {\n    margin: 10px 0 0 0;\n  }\n  mat-form-field {\n    width: 100%;\n  }\n}\n\n.mat-mdc-dialog-actions {\n  justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
