import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  collection,
  orderBy,
  where,
  query,
  getDocs,
  QueryConstraint,
} from 'firebase/firestore';
import moment from 'moment';
import { db } from 'src/app/app.component';
import { Appointment, AppointmentType, PlanningUser } from 'src/app/interfaces';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';

export interface DialogData {
  appointmentTypes: AppointmentType[];
  planningUsers: PlanningUser[];
  userId?: string;
}

@Component({
  selector: 'app-export-appointments',
  templateUrl: './export-appointments.component.html',
  styleUrls: ['./export-appointments.component.scss'],
})
export class ExportAppointmentsComponent implements OnInit {
  townshipId = localStorage.getItem('township') as string;
  particularities = {};
  typesAlias = {
    all: 'Alle',
    coach: 'Coaches',
    handyman: 'Klussers',
    internal: 'Interne afspraken',
  };
  exportForm: UntypedFormGroup = this.fb.group({
    typeOfExport: ['all', Validators.required],
    selectedFilter: ['day'],
    year: [moment().year(), Validators.required],
    month: [moment().month(), Validators.required],
    week: [moment().week(), Validators.required],
    day: [moment().toDate(), Validators.required],
  });
  years = [];
  months = [
    { value: 0, viewValue: 'Januari' },
    { value: 1, viewValue: 'Februari' },
    { value: 2, viewValue: 'Maart' },
    { value: 3, viewValue: 'April' },
    { value: 4, viewValue: 'Mei' },
    { value: 5, viewValue: 'Juni' },
    { value: 6, viewValue: 'Juli' },
    { value: 7, viewValue: 'Augustus' },
    { value: 8, viewValue: 'September' },
    { value: 9, viewValue: 'Oktober' },
    { value: 10, viewValue: 'November' },
    { value: 11, viewValue: 'December' },
  ];
  dateFilter = [
    { value: 'day', viewValue: 'Dag' },
    { value: 'week', viewValue: 'Week' },
    { value: 'month', viewValue: 'Maand' },
  ];
  weeks: number[] = [];
  days: number[] = [];
  selectedYear: number;
  selectedMonth: number;
  selectedWeek: number;
  selectedDay: any;
  saving = false;
  env = environment.env;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<ExportAppointmentsComponent>,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar
  ) {}

  async ngOnInit() {
    const date = new Date();
    let year = date.getFullYear() - 3;
    for (let i = 0; i < 6; i++) {
      this.years.push({ value: year, viewValue: year.toString() });
      year++;
    }
    for (let i = 1; i < 54; i++) {
      this.weeks.push(i);
    }
    await this.getParticularities();
    this.periodChange();
  }

  periodChange() {
    switch (this.exportForm.controls.selectedFilter.value) {
      case 'month':
        this.exportForm.controls.year.enable();
        this.exportForm.controls.month.enable();
        this.exportForm.controls.day.disable();
        break;
      case 'week':
        this.exportForm.controls.year.enable();
        this.exportForm.controls.week.enable();
        this.exportForm.controls.day.disable();
        break;
      case 'day':
        this.exportForm.controls.year.disable();
        this.exportForm.controls.week.disable();
        this.exportForm.controls.day.enable();
        break;
    }
  }

  async export() {
    this.exportForm.markAllAsTouched();
    if (!this.exportForm.valid) {
      return;
    }
    this.saving = true;
    this.dialogRef.disableClose = true;
    const exportPeriod = this.exportForm.value;
    let userType;
    let fileNameStart;
    let teamUser: PlanningUser;
    if (this.env == 'team' && this.data.userId) {
      teamUser = this.getPlanningUser(this.data.userId);
      exportPeriod.typeOfExport = teamUser.rights;
      userType = this.typesAlias[exportPeriod.typeOfExport];
      fileNameStart = `Afspraken ${teamUser.firstName} ${teamUser.lastName}`;
    } else {
      if (exportPeriod.typeOfExport == 'all') {
        userType = 'Teamlid';
        fileNameStart = 'Alle afspraken';
      } else if (exportPeriod.typeOfExport == 'internal') {
        userType = 'Teamlid';
        fileNameStart = 'Interne afspraken';
      } else {
        userType = this.typesAlias[exportPeriod.typeOfExport];
        fileNameStart = `Afspraken ${userType}`;
      }
    }

    const date = moment().locale('nl');
    let start: moment.Moment;
    let end: moment.Moment;
    let exportFileName = '';
    switch (exportPeriod.selectedFilter) {
      case 'month':
        date.month(exportPeriod.month).year(exportPeriod.year);
        start = moment(date).startOf('month');
        end = moment(date).endOf('month');
        exportFileName = `${fileNameStart} ${date.format('MMMM-YYYY')}.xlsx`;
        break;
      case 'week':
        date.year(exportPeriod.year).week(exportPeriod.week);
        start = moment(date).startOf('week');
        end = moment(date).endOf('week');
        exportFileName = `${fileNameStart} week ${date.format('W YYYY')}.xlsx`;
        break;
      case 'day':
        const dayDate = moment(exportPeriod.day).locale('nl');
        start = moment(dayDate).startOf('day');
        end = moment(dayDate).endOf('day');
        exportFileName = `${fileNameStart} ${dayDate.format(
          'DD-MM-YYYY'
        )}.xlsx`;
        break;
    }
    if (!start || !end || !exportFileName) {
      this.saving = false;
      this.dialogRef.disableClose = false;
      this.snackBar.open(
        'Er is iets fout gegaan met het verwerken van de gegevens',
        'X',
        {
          duration: 5000,
        }
      );
      return;
    }

    const appointments: Appointment[] = [];
    const additionalConstrains: QueryConstraint[] = [];
    if (this.env == 'team') {
      additionalConstrains.push(
        where('planningUserIds', 'array-contains', this.data.userId)
      );
    }
    const appointmentQuery = query(
      collection(db, `township/${this.townshipId}/appointments`),
      where('start', '>=', start.toDate()),
      where('start', '<=', end.toDate()),
      ...additionalConstrains,
      orderBy('start')
    );
    const docs = await getDocs(appointmentQuery);
    docs.forEach((doc) => {
      const data = { id: doc.id, ...doc.data() } as Appointment;
      appointments.push(data);
    });
    console.log(appointments);
    if (appointments.length == 0) {
      this.saving = false;
      this.dialogRef.disableClose = false;
      this.snackBar.open(
        'Er zijn geen afspraken gevonden in de gekozen periode',
        'X',
        {
          duration: 5000,
        }
      );
      return;
    }

    const exportAppointments = [];
    appointments.forEach((appointment) => {
      if (
        exportPeriod.typeOfExport != 'internal' ||
        (exportPeriod.typeOfExport == 'internal' &&
          appointment.internalAppointment)
      ) {
        const appointmentType = this.getAppointmentType(
          appointment.appointmentTypeId
        );
        const exportBase = {};
        const start = moment(appointment.start.toDate()).locale('nl');
        const end = moment(appointment.end.toDate()).locale('nl');
        let address: string;
        if (appointment.street) {
          address = `${appointment.street} ${appointment.houseNumber}${appointment.houseNumberAddition}, ${appointment.postal}`;
        } else {
          address = `${appointment.postal} ${appointment.houseNumber}${appointment.houseNumberAddition}`;
        }
        if (appointment.city) {
          address += `, ${appointment.city}`;
        }

        exportBase['Maand'] = this.ucFirst(start.format('MMMM'));
        exportBase['Week'] = start.format('W');
        exportBase['Dag'] = this.ucFirst(start.format('dddd'));
        exportBase['Start afspraak'] = start.format('HH:mm DD-MM-YYYY');
        exportBase['Einde afspraak'] = end.format('HH:mm DD-MM-YYYY');
        exportBase['Afspraaktype'] = appointmentType.name;
        exportBase['Pipedrive ID'] = appointmentType.pipedriveId;
        if (this.env != 'team') {
          exportBase[`${userType}`] = '';
        }
        if (exportPeriod.typeOfExport != 'internal') {
          exportBase[
            `Competenties${
              this.env != 'team' ? ' ' + userType.toLowerCase() : ''
            }`
          ] = '';
          exportBase[
            `Bijzonderheden${
              this.env != 'team' ? ' ' + userType.toLowerCase() : ''
            }`
          ] = '';
          exportBase['Naam aanvrager'] = appointment.userName;
        }
        exportBase['Afspraak locatie'] = address;

        let i = 0;
        appointment.planningUserIds.forEach((userId) => {
          const user = this.getPlanningUser(userId);
          if (
            this.env != 'team' ||
            (this.env == 'team' && teamUser?.id == userId)
          ) {
            if (
              (user && user.rights == exportPeriod.typeOfExport) ||
              exportPeriod.typeOfExport == 'all' ||
              exportPeriod.typeOfExport == 'internal'
            ) {
              const exportObj = { ...exportBase };
              const appointmentTypes = [];
              const particularities = [];
              if (
                user?.workData &&
                user.rights != 'planner' &&
                exportPeriod.typeOfExport != 'internal'
              ) {
                user.workData.appointmentTypes.forEach((typeId) => {
                  const type = this.getAppointmentType(typeId);
                  type ? appointmentTypes.push(type.name) : null;
                });
                user.workData?.particularities.forEach((particularityId) => {
                  const particularity = this.particularities[particularityId];
                  particularity
                    ? particularities.push(particularity.info)
                    : null;
                });
                user.workData?.additionalParticularities.forEach(
                  (particularity) => {
                    particularities.push(particularity);
                  }
                );
                exportObj[
                  `Competenties${
                    this.env != 'team' ? ' ' + userType.toLowerCase() : ''
                  }`
                ] = appointmentTypes.join(', ');
                exportObj[
                  `Bijzonderheden${
                    this.env != 'team' ? ' ' + userType.toLowerCase() : ''
                  }`
                ] = particularities.join(', ');
              }
              if (this.env != 'team') {
                exportObj[`${userType}`] = appointment.planningUserNames[i];
              }
              exportAppointments.push(exportObj);
            }
          }
          i++;
        });
      }
    });
    if (exportAppointments.length == 0) {
      this.saving = false;
      this.dialogRef.disableClose = false;
      this.snackBar.open(
        this.env != 'team'
          ? 'Er zijn geen afspraken gevonden in de gekozen periode voor dit type werknemer'
          : 'Er zijn geen afspraken gevonden in de gekozen periode',
        'X',
        {
          duration: 5000,
        }
      );
      return;
    }
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportAppointments);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Afspraken');
    XLSX.writeFile(wb, exportFileName);
    this.dialogRef.close();
  }

  getPlanningUser(id: string) {
    const planningUser = this.data.planningUsers.find((user) => user.id === id);
    return planningUser;
  }

  getAppointmentType(id: string) {
    const appointmentType = this.data.appointmentTypes.find(
      (type) => type.id === id
    );
    return appointmentType;
  }

  async getParticularities() {
    const particularitiesRef = collection(
      db,
      `township/${this.townshipId}/planningParticularities`
    );
    const particularitiesDocs = await getDocs(query(particularitiesRef));
    particularitiesDocs.forEach((particularityDoc) => {
      const particularity = particularityDoc.data();
      this.particularities[particularityDoc.id] = particularity;
    });
  }

  ucFirst(string: string) {
    return String(string).charAt(0).toUpperCase() + String(string).slice(1);
  }
}
