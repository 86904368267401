import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Appointment, PlanningUser } from 'src/app/interfaces';
import { ManageReportComponent } from '../manage-report/manage-report.component';
import { ActionType } from 'src/app/enums';

export interface DialogData {
  appointment: Appointment;
  planningUsers: PlanningUser[];
}

@Component({
  selector: 'app-team-appointment-details',
  templateUrl: './team-appointment-details.component.html',
  styleUrls: ['./team-appointment-details.component.scss'],
})
export class TeamAppointmentDetailsComponent {
  reportAlreadySend: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<TeamAppointmentDetailsComponent>
  ) {
    this.reportAlreadySend =
      this.data.appointment.status == ActionType.done ||
      this.data.appointment.status == ActionType.canceled;
  }

  createReport() {
    const dialogRef = this.dialog.open(ManageReportComponent, {
      data: {
        appointment: this.data.appointment,
      },
      disableClose: true,
      panelClass: ['fullscreen-dialog'],
    });
    dialogRef.afterClosed().subscribe(async (reportSubmitted) => {
      console.log('reportSubmitted', reportSubmitted);
      if (reportSubmitted) {
        this.dialogRef.close(true);
      }
    });
  }
}
