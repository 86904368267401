// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-dialog-content {
  padding: 0 !important;
  color: black !important;
}`, "",{"version":3,"sources":["webpack://./src/app/planning-team/dialogs/report-sent/report-sent.component.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,uBAAA;AACF","sourcesContent":["mat-dialog-content {\n  padding: 0 !important;\n  color: black !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
