import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  Address,
  Appointment,
  AppointmentStatisticUser,
  AppointmentType,
  DayPart,
  DistanceLabel,
  PlanningUser,
} from 'src/app/interfaces';
import { ManageAppointmentComponent } from '../../dialogs/manage-appointment/manage-appointment.component';
import moment from 'moment';
import { AppointmentDetailsComponent } from '../../dialogs/appointment-details/appointment-details.component';
import {
  getAvailabilityCirclePercentageForWeek,
  getAvailableTimeString,
  getPlanningUsersStatistics,
} from '../../helper';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
} from '@firebase/firestore';
import { db } from 'src/app/app.component';
import { PlanningUserRights, RepeatValue, TeamUserRights } from 'src/app/enums';

export interface AppointmentInfoData {
  appointment: Appointment;
  showEditButton: boolean;
  planningUsers: PlanningUser[];
}

@Component({
  selector: 'app-appointment-info-card',
  templateUrl: './appointment-info-card.component.html',
  styleUrls: ['./appointment-info-card.component.scss'],
})
export class AppointmentInfoCardComponent implements OnInit {
  moment = moment;
  townshipId = localStorage.getItem('township') as string;
  appointmentUsers: AppointmentUser[] = [];
  dayParts: DayPart[] = [];
  appointmentType: AppointmentType;
  planningUsersStatistics: AppointmentStatisticUser[] = [];
  maximumAppointmentsInDayPart: number;
  planningUserRights = { ...TeamUserRights, ...PlanningUserRights };
  repeatValues = { ...RepeatValue };
  availableUsers = [];
  loaded: boolean = false;
  particularities = {};
  @Input() data: AppointmentInfoData;

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<AppointmentDetailsComponent>
  ) {}

  async ngOnInit() {
    moment.locale('nl');
    console.log(this.data);
    this.planningUsersStatistics = await getPlanningUsersStatistics(
      moment(this.data.appointment.start).format('YYYY-ww')
    );

    await this.getDayParts();
    await this.getAppointmentType();
    await this.getParticularities();

    const currentDay = moment(this.data.appointment.start).day();
    const dayPartsToday = this.dayParts
      .filter((dayPart) => {
        return dayPart.day === currentDay && dayPart.enabled;
      })
      .sort((a, b) => {
        if (a.startHour > b.startHour) {
          return 1;
        }
        return -1;
      });
    const dayPartsTodayIds = [];
    dayPartsToday.forEach((dayPart) => {
      dayPartsTodayIds.push(dayPart.id);
    });
    let totalMinutesInDay = 0;
    for (const dayPart of dayPartsToday) {
      const dayPartMinutes =
        (dayPart.endHour - dayPart.startHour) * 60 +
        (dayPart.endMinutes - dayPart.startMinutes);
      totalMinutesInDay = totalMinutesInDay + dayPartMinutes;
    }

    this.data.appointment.planningUserIds?.forEach((userId) => {
      const user = this.getPlanningUser(userId);
      const userDistance = this.getPlanningUserDistance(userId);
      const circlePercentage = getAvailabilityCirclePercentageForWeek(
        user,
        this.planningUsersStatistics
      );
      const degrees = 360 * circlePercentage;
      const circularProgress = `conic-gradient(var(--primary) ${degrees}deg, transparent 0deg)`;
      const maxWorkMinutes = user?.workData?.maxWeeklyHours
        ? user.workData.maxWeeklyHours * 60
        : 0;
      const appointmentUser: AppointmentUser = {
        id: userId,
        user: user,
        circularProgress: circularProgress,
        maxTime: getAvailableTimeString(maxWorkMinutes),
        timeUsed: getAvailableTimeString(maxWorkMinutes, circlePercentage),
        distanceLabel: userDistance?.distanceLabel,
        lastAddress: userDistance?.lastAddress,
      };
      if (user) this.appointmentUsers.push(appointmentUser);
    });
    await this.teamUsersAvailable();
    this.loaded = true;
  }

  async getDayParts() {
    const dayPartsRef = collection(db, `township/${this.townshipId}/dayParts`);
    const dayPartsDocs = await getDocs(query(dayPartsRef, orderBy('day')));
    dayPartsDocs.forEach((dayPartDoc) => {
      const dayPart = dayPartDoc.data() as DayPart;
      dayPart.id = dayPartDoc.id;
      this.dayParts.push(dayPart);
    });
  }

  async getAppointmentType() {
    const appointmentTypeRef = doc(
      db,
      `township/${this.townshipId}/appointmentTypes/${this.data.appointment.appointmentTypeId}`
    );
    this.appointmentType = (
      await getDoc(appointmentTypeRef)
    ).data() as AppointmentType;
    this.appointmentType.id = this.data.appointment.appointmentTypeId;
  }

  async getParticularities() {
    const particularitiesRef = collection(
      db,
      `township/${this.townshipId}/planningParticularities`
    );
    const particularitiesDocs = await getDocs(query(particularitiesRef));
    particularitiesDocs.forEach((particularityDoc) => {
      const particularity = particularityDoc.data();
      this.particularities[particularityDoc.id] = particularity;
    });
  }

  getPlanningUserStatisticsCount(userId: string, dayPartIds: string[]) {
    const user = this.planningUsersStatistics.find(
      (user) => user.id === userId
    );
    if (!user) {
      return 0;
    }
    const filteredAppointments = user.appointments.filter((appointment) => {
      const dayPartIdFound = dayPartIds.filter((dayPartId) => {
        return appointment.dayPartIds.indexOf(dayPartId) != -1;
      });
      return dayPartIdFound.length > 0;
    });
    return filteredAppointments.length;
  }

  capitalizeFirstLetter(val: string) {
    return String(val).charAt(0).toUpperCase() + String(val).slice(1);
  }

  getPlanningUser(id: string) {
    const planningUser = this.data.planningUsers.find((user) => user.id === id);
    return planningUser;
  }

  getPlanningUserDistance(id: string) {
    const planningUserDistance =
      this.data.appointment.planningUserDistance?.find(
        (user) => user.userId === id
      );
    return planningUserDistance;
  }

  async teamUsersAvailable() {
    this.appointmentUsers.forEach((planningUser) => {
      const appointmentStart = moment(
        this.data.appointment.start.toDate
          ? this.data.appointment.start.toDate()
          : this.data.appointment.start
      );
      const appointmentEnd = moment(
        this.data.appointment.end.toDate
          ? this.data.appointment.end.toDate()
          : this.data.appointment.end
      );
      const statisticsUser = this.planningUsersStatistics.find(
        (user) => user.id === planningUser.user.id
      );
      const appointments = statisticsUser
        ? statisticsUser.appointments.filter((appointment) => {
            const start = moment(appointment.start.toDate());
            const end = moment(appointment.end.toDate());
            return (
              start.isSame(appointmentStart, 'day') &&
              ((start.isSameOrAfter(appointmentStart) &&
                end.isSameOrBefore(appointmentEnd)) ||
                start.isBetween(appointmentStart, appointmentEnd) ||
                end.isBetween(appointmentStart, appointmentEnd)) &&
              appointment.id != this.data.appointment.id
            );
          })
        : [];
      const exceptions = planningUser.user?.exceptions?.filter((exception) => {
        const start = moment(exception.startDate.toDate());
        const end = moment(exception.endDate.toDate());
        return (
          (appointmentStart.isSameOrAfter(start) &&
            appointmentEnd.isSameOrBefore(end)) ||
          appointmentStart.isBetween(start, end) ||
          appointmentEnd.isBetween(start, end)
        );
      });
      const index = this.availableUsers.indexOf(planningUser.user.id);
      const dayPartIdFound = this.dayParts.filter((dayPart) => {
        return this.data.appointment.dayPartIds.indexOf(dayPart.id) != -1;
      });
      if (
        (!appointments || appointments.length == 0) &&
        (!exceptions || exceptions.length == 0) &&
        (!planningUser.user?.dayPartsUnavailable || dayPartIdFound) &&
        index == -1
      ) {
        this.availableUsers.push(planningUser.user.id);
      }
    });
  }

  editAppointment() {
    const dialogRef = this.dialog.open(ManageAppointmentComponent, {
      width: '500px',
      data: {
        appointment: this.data.appointment,
        planningUsers: this.data.planningUsers,
      },
      disableClose: true,
      panelClass: ['fullscreen-dialog'],
    });
    dialogRef.afterClosed().subscribe(async (changesMade) => {
      console.log('changesMade', changesMade);
      if (changesMade) {
        this.dialogRef.close();
      }
    });
  }
}

interface AppointmentUser {
  id: string;
  user: PlanningUser;
  circularProgress: string;
  maxTime: string;
  timeUsed: string;
  distanceLabel?: DistanceLabel;
  lastAddress?: Address;
}
